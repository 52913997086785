import { CHANGE_LOCALE } from './localeMiddleware'

export function changeLocale(locale: string) {
  return {
    type: CHANGE_LOCALE,
    payload: {
      locale
    }
  };
}
