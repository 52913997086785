export function middleTrim(str: string, maxLength: number) {
  if (!str || str.length <= maxLength) {
    return str;
  }

  const midpoint = Math.ceil(str.length / 2);
  const toRemove = str.length - maxLength;
  const lStrip = Math.ceil(toRemove / 2);
  const rStrip = toRemove - lStrip;

  return str.substring(0, midpoint - lStrip) + '...' + str.substring(midpoint + rStrip);
}

export function middleTrimWithBoxWidth(str: string, width: number, fontSize: number) {
  const nameTrimLength = width / fontSize;
  return middleTrim(str, nameTrimLength);
}
