import React from 'react';
import { IAuthState } from './reducers';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import api from '../api';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { loginUserSuccess } from './actions/auth';
import config from '../config';

type StateProps = {
  auth: IAuthState;
};

type DispatchProps = {
  loginUserSuccess: typeof loginUserSuccess;
};

export default function(Component: React.ComponentType) {
  class WrapperComponent extends React.Component<StateProps & DispatchProps & RouteComponentProps> {
    state = {
      shouldRender: false
    };

    async componentDidMount() {
      const { auth } = this.props;

      if (auth.authed) {
        this.setState({ shouldRender: true });
      } else {
        const token = localStorage.getItem('token');

        Modelo.init({
          endpoint: config.MODELO_API_URI,
          appToken: token!
        });

        try {
          const appToken = await api.getAppToken();
          const { data } = await api.getProjects();
          this.props.loginUserSuccess({ username: data.user!.username });
          this.setState({ shouldRender: true });
        } catch (e) {
          this.props.history.push('/login');
        }
      }
    }

    render() {
      const { shouldRender } = this.state;
      if (!shouldRender) return null;

      return <Component {...this.props} />;
    }
  }

  return connect(
    (state: IState) => ({ auth: state.auth }),
    { loginUserSuccess }
  )(withRouter(WrapperComponent));
}
