import React from 'react';
import { withApollo } from 'react-apollo';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import './CombineModelMessageDialog.scss';
import withProjectFolders, { WithProjectFolders } from './withProjectFolders';

type Props = {};

export type CombineModelMessageDialogDataProps = { 
  done: () => void,
  contentMessage : {
    title : string,
    content : string,
    buttons : Array<any>
  },
 };

type State = {
  name: string;
  selectedRows: string[];
};


class CombineModelMessageDialog extends React.Component< WithProjectFolders<Props> & DialogProps<CombineModelMessageDialogDataProps> & State > {

  static size = {
    width: 580,
    height: 180
  };
  //static showCloseButton = false;

  render(){
    const { data } = this.props.dialog;
    if(!data) return null;
    return(
      <div className = 'CombineModelMessageDialog'> 
        <h2 className = 'title'>{data.contentMessage.title}</h2>
        <div className = 'content'>{data.contentMessage.content}</div>
        <div className = 'footer'>
          <div className = 'actionBtns'>
            {
              data.contentMessage.buttons.map(
                (item,index) => <span key={index+item.name} className = {item.state} onClick = {() => item.action()}>{item.name}</span>
              )
            }
          </div>
        </div>
      </div>
    )
  }

}

export default withApollo(withProjectFolders(CombineModelMessageDialog));