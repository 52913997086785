import { format } from 'date-fns';
import memoize from 'memoize-one';
import { unnest } from 'ramda';
import { getExtension, groupByCharacterType, SortOptions, SortOrder } from '../../Common/utils/table';
import { GetModelFolderContentsChildFolders, GetModelFolderContentsModels, ModelStatus } from '../../generated/graphql';

export type ModelRow = {
  id: string;
  name: string;
  modelId: string;
  fileType: string | null;
  creator: string;
  createdAt: string;
  status: ModelStatus;
};

export const getModels = memoize((data: GetModelFolderContentsModels[], sortOptions?: SortOptions) => {
  const models = data.map(model => {
    return {
      id: model.id,
      name: model.name,
      modelId: model.id,
      fileType: getExtension(model.name) || 'm3d',
      createdAt: format(new Date(model.createdAt), 'YYYY-MM-DD'),
      creator: model.createdBy.username,
      status: model.status
    };
  });

  if (!sortOptions) {
    return models;
  }

  return sorted(sortOptions, models);
});

export const getFolders = memoize((data: GetModelFolderContentsChildFolders[], sortOptions?: SortOptions) => {
  const folders = data.map(folder => {
    return {
      id: folder.id + '',
      name: folder.name,
      modelId: '--',
      fileType: 'folder',
      createdAt: format(new Date(folder.createdAt), 'YYYY-MM-DD'),
      creator: folder.createdBy.username,
      status: ModelStatus.Converted
    };
  });

  if (!sortOptions) {
    return folders;
  }

  return sorted(sortOptions, folders);
});

function sorted(options: SortOptions, data: ModelRow[]) {
  const { order, by } = options;
  const [zh, upper, lower] = groupByCharacterType(data, by) as [ModelRow[], ModelRow[], ModelRow[]];

  if (order === SortOrder.NONE) {
    return data;
  }

  if (by === 'name' || by === 'creator') {
    const normalSort = (a: ModelRow, b: ModelRow) => {
      if (order === SortOrder.ASC) {
        if (a[by] < b[by]) {
          return -1;
        }
        if (a[by] > b[by]) {
          return 1;
        }
        return 0;
      } else {
        if (a[by] < b[by]) {
          return 1;
        }
        if (a[by] > b[by]) {
          return -1;
        }
        return 0;
      }
    };
    return unnest([
      zh.sort((a, b) => {
        if (order === SortOrder.ASC) {
          return (a[by] as string).localeCompare(b[by], 'zh-Hans-CN', { sensitivity: 'accent' });
        } else {
          return (b[by] as string).localeCompare(a[by], 'zh-Hans-CN', { sensitivity: 'accent' });
        }
      }),
      upper.sort(normalSort),
      lower.sort(normalSort)
    ]);
  }

  if (by === 'createdAt') {
    return data.sort((a, b) =>
      order === SortOrder.ASC
        ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }

  return data;
}
