import { CHANGE_LOCALE } from './localeMiddleware';
import { ActionWithPayload } from '../../src/Common/utils/actions';

export default (state: string = 'zh', action: ActionWithPayload<typeof CHANGE_LOCALE, { locale: string }>) => {
  if (action.type === CHANGE_LOCALE) {
    return action.payload.locale;
  }

  return state;
};
