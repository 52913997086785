import { produceWithPatches, Patch, enablePatches } from 'immer';
import {EntryObject} from '../effects/effect';

enablePatches();

type StateUpdator<T> = (state: T) => void;
type PatchHandler<T> = (patch: EntryObject<T>) => void;

export class Store<T> {
  state: T;

  private _handlerIndex = 0;
  private _handlers: { [id: number]: PatchHandler<T> } = {};

  constructor(initialState: T) {
    this.state = initialState;
  }

  update(updator: StateUpdator<T>) {
    const [next, patches] = produceWithPatches(this.state, updator);
    this.state = next;

    patches.forEach(patch => {
      Object.values(this._handlers).forEach(handler => {
        handler && handler({ key: patch.path[0] as any, value: patch.value, path: patch.path });
      });
    });
  }

  forceUpdate() {
    Object.keys(this.state).forEach((key: string) => {
      Object.values(this._handlers).forEach(handler => {
        //@ts-ignore
        handler && handler({ key: key as any, value: this.state[key], path: [key] });
      });
    })
  }

  subscribe(handler: PatchHandler<T>) {
    const id = ++this._handlerIndex;
    this._handlers[id] = handler;
    //@ts-ignore
    return () => (this._handlers[id] = null);
  }

  applyState(newState: T) {
    this.update(state => {
      Object.keys(newState).forEach(key => {
        //@ts-ignore
        state[key] = newState[key];
      })
    });
  }
}
