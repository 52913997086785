import * as dialogActions from './actions';
import { ActionsUnion } from '../utils/actions';
import MoveModelDialog from '../../Model/components/MoveModelDialog';
import ResetAppTokenDialog from '../../Home/RestAppTokenDialog';
import UploadModelDialog from '../../Model/UploadModelDialog';
import DeleteModeloDialog from '../../Model/components/DeleteModeloDialog';
import SiderDeleteFolderDialog from '../../Model/SiderDeleteFolderDialog';
import CombineModelDialog from '../../Model/CombineModelDialog';
import GeneratePDFDialog from '../../Model/GeneratePDFDialog';
import MoveAssetDialog from '../../Asset/components/MoveAssetDialog';
import DeleteAssetDialog from '../../Asset/components/DeleteAssetDialog';
import PreviewAssetDialog from '../../Asset/PreviewAsetDialog';
import ChangeLogDialog from '../../Update/ChangeLogDialog';
import CombineModelMessageDialog from '../../Model/CombineModelMessageDialog';
import UploadModelToDAMDialog from '../../Model/UploadModelToDAMDialog';
import LoadingDialog from '../../Model/LoadingDialog';
import ErrorDialog from '../../Model/ErrorDialog';

export type DialogActions = ActionsUnion<typeof dialogActions>;

export type DialogProps<T = any> = { dialog: Dialog<T> };

export enum DialogType {
  Custom = 'Custom',
  Message = 'Message'
}

export enum DialogName {
  MoveModel = 'MoveModel',
  MoveAsset = 'MoveAsset',
  RestAppToken = 'RestAppToken',
  UploadModel = 'UploadModel',
  DeleteModel = 'DeleteModel',
  DeleteAsset = 'DeleteAsset',
  SiderDelete = 'SiderDelete',
  CombineModel = 'CombineModel',
  GeneratePDF = 'GeneratePDF',
  PreviewAsset = 'PreviewAsset',
  ChangeLog = 'ChangeLog',
  CombineModelMessage = 'CombineModelMessage',
  UploadModelToDAM = 'UploadModelToDAMDialog',
  Loading = 'LoadingDialog',
  Error = 'ErrorDialog',
}

export interface Dialog<T = any> {
  name: string;
  width?: number;
  height?: number;
  showCloseButton?: boolean;
  type: DialogType;
  visible: boolean;
  onCancel?: () => void;
  onConfirm?: (dialog: Dialog<T>) => void;
  data?: T;
  props?: any;
}

export interface CustomDialog<T = any> extends Dialog<T> {
  component: React.ComponentType<{ dialog: Dialog<T> }>;
}

export interface MessageDialog extends Dialog {
  title: string;
  message: string;
}

export type DialogsState = {
  [name: string]: Dialog;
};

export const dialogComponents: { [name: string]: React.ComponentType<DialogProps> } = {
  [DialogName.MoveModel]: MoveModelDialog,
  [DialogName.MoveAsset]: MoveAssetDialog,
  [DialogName.RestAppToken]: ResetAppTokenDialog,
  [DialogName.UploadModel]: UploadModelDialog,
  [DialogName.DeleteModel]: DeleteModeloDialog,
  [DialogName.DeleteAsset]: DeleteAssetDialog,
  [DialogName.SiderDelete]: SiderDeleteFolderDialog,
  [DialogName.CombineModel]: CombineModelDialog,
  [DialogName.GeneratePDF]: GeneratePDFDialog,
  [DialogName.PreviewAsset]: PreviewAssetDialog,
  [DialogName.ChangeLog]: ChangeLogDialog,
  [DialogName.CombineModelMessage]: CombineModelMessageDialog,
  [DialogName.UploadModelToDAM]: UploadModelToDAMDialog,
  [DialogName.Loading]: LoadingDialog,
  [DialogName.Error]: ErrorDialog,
};

export const initialDialogs = Object.keys(dialogComponents).reduce((p: any, c) => {
  const size = (dialogComponents[c] as any).size;
  p[c] = {
    name: c,
    type: DialogType.Custom,
    visible: false,
    component: dialogComponents[c],
    showCloseButton: (dialogComponents[c] as any).showCloseButton,
  };

  if (size) {
    p[c].width = size.width;
    p[c].height = size.height;
  }

  return p;
}, {});
