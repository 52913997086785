import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './DwgViewer.scss';

declare const Modelo: any;

class ModelViewer extends React.Component<RouteComponentProps<{ dwgId: string }>> {
  state = {
    error: '',
    folderIds: []
  };

  app: any;

  componentWillUnmount() {
    this.app && this.app.destroy();
  }

  componentDidMount() {
    const { dwgId } = this.props.match.params;

    const assetId = parseInt(dwgId);

    if (!assetId) {
      this.setState({ error: 'Not valid dwg' });
      return;
    }

    this.app = new (Modelo as any).UI.DWGViewer({
      locale: 'zh',
      containerId: 'dwgContainer',
      assetId,
      onClose: () => {
        this.props.history.goBack();
      }
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <div className="">{error}</div>;
    }

    return <div className="DwgViewer" id="dwgContainer" />;
  }
}

export default withRouter(ModelViewer);
