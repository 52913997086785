import React from 'react';
import classNames from 'classnames';
import { VariableSizeGrid as Grid, GridChildComponentProps } from 'react-window';
import ReactResizeDetector from 'react-resize-detector';
import intl from 'react-intl-universal';
import { lt } from '../../i18n';
import './Table.scss';

const rowHeight = 58;
const headerHeight = 32;

function generateUniqueName(existNames: string[], noRepeatName: string, uniqueId: number, currentInputValue?: string) {
  while (existNames.includes(noRepeatName)) {
    uniqueId++;
    noRepeatName = `${currentInputValue ? currentInputValue : intl.get(lt.NEW_FOLDER)}(${uniqueId})`;
  }
  return noRepeatName;
}

export type TableMenu<T = any> = {
  name: string;
  shouldShow?: (data: { rowIndex: number; rowData: T }) => boolean;
  action: (data: { rowIndex: number; rowData: T; done: () => void }) => void;
};

export type TableColumn<T = any> = {
  header?: string;
  accessor: keyof T;
  renderer?: (cell: { column: TableColumn<T>; row: T }) => JSX.Element;
  headerRenderer?: (header: { column: TableColumn<T> }) => JSX.Element;
  width?: number;
};

export type TableData<T = any> = T[];

class TablePopMenu<T> extends React.Component<{ menus: TableMenu[]; rowIndex: number; rowData: T; done: () => void }> {
  menuRef: HTMLDivElement | null = null;

  state = {
    shouldRender: false,
    style: {} as React.CSSProperties
  };

  componentDidMount() {
    const rect = this.menuRef!.getBoundingClientRect();
    const maxW = window.innerWidth;
    const maxH = window.innerHeight;
    let style: any = {};
    // 距底
    if (rect.top + rect.width + 20 > maxH) {
      style = { bottom: 25, left: 12 };
    } else {
      style = { top: 40, left: 12 };
    }
    if (rect.left + rect.width > maxW) {
      delete style.left;
      style.right = 30;
    }
    this.setState({ style });
  }

  render() {
    const { menus, rowIndex, rowData, done } = this.props;
    const { shouldRender, style } = this.state;

    return (
      <div className="TablePopMenu" style={style} ref={e => (this.menuRef = e)}>
        {menus
          .filter(menu => (menu.shouldShow ? menu.shouldShow({ rowIndex: rowIndex, rowData: rowData }) : true))
          .map(menu => {
            return (
              <div
                key={menu.name}
                className="TablePopMenu__menu"
                onClick={e => {
                  e.stopPropagation();
                  menu.action({ rowIndex, rowData, done });
                }}
              >
                {menu.name}
              </div>
            );
          })}
      </div>
    );
  }
}

class Cell<T> extends React.Component<
  GridChildComponentProps & {
    data: T[];
    columns: TableColumn<T>[];
    selected: boolean;
    showCheckbox: boolean;
    onClick?: (data: { rowIndex: number; rowData: T }) => void;
    onSelect?: (data: { rowIndex: number; rowData: T }) => void;
    onInputBlur?: (data: { rowIndex: number; rowData: T; inputValue: string }) => void;
    updateState: (state: Partial<State>) => void;
    state: State;
    menus: TableMenu[];
    showNewRow?: boolean;
    renameRowIndex?: number;
  }
> {
  private inputRef: HTMLInputElement | null = null;

  handleCloseMenu = () => {
    this.props.updateState({ popMenuRowIndex: null });
  };

  handleClickCell = (data: { rowIndex: number; rowData: T }) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(data);
    }
  };

  handleInputBlur = (row: { rowIndex: number; rowData: T }) => {
    const { onInputBlur, data, columns } = this.props;
    let existNames: string[] = data.map((v: T) => v[columns[0].accessor]);
    let currentInputValue = this.inputRef!.value;
    let noRepeatName = '';
    let noRepeatId = 1;
    const equalValueIndex = existNames.indexOf(currentInputValue);
    if (row.rowIndex === -1) {
      if (equalValueIndex !== -1) {
        noRepeatName = `${currentInputValue}(${noRepeatId})`;
        noRepeatName = generateUniqueName(existNames, noRepeatName, noRepeatId, currentInputValue);
      } else {
        if (currentInputValue) {
          noRepeatName = currentInputValue;
        } else {
          noRepeatName = this.inputRef!.defaultValue;
        }
      }
    } else {
      if (currentInputValue && currentInputValue !== this.inputRef!.defaultValue) {
        if (equalValueIndex !== -1) {
          noRepeatName = `${currentInputValue}(${noRepeatId})`;
          noRepeatName = generateUniqueName(existNames, noRepeatName, noRepeatId, currentInputValue);
        } else {
          noRepeatName = currentInputValue;
        }
      } else {
        noRepeatName = this.inputRef!.defaultValue;
      }
    }

    if (onInputBlur) {
      onInputBlur({ ...row, inputValue: noRepeatName });
    }
  };

  render() {
    let { columnIndex } = this.props;
    const {
      rowIndex,
      style,
      columns,
      data,
      updateState,
      state,
      menus,
      showNewRow,
      renameRowIndex,
      selected,
      onSelect,
      showCheckbox
    } = this.props;

    columnIndex = columnIndex - 1;

    const tableClass = classNames('TableCell', {
      'TableCell--disabled':
        typeof renameRowIndex === 'number' ? renameRowIndex !== rowIndex || columnIndex !== 0 : false || showNewRow
    });

    // manually added new column for checkboxes
    if (columnIndex === -1) {
      const row = data[rowIndex];
      return (
        <div
          style={style}
          className={tableClass}
          onMouseEnter={() => {
            updateState({ hoveredRowIndex: rowIndex });
          }}
          onMouseLeave={() => {
            updateState({ hoveredRowIndex: null });
          }}
        >
          <div
            className={classNames('TableCell__checkbox', {
              'TableCell__checkbox--selected': selected,
              'TableCell__checkbox--active': state.hoveredRowIndex === rowIndex || showCheckbox
            })}
            onClick={e => {
              e.stopPropagation();
              if (onSelect) {
                onSelect({ rowIndex, rowData: row });
              }
            }}
          />
        </div>
      );
    }

    const column = columns[columnIndex];
    const row = data[rowIndex];

    let defaultValueUniqueId = 0;
    let defaultName = intl.get(lt.NEW_FOLDER);
    let existNames: string[] = data.map((v: T) => v[columns[0].accessor]);
    defaultName = generateUniqueName(existNames, defaultName, defaultValueUniqueId);

    // manually added new column for pop menus
    if (columnIndex === columns.length) {
      return (
        <div
          style={style}
          className={classNames(tableClass, 'TableCell__menuTrigger')}
          onMouseEnter={() => {
            updateState({ popMenuRowIndex: rowIndex });
          }}
          onMouseLeave={() => {
            updateState({ popMenuRowIndex: null });
          }}
        >
          <div
            className="TableCell__dots"
            onClick={e => {
              e.stopPropagation();
              if (state.popMenuRowIndex === rowIndex) {
                updateState({ popMenuRowIndex: null });
              } else {
                updateState({ popMenuRowIndex: rowIndex });
              }
            }}
          >
            ...
          </div>
          {rowIndex === state.popMenuRowIndex && (
            <TablePopMenu menus={menus} rowIndex={rowIndex} rowData={row} done={this.handleCloseMenu} />
          )}
        </div>
      );
    }

    // new row name
    if (showNewRow && rowIndex === -1) {
      let defaultValueUniqueId = 0;
      let defaultName = intl.get(lt.NEW_FOLDER);
      let existNames: string[] = data.map((v: T) => v[columns[0].accessor]);
      defaultName = generateUniqueName(existNames, defaultName, defaultValueUniqueId);

      return (
        <div style={style} className={tableClass}>
          {columnIndex === 0 && (
            <input
              defaultValue={defaultName}
              className="TableCell__newFolder"
              ref={e => (this.inputRef = e)}
              onBlur={() => this.handleInputBlur({ rowIndex, rowData: row })}
              autoFocus
              onKeyUp={e => {
                if (e.keyCode === 13) this.handleInputBlur({ rowIndex, rowData: row });
              }}
            />
          )}
        </div>
      );
    }

    // rename row
    if (typeof renameRowIndex === 'number' && rowIndex === renameRowIndex && columnIndex === 0) {
      return (
        <div style={style} className={tableClass}>
          <input
            defaultValue={data[rowIndex].name}
            className="TableCell__newFolder"
            autoFocus
            ref={e => (this.inputRef = e)}
            onBlur={() => this.handleInputBlur({ rowIndex, rowData: row })}
            onKeyUp={e => {
              if (e.keyCode === 13) this.handleInputBlur({ rowIndex, rowData: row });
            }}
          />
        </div>
      );
    }

    if (column.renderer) {
      return (
        <div
          className={tableClass}
          style={style}
          onClick={() => this.handleClickCell({ rowIndex, rowData: row })}
          onMouseEnter={() => {
            updateState({ hoveredRowIndex: rowIndex });
          }}
          onMouseLeave={() => {
            updateState({ hoveredRowIndex: null });
          }}
        >
          {column.renderer({ column, row })}
        </div>
      );
    }

    return (
      <div
        className={tableClass}
        style={style}
        onClick={() => this.handleClickCell({ rowIndex, rowData: row })}
        onMouseEnter={() => {
          updateState({ hoveredRowIndex: rowIndex });
        }}
        onMouseLeave={() => {
          updateState({ hoveredRowIndex: null });
        }}
      >
        {row[column.accessor]}
      </div>
    );
  }
}

type Props<T = any> = {
  showNewRow?: boolean;
  renameRowIndex?: number;
  data: TableData<T>;
  columns: TableColumn<T>[];
  menus: TableMenu<T>[];
  showCheckbox: boolean;
  isSelected?: (data: { rowIndex: number; rowData: T }) => boolean;
  onLoad?: () => void;
  onClick?: (data: { rowIndex: number; rowData: T }) => void;
  onSelect?: (data: { rowIndex: number; rowData: T }) => void;
  onInputBlur?: (data: { rowIndex: number; rowData: T; inputValue: string }) => void;
};

type State = {
  hoveredRowIndex: number | null;
  popMenuRowIndex: number | null;
  size: { width: number; height: number };
};

class Table<T> extends React.Component<Props<T>, State> {
  gridRef: Grid | null = null;
  tableRef: HTMLDivElement | null = null;

  shouldComponentUpdate(nextProps: Props<T>) {
    const { renameRowIndex } = this.props;

    if (nextProps.renameRowIndex && nextProps.renameRowIndex === renameRowIndex) {
      return false;
    }

    return true;
  }

  state = {
    hoveredRowIndex: null,
    popMenuRowIndex: null,
    size: { width: 1, height: 1 }
  };

  componentDidMount() {
    this.props.onLoad && this.props.onLoad();
    this.setState({ size: { width: this.tableRef!.clientWidth, height: this.tableRef!.clientHeight } });
  }

  handleUpdateState = (state: { hoveredRowIndex?: number | null; popMenuRowIndex?: number | null }) => {
    setTimeout(() => {
      this.setState(() => state as any);
    }, 50);
  };

  handleResize = (width: number, height: number) => {
    this.setState({ size: { width, height } });

    if (this.gridRef) {
      this.gridRef.resetAfterColumnIndex(0);
    }
  };

  render() {
    const {
      data,
      columns,
      menus,
      onClick,
      onSelect,
      showNewRow,
      renameRowIndex,
      onInputBlur,
      isSelected,
      showCheckbox
    } = this.props;
    const { size } = this.state;
    const { width, height } = size;

    if (!width || !height) return <div />;

    const totalWeight = columns.reduce((p, c) => p + (c.width || 1), 1);
    const columnW = width / totalWeight;
    // 54 is table padding-top
    // 32 is table header height
    // 18 is table header padding-top
    const tableHeight = height - 54 - 32 - 18;

    return (
      <div className="Table" ref={e => (this.tableRef = e)}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
        <div className="Table__headers">
          {columns.map((column, i) => {
            const left = columns.slice(0, i).reduce((p, c) => p + (c.width || 1) * columnW, 0) + 50;
            return (
              <div key={i} className="Table__header" style={{ top: 0, left }}>
                {column.header && column.header}
                {column.headerRenderer && column.headerRenderer({ column })}
              </div>
            );
          })}
        </div>
        <Grid
          ref={e => (this.gridRef = e)}
          columnCount={columns.length + 2}
          columnWidth={columnIndex => {
            const realColumnIndex = columnIndex - 1;
            return realColumnIndex === -1
              ? 50
              : ((columns[realColumnIndex] && columns[realColumnIndex].width) || 1) * columnW;
          }}
          height={tableHeight}
          rowCount={showNewRow ? data.length + 1 : data.length}
          rowHeight={() => rowHeight}
          width={width}
          style={{ overflowX: 'hidden', overflowY: 'scroll' }}
          className="Grid"
        >
          {args => (
            <Cell<T>
              {...args}
              rowIndex={showNewRow ? args.rowIndex - 1 : args.rowIndex}
              data={data}
              columns={columns}
              menus={menus}
              onClick={onClick}
              onSelect={onSelect}
              state={this.state}
              updateState={this.handleUpdateState}
              showNewRow={showNewRow}
              renameRowIndex={renameRowIndex}
              onInputBlur={onInputBlur}
              selected={(() => {
                if (isSelected) {
                  return isSelected({ rowIndex: args.rowIndex, rowData: data[args.rowIndex] });
                }
                return false;
              })()}
              showCheckbox={showCheckbox}
            />
          )}
        </Grid>
      </div>
    );
  }
}

export default Table;
