import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { changeLocale } from '../i18n/action';
import withProjectFolders, { WithProjectFolders } from '../Model/withProjectFolders';
import { IState } from '../rootReducer';
import Logo from '../static/svg/icon_home_MBUILD.svg';
import './Sider.scss';
import UserProfile from './UserProfile';
import Beian from '../Beian';

type State = {
  isUserMenuVisible: boolean;
};

type StateProps = {
  locale: string;
};

type DispatchProps = {
  changeLocale: typeof changeLocale;
};

type OwnProps = {
  showContent: boolean;
  children: React.ComponentType;
};

type Props = StateProps & DispatchProps & OwnProps;

class Sider extends React.Component<WithProjectFolders<Props>, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isUserMenuVisible: false
    };
  }

  render() {
    const { changeLocale, locale, showContent } = this.props;

    return (
      <div className={classNames('Sider', { 'Sider--show': showContent })}>
        <div className="Sider__logo">
          <img className="Sider__logo--logoIcon" src={Logo} />
        </div>
        {showContent && (
          <div className="Sider__container">
            {this.props.children}
            <div className="Sider__userContainer">
              <UserProfile />
              <div className="Sider__globalization">
                <div
                  className={classNames('Sider__engilsh', {
                    'Sider__engilsh--active': locale === 'en'
                  })}
                  onClick={() => {
                    if (locale !== 'en') {
                      changeLocale('en');
                      localStorage.setItem('locale', 'en');
                    }
                  }}
                >
                  EN
                </div>
                <div className="Sider__divid">|</div>
                <div
                  className={classNames('Sider__chinese', {
                    'Sider__chinese--active': locale === 'zh'
                  })}
                  onClick={() => {
                    if (locale !== 'zh') {
                      changeLocale('zh');
                      localStorage.setItem('locale', 'zh');
                    }
                  }}
                >
                  CN（中文）
                </div>
              </div>
            </div>
            <Beian />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state: IState) => ({
    locale: state.locale,
  }),
  { changeLocale }
)(withProjectFolders(Sider));
