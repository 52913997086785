import React from 'react';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import './LoadingDialog.scss';

export type LoadingDialogDataProps = { 
    done: () => void,
    dialogContent : {
        title : string,
        content : string,
        image : string,
    },
};

type State = {
    name: string;
    selectedRows: string[];
};

class LoadingDialog extends React.Component< DialogProps<LoadingDialogDataProps> ,State > {

    static size = {
        width: 210,
        height: 116
    };
    
    static showCloseButton = false;
    render() {
        const  propsData  = this.props.dialog.data;
        if(!propsData){
            return null
        }else{
            return (
                propsData && <div className='LoadingDialog'>
                    <div className='LoadingDialogWrap'>
                        {propsData.dialogContent.title && (<div className='LoadingDialog_title'>{propsData.dialogContent.title}</div>)}
                        {propsData.dialogContent.image && (<div style={{backgroundImage: 'url('+require(`../static/svg/${propsData.dialogContent.image}`)+')'}} className='LoadingDialog_img rotateTransition'></div>)}
                        {propsData.dialogContent.content && (<div className='LoadingDialog_content'>{propsData.dialogContent.content}</div>)} 
                    </div>
                </div>
            )
        }
    } 
}


export default LoadingDialog;