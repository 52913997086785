export default {
  MODELO: 'Modelo',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  REGISTER: 'Register',
  LOGIN_ACCOUNT_ERROR: 'Are sure the account is correct?',
  LOGIN_PASSWORD_ERROR: 'User and password does not match!',
  PLUGIN: 'Plugin',
  USER_GUIDE: 'User Guide',
  SWITCH_LOCALE: 'Switch Language',
  CHINESE: 'Chinese',
  ENGLISH: 'English',
  NAME: 'Name',
  UPLOAD: 'Upload',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  CONFIRM: 'Confrim',
  MOVE: 'Move',
  DELETE: 'Delete',
  EDIT: 'Edit',
  LINK: 'LINK',
  NEXT: 'Next',
  BACK: 'Back',
  UPDATE: 'Updates',
  SEND: 'Send',
  HIDE: 'Hide',
  CAUTION: 'Caution',
  COMMENT: 'Comment',
  COMMENT__TASK: 'Comment',
  SHOW: 'Show',
  SETTINGS: 'Settings',
  TOP: 'Top',
  BOTTOM: 'Bottom',
  LEFT: 'Left',
  RIGHT: 'Right',
  FRONT: 'Front',
  BACK__CAMERA: 'Back',
  ISOMETRIC: 'Isometric',
  SECTION: 'Section',
  DOWNLOAD: 'Download',
  SAVE: 'Save',
  SAVE__SELECTION: 'Save',
  VIEW: 'View',
  VERSION: 'Version',
  SELECT: 'Select',
  SEARCH: 'Search',
  CURRENT_VERSION: 'Current version',
  MANAGE_VERSIONS: 'Manage versions',
  OR: 'or',
  TAG: 'Tag',
  TASK: 'Task',
  TASK_TREE: 'Task tree',
  START: 'Start',
  FINISH: 'Finish',
  ATTACHMENT: 'Attachment',
  DESCRIBE: 'Describe',
  DOCUMENT: 'Documents',
  EXAMPLE: 'Examples',
  COPY_TOKEN: 'CopyToken',
  INTRODUCE_APPLICATION_DEVELOPMENT_FRAMEWORK_HOW_TO_QUICKLY_STARED:
    'Introduce the API',
  UPDATE_LATEST_API_TO_USE_MORE_ENTERPRISE_APP: 'Create more powerful enterprise applications',
  CHANGE_LOG: 'Change Log',
  DOWNLOAD_PULGINS_UPLOAD_EXPERIENCE_MORE_OPTIMIZED: 'For better uploading experience',
  FILE_TYPE: 'File Type',
  CREATE_AT: 'Created At',
  DOWNLOAD_SOURCE_FILE: 'Download File',
  ARE_YOU_SURE_YOU_WANT_TO_REGENERATE_THE_APP_TOKEN: 'Are you sure you want to perform the action?',
  FAILURE_TO_READ_THE_FOLLOWING_NOTICES_MAY_RESULT_IN_SERIOUS_DAMAGE: 'Failure to read the following notices may result in serious damage!',
  THIS_OPREAION_CANNOT_BE_UNDONE_THIS_WILL_REGENERATE_A_KEY: 'This operation cannot be undone. This will regenerate a app token',
  PLEASE_ENTER_YOUR_PASSWORD: 'Please enter your password',
  THE_PASSWORD_YOU_ENTERED_IS_INCORRECT_PLEASE_REENTER: 'The password you entered is incorrect. Please re-enter',
  NEW_GENERATE_KEY: 'Generate New Key',
  THE_UTMOST_WEBGL_BASED_BIM_ENGINE: 'The utmost WebGL based BIM engine',
  FOLDER: ' folder',
  ALL_FOLDERS: 'All folders',
  ARE_YOU_SURE_TO_DELETE: 'Are you sure to delete',
  QUIT: 'Quit',
  ALREADY_SELECTED: '{ count } items selected',
  DOWNLOAD_PROGRAMMING: 'Download Programming Guide',
  PROGRAMMING_GUIDE: 'Programming Guide',
  DOWNLOAD_API: 'Download API',
  DOWNLOAD_PLUGINS: 'Download Plugins',
  COMING_SOON: 'coming soon',

  BI_OVERVIEW: 'BI overview',
  MODELS: 'Models',
  MODEL: 'Model',
  MODEL_LOWER_CASE: ' models',
  MODELS__COLUMN: 'Models',
  ASSETS: 'Assets',
  ASSETS_LOWER_CASE: 'assets',
  ASSETS__COLUMN: 'files',
  TEAM_MEMBERS: 'Team members',
  PROJECT_WBS: 'Project WBS',
  QUALITY_ASSURANCE: 'Quality assurance',

  NO_DATA: 'No data',
  NEW_FOLDER: 'New folder',
  UPLOAD_MODEL: 'Upload model',
  COMBINE_MODELS: 'Combine models',
  COMBINE: 'Combine',
  AFTERCOMBINE: 'Calibration then combine',
  UPLOAD_ASSET: 'Upload asset',
  UPLOAD_PANORAMA: 'Upload panorama',
  CREATED_ON: 'Created on',
  CREATOR: 'Creator',
  RENAME_FOLDER: 'Rename folder',
  CHANGE_FOLDER: 'Change folder',
  SHARE_MODEL: 'Share model',
  REUPLOAD_MODEL: 'Reupload model',
  UPLOAD_NEW_VERSION: 'Upload new version',
  MOVE_FILE: 'Move file',
  SELECT_TARGET_FOLDER: 'Selecte the folder you would like to move the file to',
  FILE_TYPE_NOT_SUPPORTED: 'This file type is not supported.',
  USING_REVIT: 'Using Revit?',
  GET_EXPORTER_PLUGIN: 'Get the exporter plugin',
  CLICK_ON_THE_MODEL_TO_SELECT_THE_MODEL_FILE_HERE: 'Click here to select a model file from your computer or drag and drop it here',// TODO
  REDUCE_TEXTURE_FILE_SIZE: 'Reduce texture file size for faster loading',// TODO
  GET_UPLOAD_TOKEN: 'Get upload token',
  DELETE_MODEL: 'Delete model',
  DELETE_FOLDER: 'Delete folder',
  DELETE_CURRENT_VERSION: 'Delete current version',
  DELETE_ALL_VERSIONS: 'Delete all versions',
  CANNOT_BE_UNDONE: 'This cannot be undone.',
  CHECK_BOX_TO_CONFIRM: 'Check box to confirm.',
  CHECK_BOXES_TO_CONFIRM: 'Check boxes to confirm.',
  ALL_DESIGN_REVIEWS_WILL_BE_DELETED: 'All design reviews for this model will be deleted',
  ARE_YOU_SURE_TO_DELETE_FOLDER: 'Are you sure you would like to delete this Folder',
  ARE_YOU_SURE_TO_DELETE_MODEL: 'Are you sure you would like to delete "{model}"?',
  ARE_YOU_SURE_TO_DELETE_MODEL__ALL_VERSIONS:
    'Are you sure you would like to delete <span style="color: red;">ALL VERSIONS</span> of "{model}"',
  ALL_VERSIONS: 'All versions',
  ALL_MODELS_IN_FOLDER_WILL_BE_DELETED: 'All models in this folder will be deleted',
  ALL_DESIGN_REVIEWS_IN_FOLDER_WILL_BE_DELETED: 'All design reviews in this folder will be deleted',
  ALL_DESIGN_REVIEWS_ASSOCIATED_WITH_THIS_MODEL_WILL_BE_DELETED:
    'All design reviews associated with this model will be deleted',
  UPLOADING_X_MODELS_X_ASSETS: 'Uploading { modelsLength } Models, { assetsLength } Assets',
  UPLOAD_MODEL_TO_CURRENT_FOLDER: 'Upload model to current folder',
  GENERATE_COMBINED_MODEL_IN_CURRENT_FOLDER: 'Generate a combined model in current folder',// TODO

  DELETE_ASSET: 'Delete assets',
  ARE_YOU_SURE_TO_DELETE_ASSET: 'Are you sure to delete asset',
  ALL_ASSET_IN_FOLDER_WILL_BE_DELETED: 'All assets in this folder will be deleted',
  SHARE_ASSET: 'Share asset',

  BIM_INFO: 'BIM info',
  BIM_TREE: 'BIM tree',
  GO_BACK: 'Go back',
  PROPERTY: 'Property',
  VIEW_BY: 'View by',
  LEVEL_VIEW: 'Level view',
  CATEGORY_VIEW: 'Category view',
  VIEW_BROWSER_BY: 'View browser by',
  ADD_FAMILY_PROPERTY: 'Add family type',
  ACCELERATED_RENDERING: 'Accelerated rendering',
  ENHANCED_RENDERING: 'Enhanced rendering',
  PROFILE_LINES: 'Profile lines',
  BRIGHTNESS: 'Brightness',
  CUTTING_SPEED: 'Cutting speed',
  DOWNLOADING_AND_PROCESSING_MODEL: 'Downloading and processing model...',
  DATA_EXTRACTION: 'Data extraction for reporting',
  DATA_OVERVIEW: 'Data overview',
  CHECK_DATA: 'Check data',
  PICK_DATA: 'Pick data',
  PICK: 'Pick',

  NO_WBS_CREATED: 'There is no WBS created at this project yet',
  GET_STARTED: 'Get started...',
  IMPORT_WBS_FILE: 'Import WBS file',
  LINK_BIM_MODEL: 'Link BIM model',
  IMPORT_WBS_TEMPLATE: 'Import your WBS template',
  SUPPORTED_FORMAT: 'Supported format',
  UPLOADING: 'Uploading',
  UPLOADED: 'Uploaded',
  HIDE_ALL: 'Hide all',
  SHOW_ALL: 'Show all',
  TYPE_YOUR_FILTER_HERE: 'Type your filter here',
  RENAME_TASK: 'Rename task',
  ADD_TASK: 'Add task',
  DELETE_TASK: 'Delete task',
  SHOW_3D: 'Show 3D',
  WBS_NOT_COMPLETED: 'Project WBS has not been completed yet',
  QUALITY_ASSURANCE_WILL_BE_GENERATED: 'Quality assurance will be generated once the WBS is done',

  LOAD_MODEL: 'Load model',

  UT_LENGTH_FEET: 'm',
  UT_AREA_FEET_2: 'm²',
  UT_VOLUME_FEET_3: 'm³',

  MODELO_PROPERTIES: 'Modelo properties',
  MODELO_CONNECTED_DATA: 'Connected data',
  PG_GEO_LOCATION: 'Geo location',
  PG_STRUCTURAL_SECTION_GEOMETRY: 'Structural section geometry',
  PG_ENERGY_ANALYSIS_BLDG_CONS_MTL_THERMAL_PROPS: 'Energy analysis bldg cons mtl thermal props',
  PG_ENERGY_ANALYSIS_ROOM_SPACE_DATA: 'Energy analysis room space data',
  PG_ENERGY_ANALYSIS_BUILDING_DATA: 'Energy analysis building data',
  PG_COUPLER_ARRAY: 'Coupler array',
  PG_ENERGY_ANALYSIS_ADVANCED: 'Energy analysis advanced',
  PG_RELEASES_MEMBER_FORCES: 'Releases member forces',
  PG_SECONDARY_END: 'Secondary end',
  PG_PRIMARY_END: 'Primary end',
  PG_MOMENTS: 'Moments',
  PG_FORCES: 'Forces',
  PG_FABRICATION_PRODUCT_DATA: 'Fabrication product data',
  PG_REFERENCE: 'Reference',
  PG_GEOMETRY_POSITIONING: 'Geometry positioning',
  PG_DIVISION_GEOMETRY: 'Division geometry',
  PG_SEGMENTS_FITTINGS: 'Segments fittings',
  PG_CONTINUOUSRAIL_END_TOP_EXTENSION: 'Continuousrail end top extension',
  PG_CONTINUOUSRAIL_BEGIN_BOTTOM_EXTENSION: 'Continuousrail begin bottom extension',
  PG_STAIRS_WINDERS: 'Stairs winders',
  PG_STAIRS_SUPPORTS: 'Stairs supports',
  PG_STAIRS_OPEN_END_CONNECTION: 'Stairs open end connection',
  PG_RAILING_SYSTEM_SECONDARY_FAMILY_HANDRAILS: 'Railing system secondary family handrails',
  PG_TERMINTATION: 'Termintation',
  PG_STAIRS_TREADS_RISERS: 'Stairs treads risers',
  PG_STAIRS_CALCULATOR_RULES: 'Stairs calculator rules',
  PG_SPLIT_PROFILE_DIMENSIONS: 'Split profile dimensions',
  PG_LENGTH: 'Length',
  PG_NODES: 'Nodes',
  PG_ANALYTICAL_PROPERTIES: 'Analytical properties',
  PG_ANALYTICAL_ALIGNMENT: 'Analytical alignment',
  PG_SYSTEMTYPE_RISEDROP: 'Systemtype risedrop',
  PG_LINING: 'Lining',
  PG_INSULATION: 'Insulation',
  PG_OVERALL_LEGEND: 'Overall legend',
  PG_VISIBILITY: 'Visibility',
  PG_SUPPORT: 'Support',
  PG_RAILING_SYSTEM_SEGMENT_V_GRID: 'Railing system segment v grid',
  PG_RAILING_SYSTEM_SEGMENT_U_GRID: 'Railing system segment u grid',
  PG_RAILING_SYSTEM_SEGMENT_POSTS: 'Railing system segment posts',
  PG_RAILING_SYSTEM_SEGMENT_PATTERN_REMAINDER: 'Railing system segment pattern remainder',
  PG_RAILING_SYSTEM_SEGMENT_PATTERN_REPEAT: 'Railing system segment pattern repeat',
  PG_RAILING_SYSTEM_FAMILY_SEGMENT_PATTERN: 'Railing system family segment pattern',
  PG_RAILING_SYSTEM_FAMILY_HANDRAILS: 'Railing system family handrails',
  PG_RAILING_SYSTEM_FAMILY_TOP_RAIL: 'Railing system family top rail',
  PG_CONCEPTUAL_ENERGY_DATA_BUILDING_SERVICES: 'Conceptual energy data building services',
  PG_DATA: 'Data',
  PG_ELECTRICAL_CIRCUITING: 'Electrical circuiting',
  PG_GENERAL: 'General',
  PG_FLEXIBLE: 'Flexible',
  PG_ENERGY_ANALYSIS_CONCEPTUAL_MODEL: 'Energy analysis conceptual model',
  PG_ENERGY_ANALYSIS_DETAILED_MODEL: 'Energy analysis detailed model',
  PG_ENERGY_ANALYSIS_DETAILED_AND_CONCEPTUAL_MODELS: 'Energy analysis detailed and conceptual models',
  PG_FITTING: 'Fitting',
  PG_CONCEPTUAL_ENERGY_DATA: 'Conceptual energy data',
  PG_AREA: 'Area',
  PG_ADSK_MODEL_PROPERTIES: 'Adsk model properties',
  PG_CURTAIN_GRID_V: 'Curtain grid v',
  PG_CURTAIN_GRID_U: 'Curtain grid u',
  PG_DISPLAY: 'Display',
  PG_ANALYSIS_RESULTS: 'Analysis results',
  PG_SLAB_SHAPE_EDIT: 'Slab shape edit',
  PG_LIGHT_PHOTOMETRICS: 'Light photometrics',
  PG_PATTERN_APPLICATION: 'Pattern application',
  PG_GREEN_BUILDING: 'Green building',
  PG_PROFILE_2: 'Profile 2',
  PG_PROFILE_1: 'Profile 1',
  PG_PROFILE: 'Profile',
  PG_TRUSS_FAMILY_BOTTOM_CHORD: 'Truss family bottom chord',
  PG_TRUSS_FAMILY_TOP_CHORD: 'Truss family top chord',
  PG_TRUSS_FAMILY_DIAG_WEB: 'Truss family diag web',
  PG_TRUSS_FAMILY_VERT_WEB: 'Truss family vert web',
  PG_TITLE: 'Title',
  PG_FIRE_PROTECTION: 'Fire protection',
  PG_ROTATION_ABOUT: 'Rotation about',
  PG_TRANSLATION_IN: 'Translation in',
  PG_ANALYTICAL_MODEL: 'Analytical model',
  PG_REBAR_ARRAY: 'Rebar array',
  PG_REBAR_SYSTEM_LAYERS: 'Rebar system layers',
  PG_CURTAIN_GRID: 'Curtain grid',
  PG_CURTAIN_MULLION_2: 'Curtain mullion 2',
  PG_CURTAIN_MULLION_HORIZ: 'Curtain mullion horiz',
  PG_CURTAIN_MULLION_1: 'Curtain mullion 1',
  PG_CURTAIN_MULLION_VERT: 'Curtain mullion vert',
  PG_CURTAIN_GRID_2: 'Curtain grid 2',
  PG_CURTAIN_GRID_HORIZ: 'Curtain grid horiz',
  PG_CURTAIN_GRID_1: 'Curtain grid 1',
  PG_CURTAIN_GRID_VERT: 'Curtain grid vert',
  PG_IFC: 'IFC',
  PG_AELECTRICAL: 'Aelectrical',
  PG_ENERGY_ANALYSIS: 'Energy analysis',
  PG_STRUCTURAL_ANALYSIS: 'Structural analysis',
  PG_MECHANICAL_AIRFLOW: 'Mechanical airflow',
  PG_MECHANICAL_LOADS: 'Mechanical loads',
  PG_ELECTRICAL_LOADS: 'Electrical loads',
  PG_ELECTRICAL_LIGHTING: 'Electrical lighting',
  PG_TEXT: 'Text',
  PG_VIEW_CAMERA: 'View camera',
  PG_VIEW_EXTENTS: 'View extents',
  PG_PATTERN: 'Pattern',
  PG_CONSTRAINTS: 'Constraints',
  PG_PHASING: 'Phasing',
  PG_MECHANICAL: 'Mechanical',
  PG_STRUCTURAL: 'Structural',
  PG_PLUMBING: 'Plumbing',
  PG_ELECTRICAL: 'Electrical',
  PG_STAIR_STRINGERS: 'Stair stringers',
  PG_STAIR_RISERS: 'Stair risers',
  PG_STAIR_TREADS: 'Stair treads',
  PG_UNDERLAY: 'Underlay',
  PG_MATERIALS: 'Materials',
  PG_GRAPHICS: 'Graphics',
  PG_CONSTRUCTION: 'Construction',
  PG_GEOMETRY: 'Geometry',
  PG_IDENTITY_DATA: 'Identity data',
  INVALID: 'Invalid',

  ERROR_FILE_COULD_NOT_BE_LOADED: 'Error: File could not be loaded',
  PRESENT: 'Present',
  EXIT: 'Exit',
  UNDO: 'Undo',
  REDO: 'Redo',
  BORDER: 'Border',
  FILL: 'Fill',
  WIDTH: 'Width',
  HEIGHT: 'Height',
  TEXT: 'Text',

  LINK_INFO_FROM: 'Link info from… ',
  SELECT_FILE_YOU_WOULD_LIKE_TO_LINK_TO: 'Select the file you would like to link to',
  SUBMIT: 'Submit',
  ADD_TO: 'Add link',
  RELATED_FILES: 'Related Files',
  LOADING_DOTS: 'Loading...',
  SPLIT: 'Split',
  MATCHES: '{matches} Matches',

  COMBINATION: 'Select the model to be combined',
  SAVE_FILENAME: 'The file name is saved as:',
  SAVE_COMBINED_MODEL_NAME: 'the combined model name',
  SAVE_PATH: 'Save path:',
  COMBINED_MODELS: 'Combinatorial model',
  FILE_NOT_FOUND: 'File not found',

  PROGRESS: 'Progress',
  PROGRESS_PLAN: 'Progress Plan',
  SCHEDULE_QUALITY_MANAGE: 'Schedule Quality Manage',
  PERCENTAGE_OF_PROGRESS: 'Percentage Of Progress',
  TASK_TIME_CONSUMING_STATISTICS: 'Task Time-consuming Statistics',
  OUTPUT_VALUE: 'Output Value',
  COST_EXPENDITURE: 'Cost Expenditure',
  MONTHLY_STATISTICS_OF_QUALITY: 'Monthly Statistics Of Quality',
  MONTHLY_STATISTICS_OF_SAFETY: 'Monthly Statistics Of Safety',
  QUANTITY_STATISTICS: 'Quantity Statistics',

  FLOOR_PLAN: 'FloorPlan',
  ARRANGEMENT: 'Arrangement',
  THUMBNAIL: 'Thumbnail',
  FILE_MODE: 'File Mode',

  MEASURE: 'Measure',
  FEET_SHORTHAND: 'feet',
  INCH_SHORTHAND: 'inch',
  CM_SHORTHAND: 'cm',
  M_SHORTHAND: 'm',
  FEET2_SHORTHAND: 'square feet',
  M2_SHORTHAND: 'square meter',

  TEXT_DOESNT_MATCH_DELETE: "Text doesn't match Delete",
  PLEASE_ENTER_DELETE_TO_CONFIRM: 'Please enter delete to confirm',
  PLEASE_ENTER_FILE_NAME: 'Please enter file name',
  ARE_YOU_SURE_TO_DELETE_THIS_ITEM: 'Are you want to delete "{item}"?',
  DELETE_X: 'Delete {type}',

  FAILED_TO_DELETE_MODEL_DWG_LINK: 'Failed to delete model dwg link',
  FAILED_TO_UPDATE_MODEL_DWG_LINK: 'Failed to update model dwg link',
  ANGLE: 'Angle',

  ANGLE__DEGREE: 'Degree',
  ANGLE__RADIANS: 'Radians',
  MEASURE_ANGLE: 'Measure angle',

  DRAWING: 'Drawing',
  IMPORTED_SVGS: 'Imported DWGs',
  SELECT_DRAWINGS_TO_IMPORT: 'Select DWGs to import',
  FAILED_TO_ADD_MODEL_DWG_LINK: 'Failed to add model dwg link',

  DO_YOU_WANT_TO_SAVE_TRANSFORM_INFORMATION: 'Do you want to save transform information?',
  SAVE_MODEL_TRANSFORM: 'Save model transform',
  MODEL_TRANSFORM: 'Model transform',
  SCALING: 'Scaling',
  ROTATION: 'Rotation',
  SIZE: 'Size',
  INPUT_CANNOT_BE_NONE_NEGATIVE: 'Input cannot be none negative',
  INPUT_SHOULD_BE_IN_RANGE_0_TO_360: 'Input should be in range [0, 360)',

  PROGRESS_PLAN_CREATE_NEW: 'New Progress Plan',
  PROGRESS_PLAN_PROJECT: '',
  PROGRESS_PLAN_SORT_BY_TIME_ASC: '',
  PROGRESS_PLAN_SORT_BY_LETTER_ASC: '',
  PROGRESS_PLAN_SORT_BY_LETTER_DESC: '',
  PROGRESS_PLAN_WARNING_PROJECT_WILL_BE_INVALID: '',
  PROGRESS_PLAN_WARNING_PROJECT_CANNOT_BE_RESTORE: '',
  PROGRESS_PLAN_FINISH_PERCENT: '{percent} is finished',
  PROGRESS_PLAN_LINK_MODEL: '',
  PROGRESS_PLAN_RENAME_PROJECT: 'Rename project',
  PROGRESS_PLAN_LEAVE_PROJECT: 'Leave project',
  PROGRESS_PLAN_DELETE_PROJECT: 'Delete project',
  PROGRESS_PLAN_SELECT_FROM_DISK: 'Select from disk',
  PROGRESS_PLAN_GENERATE_FROM_MODEL: 'Generate from model',
  NEXT_STEP: 'Next step',
  PREV_STEP: 'Previous step',

  LEAVE_PROJECT: 'Leave project',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PROJECT: 'Are you sure you want to delete this project?',
  LEAVE: 'Leave',

  WBS_MODE: 'WBS mode',
  NETWORK_CHART: 'Network Chart',
  GNATT_CHART: 'Gantt Chart',
  EARNED_VALUE: 'Earned Value',
  TASK_CARD: 'Task Card',
  MODE_5D: '5D Mode',

  TASK_NAME: 'Task name',
  DURATION: 'Duration',
  SCHEDULE_STARTING_DAY: 'Scheduled Starting Day',
  SCHEDULE_FINISHING_DAY: 'Scheduled Finishing Day',
  ACTUAL_STARTING_DAY: 'Actual Starting Day',
  ACTUAL_FINISHING_DAY: 'Actual Finish Day',
  ESTIMATED_STARTING_DAY: 'Estimated Starting Day',
  ESTIMATED_FINISHING_DAY: 'Estimated Finishing Day',

  SCHEDULE: 'Schedule',
  ASSIGN: 'Assign',

  GNATT_CHART__SHORT: 'Chart',
  ESTIMATE: 'Estimate',
  ACTUAL: 'Actual',

  ADD_TASK_ABOVE: 'Add Task Above',
  ADD_TASK_BELOW: 'Add Task Below',
  ADD_CHILD_TASK: 'Add Child Task',
  DELETE_ROW: 'Delete Row',
  CUT: 'Cut',
  COPY: 'Copy',
  PASTE: 'Paste',
  EMBED_CHILD_SCHEDULE_PLAN: 'Embed child schedule plan',
  EMBED_BLANK_SCHEDULE_PLAN: 'Embed blank schedule plan',

  RENAME: 'Rename',
  DUPLICATE: 'Duplicate',

  EDIT_PREDECESSOR: 'Edit predecessor',
  PREDECESSORS: 'Predecessors',
  CURRENT_TASK: 'Current task',

  LINKED_MODEL_ELEMENTS: 'Linked model elements',
  BATCH_DELETE: 'Batch delete',

  EXPAND: 'Expand',
  EXPAND_ALL: 'Expand all',
  COLLAPSE: 'Collapse',
  COLLAPSE_ALL: 'Collapse all',
  EXPAND_TO_LEVEL_X_NODE: 'Expand to level {level} node',

  ASSIGNEE: 'Assignee',
  MANAGER: 'Manager',

  LINKED_MODEL_COMPONENTS: 'Linked model components',
  BATCH_ASSIGN_TO_SELECTED_TASKS: 'Batch assign to selected tasks',
  STATUS: 'Status',
  SIMULATION_COLOR: 'Simulated color',

  VIEW_3D: 'View 3D',
  CLOSE_3D: 'Close 3D',
  MARK_AS_FINISHED: 'Mark as finished',
  VIEW_QA_ISSUES: 'View QA issues',
  STARTING_DAY: 'Starting Day',
  FINISHING_DAY: 'Finishing Day',
  START_TO_FINISH_DAY: 'start-finish day',

  PRIORITY: 'Priority',
  QA_ISSUE: 'QA issue',
  QA_ISSUES_OVERVIEW: 'QA issues overview',
  CREATE_NEW_QA_ISSUE: 'Create new QA issue',
  HAS_X_QA_ISSUES: 'Has {issues} QA issues',
  TYPE_HERE: 'Type here...',
  ADD_PHOTO: 'Add photo',
  /*task priority*/
  NORMAL: 'Normal',
  CRITICAL: 'Critical',
  URGENT: 'Urgent',
  /*task progress*/
  TASK_NOTSTARTED: 'Task has not started',
  TASK_NORMAL: 'In normal progress',
  TASK_DELAY: 'Task may delay',
  TASK_COMPLETED: 'Task has completed',

  TODO: 'To do',
  DOING: 'Doing',
  DONE: 'Done',

  TAGS: 'Tags',
  COMMENTS: 'Comments',

  SIMULATE_BY_TIME: 'Simulate by time',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  DATE_RANGE: 'Date Range',

  NOT_STARTED: 'Not started',

  IN_PROGRESS: 'In progress',
  ALREADY_FINISHED: 'Already started',

  SELECT_ALL: 'Select all', //"全选",
  UN_SELECT_ALL: 'Unselect all', //
  SELECT_A_MESSAGE: 'Select a message',
  CLASSIFY_TO: 'Classify to ...',
  CREATE_QA_ISSUE_CARD: 'Create QA card',
  DRAG_AND_DROP_FILES_HERE: 'Drag and drop files here',
  AI_ASSISTANT: 'AI Assistant',

  AI_TASK_HANDLING: 'AI Task handling',
  WECHAT_RECORD_ANALYSIS: 'Wechat record analysis',
  SELECT_A_TASK_FROM_THE_LEFT_AND_THE_AI_WILL_TURN_IT_INTO_A_ISSUE:
    'Select a task from the left and the ai will turn it into an issue',

  WECHAT_CHAT_RECORDS: 'Wechat chat records',
  IMAGE_RECORDS: 'Images records',
  DELAYED: 'Delayed',

  NONE: 'None',
  CHECK_DETAILS: 'Check details',
  BELONG_TO: 'Belong to',

  OPEN: 'Open',
  RESOLVED: 'Resolved',

  FILTER: 'Filter',
  CLEAR_ALL_SELECTIONS: 'Clear all selections',
  CLEAR_DATE_RANGE: 'Clear Date Range',

  PREVIEW_DOES_NOT_SUPPORT_THIS_FILE_TYPE_YET: 'Preview does not support this file type yet',

  MERGE_SETTING_MOVE: 'Move',
  MERGE_SETTING_SCALE: 'Scale',
  MERGE_SETTING_TRANSFORM: 'Tranform',

  UPLOAD_MODEL_TODAM: 'Upload model to DAM',
  OUT_OF_CALIBRATION_TITLE: 'Out of calibration',
  OUT_OF_CALIBRATION_CONTENT: 'The edits you made will not be saved. Do you want to exit calibration?',
  OUT_OF_CALIBRATION_BTNS_CANCEL: 'Cancel',
  OUT_OF_CALIBRATION_BTNS_EXIT: 'Exit',

  COMBINE_MODEL_MESSAGE_DIALOG_TITLE: 'Combine model',
  COMBINE_MODEL_MESSAGE_DIALOG_CONTENT: 'hen models are merged, individual models are no longer available for editing. Are you sure about the merge model?',
  COMBINE_MODEL_MESSAGE_DIALOG_BTNS_CANCEL: 'Cancel',
  COMBINE_MODEL_MESSAGE_DIALOG_BTNS_MERGE: 'Merge',

  COMBINE_MODEL: 'Scene model ',
  EXIT_COMBINE_MODEL: 'Exit edit',
  MERGE_MODEL: 'Combine model',
  UPLOAD_TO_DAM:'Upload to DAM',
  VERIFY_UPLOAD_CODE: 'Verify upload code',

  COMBINE_MODELS_PLEASE_WAIT: 'Combining models… Please wait.',
  UPLOADING_MODELS_PLEASE_WAIT:'Uploading models… Please wait.',
  COMBINE_MODELS_FAILED: 'Something seems to have gone wrong with the model combination…We have informed I. M. Pei. Please try again later or contact us directly.',
  MODEL_UPLOAD_FAILED: 'Model upload failed... We have informed I. M. Pei. Please try again later or contact us directly.',
  CLOSE: 'CLOSE',

};
