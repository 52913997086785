import React from 'react';
import * as intl from 'react-intl-universal';
import { connect } from 'react-redux';
import locales from './locales';
import { IState } from '../../src/rootReducer';

class IntlProvider extends React.Component<{ locale: string }> {
  state = { ready: false };

  async componentDidMount() {
    await this.loadLocales(this.props);
    this.setState({ ready: true });
  }

  async loadLocales(props: { locale: string }) {
    await intl.init({ currentLocale: props.locale, locales });
  }

  render() {
    if (!this.state.ready) return null;

    return <>{this.props.children}</>;
  }
}

export default connect((state: IState) => ({
  locale: state.locale
}))(IntlProvider);
