import * as React from 'react';
import './RestAppTokenDialog.scss';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import { IAuthState } from '../Auth/reducers';
import api from '../api';
import intl from 'react-intl-universal';
import { lt } from '../i18n';

export type ResetAppTokenDialogData = {
  password: string;
};

type StateProps = {
  auth: IAuthState;
};

class RestAppTokenDialog extends React.Component<DialogProps<ResetAppTokenDialogData> & StateProps> {
  static size = {
    width: 654,
    height: 354
  };

  state = {
    error: ''
  };

  passwordRef: HTMLInputElement | null = null;

  handleReset = async () => {
    const { dialog, auth } = this.props;
    const username = auth.username;
    const password = this.passwordRef!.value;

    dialog.data = { password };

    this.setState({ error: '' });

    try {
      await api.loginUser({ username, password });
      // confirmed
      const { data } = await api.updateAppToken();
      localStorage.setItem('appToken', data.updatePermanentAccessToken);
      dialog.onConfirm && dialog.onConfirm(dialog);
    } catch (e) {
      this.setState({ error: '您输入的密码不正确，请重新输入' });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <div className="RestAppTokenDialog">
        <div className="RestAppTokenDialog__confirmTip">
          {intl.get(lt.ARE_YOU_SURE_YOU_WANT_TO_REGENERATE_THE_APP_TOKEN)}
        </div>
        <div className="RestAppTokenDialog__declaration">
          {intl.get(lt.THIS_OPREAION_CANNOT_BE_UNDONE_THIS_WILL_REGENERATE_A_KEY)}
        </div>
        <div className="RestAppTokenDialog__confirmPassWordTip">
          <div>{intl.get(lt.PLEASE_ENTER_YOUR_PASSWORD)}</div>
        </div>
        <div className="RestAppTokenDialog__confirmPasswordContainer">
          <input className="RestAppTokenDialog__confirmPassword" type="password" ref={e => (this.passwordRef = e)} />
          {error && (
            <div className="RestAppTokenDialog__errorMessage">
              {intl.get(lt.THE_PASSWORD_YOU_ENTERED_IS_INCORRECT_PLEASE_REENTER)}
            </div>
          )}
        </div>
        <div className="RestAppTokenDialog__confirmBtn" onClick={this.handleReset}>
          {intl.get(lt.NEW_GENERATE_KEY)}
        </div>
      </div>
    );
  }
}

export default connect((state: IState) => ({ auth: state.auth }))(RestAppTokenDialog);
