import React, { useEffect, useState, useRef, useContext } from 'react'
import config from '../../config';
import './CombineModel3d.scss';
import CombineModelLoading from '../CombineModelLoading/CombineModelLoading';
import { CombineModelEffect } from '../effects/combine-model-effect';
import { CombineContext } from '../CombineModelContext';

type Props = {
  modelIds: string[];
}

export default function CombineModel3d(props: Props) {
  const {modelIds} = props;
  const [loadProgress, setLoadProgress] = useState({});
  const ref = useRef<HTMLDivElement>(null);
  const {state, viewer, setViewer, setEffect} = useContext(CombineContext);

  useEffect(() => {
    let combineModelEffect: CombineModelEffect;
    const loadModel = async () => {
      const appToken = localStorage.getItem('token');
      Modelo.init({ endpoint: config.MODELO_API_URI, appToken: appToken ? appToken : ''});
      const viewer = new Modelo.View.Viewer3D('CombineModel3d', { isMobile: false });
      viewer.addInput(new Modelo.View.Input.Mouse(viewer));
      viewer.addInput(new Modelo.View.Input.Touch(viewer));
      viewer.setEffectEnabled('SSAO', true);
      viewer.setLightingLatitude(0.7);
      viewer.setLightingLongitude(1.4);
      viewer.setShadowEnabled(true);
      setLoadProgress(modelIds.reduce((p, c) => ({...p, [c]: 0}), {}))
      await Promise.all(
        modelIds.map(modelId =>
          viewer
            .loadModel(
              modelId,
              {
                onProgress: (progress: number) => {
                  setLoadProgress(p => ({ ...p, [modelId]: progress }));
                }
              },
              true
            )
        )
      );

      setViewer(viewer);
      combineModelEffect = new CombineModelEffect(viewer);
      combineModelEffect.run({showLog: true});
      setEffect(combineModelEffect);
    }
    ref && loadModel();
    return () => {
      combineModelEffect && combineModelEffect.stop();
    }
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      ref.current.style.marginLeft = state.treeVisible ? "400px" : "0px";
      if (viewer) {
        const width = ref.current.clientWidth;
        const height = ref.current.clientHeight;
        const calcWidth = state.treeVisible ? width - 400 : width + 400;
        ref.current.style.width = `${calcWidth}px`;
        (viewer as any).resize(calcWidth, height);
      }
    }
  }, [state.treeVisible, viewer]);
  
  return (
    <div id="CombineModel3d" ref={ref}>
      <CombineModelLoading progress={loadProgress} />
    </div>
  )
}
