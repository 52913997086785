import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import AssetPage from './Asset/AssetPage';
import Auth from './Auth/Auth';
import RequireAuth from './Auth/RequireAuth';
import Dialogs from './Common/Dialog/Dialogs';
import Content from './Home/Content';
import Sider from './Home/Sider';
import Tabs from './Home/Tabs';
import ModelPage from './Model/ModelPage';
import ModelViewer from './ModelViewer/ModelViewer';
import UpdatePage from './Update/UpdatePage';
import intl from 'react-intl-universal';
import { lt } from './i18n';
import { connect } from 'react-redux';
import { IState } from './rootReducer';
import DocPage from './Doc/DocPage';
import ModelSider from './Model/ModelSider';
import AssetSider from './Asset/AssetSider';
import UpdateSider from './Update/UpdateSider';
import AssetViewer from './AssetViewer/AssetViewer';
import Register from './Auth/Register';
import DwgViewer from './DwgViewer/DwgViewer';
import PanoViewer from './PanoViewer/PanoViewer';
import CombineModelViewer from './CombineModelViewer/CombineModelViewer';

function getLinks() {
  return [
    { name: `${intl.get(lt.MODELS)}`, path: '/models*', component: ModelPage, sider: <ModelSider /> },
    { name: `${intl.get(lt.ASSETS)}`, path: '/assets*', component: AssetPage, sider: <AssetSider /> },
    { name: `${intl.get(lt.DOCUMENT)}`, path: '/docs', component: DocPage, sider: null },
    { name: `${intl.get(lt.EXAMPLE)}`, path: 'https://api-samples.modeloapp.com', component: null, sider: null },
    { name: `${intl.get(lt.UPDATE)}`, path: 'https://build-blog.modeloapp.com/?page_id=901', component: null, sider: null }
  ];
}

class App extends React.Component {
  render() {
    const links = getLinks();
    return (
      <Router>
        <div className="App">
          <Route path="/login" exact component={Auth} />
          <Route path="/U2FsdGVkX19tmu5o1jYS7JThODmX8BpE" component={Register} />
          <Switch>
            <Redirect exact from="/" to="/models" />
            {links.map(link => {
              return (
                <PrivateRoute key={link.path} path={link.path} exact component={link.component} sider={link.sider} />
              );
            })}

            {/* <PrivateRoute path="/models/*" component={ModelPage} sider={<ModelSider />} /> */}
            {/* <PrivateRoute path="/assets/*" component={AssetPage} sider={<AssetSider />} /> */}
            <PrivateRoute path="/viewer/:modelId" component={ModelViewer} sider={false} only />
            <PrivateRoute path="/dwg-viewer/:dwgId" component={DwgViewer} sider={false} only />
            <PrivateRoute path="/pano-viewer/:panoId" component={PanoViewer} sider={false} only />
            <PrivateRoute path="/combine/:modelIds" component={CombineModelViewer} sider={false} only />

          </Switch>
          <Dialogs />
        </div>
      </Router>
    );
  }
}

function AppRoute({ only, path, sider, component: Component, ...rest }: any) {
  const links = getLinks();

  return (
    <Route
      {...rest}
      render={props => {
        if (only) {
          return <Component {...props} />;
        }
        return (
          <>
            <Sider showContent={!!sider}>{sider}</Sider>
            <Content>
              <>
                <Tabs links={links} />
                <Component {...props} />
              </>
            </Content>
          </>
        );
      }}
    />
  );
}

const PrivateRoute = RequireAuth(AppRoute) as any;

export default connect(
  (state: IState) => ({
    locale: state.locale
  }),
  {}
)(App);
