import React from 'react';
import './AssetViewer.scss';
import api from '../api';

type Props = {
  assetIds: number[];
  currentAssetId: number;
  onClose: () => void;
};

type State = {
  assets: { [id: number]: { id: number; name: string; downloadUrl: string } };
  currentId?: number;
};

class AssetViewer extends React.Component<Props, State> {
  state: State = {
    assets: {}
  };

  async componentDidMount() {
    const { assetIds, currentAssetId } = this.props;
    const { assets } = this.state;
    const asset = await api.getAsset({ assetId: currentAssetId });

    assets[asset.id] = asset;
    this.setState({ assets });
  }

  render() {
    const { onClose, currentAssetId, assetIds } = this.props;
    const { assets, currentId } = this.state;

    const currentAsset = currentId ? assets[currentId] : assets[currentAssetId] || null;

    return (
      <div className="AssetViewer">
        <div className="AssetViewer__header">
          {currentAsset && <div className="AssetViewer__name">{currentAsset.name}</div>}
          <div className="AssetViewer__headerSeparator" />
          <div className="AssetViewer__close" onClick={onClose} />
        </div>
        <div className="AssetViewer__content">
          <div
            className="AssetViewer__arrow AssetViewer__prev"
            onClick={async () => {
              const currentIndex = assetIds.findIndex(assetId => assetId === currentAsset.id);
              let prevIndex = currentIndex - 1;
              if (prevIndex < 0) prevIndex += assetIds.length;

              const prevAsset = await api.getAsset({ assetId: assetIds[prevIndex] });
              assets[prevAsset.id] = prevAsset;
              this.setState({ assets, currentId: prevAsset.id });
            }}
          />
          <div
            className="AssetViewer__arrow AssetViewer__next"
            onClick={async () => {
              const currentIndex = assetIds.findIndex(assetId => assetId === currentAsset.id);
              let nextIndex = currentIndex + 1;
              if (nextIndex > assetIds.length - 1) nextIndex = 0;

              const nextAsset = await api.getAsset({ assetId: assetIds[nextIndex] });
              assets[nextAsset.id] = nextAsset;
              this.setState({ assets, currentId: nextAsset.id });
            }}
          />
          {currentAsset && (
            <img className="AssetViewer__image" src={currentAsset.downloadUrl} alt={currentAsset.name} />
          )}
        </div>
      </div>
    );
  }
}

export default AssetViewer;
