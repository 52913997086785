import React from 'react';
import './DocPage.scss';

class DocPage extends React.Component {
  render() {
    return <div className="DocPage">
      <iframe src="https://api-doc.modeloapp.com" width="100%" height="100%" />
    </div>;
  }
}

export default DocPage;
