import React from 'react'
import './CombineModelLoading.scss';
import classNames from 'classnames';

type Props = {
  progress: any;
}

export default function CombineModelLoading(props: Props) {
  const {progress} = props; 
  const hidden = Object.keys(progress).every(k => progress[k] === 1);
  return (
    <ul className={classNames('CombineModelLoading', {'CombineModelLoading__hidden': hidden })}>
      {
        Object.keys(progress).map(item => <li key={item} className="CombineModelLoading__item">
          <span className="CombineModelLoading__label">模型{item}：</span>
          <span className="CombineModelLoading__value">{Math.floor(progress[item] * 100)}%</span>
        </li> )
      }
    </ul>
  )
}

