import React from 'react';
import Table, { TableColumn, TableMenu, TableData } from '../../Common/Table/Table';
import {
  GetRootLevelAssetsComponent,
  AssetStatus,
  GetAssetFolderContentsComponent,
  AssetType
} from '../../generated/graphql';
import { getAssets, getFolders, AssetRow, getPreviewAssets, previewTypes } from '../utils';
import './AssetTable.scss';
import intl from 'react-intl-universal';
import { lt } from '../../i18n';
import withProjectFolders, { WithProjectFolders } from '../../Model/withProjectFolders';
import { SortOptions, SortOrder, toggleSortOrder, getExtension } from '../../Common/utils/table';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IState } from '../../rootReducer';
import { IAssetTableState } from '../reducers/assetTable';
import { toggleDialog } from '../../Common/Dialog/actions';
import { toggleNewRow, changeSelectedRows } from '../actions/assetTable';
import api from '../../api';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dialog, DialogName } from '../../Common/Dialog/DialogTypes';
import { MoveAssetDialogDataProps } from './MoveAssetDialog';
import { middleTrimWithBoxWidth } from '../../Model/utils/String';
import AssetViewer from '../../AssetViewer/AssetViewer';

type State = {
  renameRowIndex?: number;
  sortOptions: SortOptions;
  textBoxWidth: number;
  showAssetViewer: boolean;
  currentAssetId?: number;
};

type StateProps = {
  assetTable: IAssetTableState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
  toggleNewRow: typeof toggleNewRow;
  changeSelectedRows: typeof changeSelectedRows;
};

type Props = WithProjectFolders<StateProps & DispatchProps & RouteComponentProps>;

class AssetTable extends React.Component<Props> {
  private table: HTMLElement | null = null;
  state: State = {
    sortOptions: { order: SortOrder.DESC, by: 'createdAt' },
    textBoxWidth: 325,
    showAssetViewer: false
  };

  updateDimensions = () => {
    const columnWidth = this.table!.offsetWidth * 0.32 - 74;
    this.setState({
      textBoxWidth: columnWidth
    });
  };

  componentDidMount() {
    this.table = document.querySelector('.Table');
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getColumns() {
    const columns: TableColumn<AssetRow>[] = [
      {
        accessor: 'name',
        width: 2,
        renderer: ({ row }) => {
          const isError = row.status === AssetStatus.Error;
          // only dwg file will go converting from uploaded status for now
          const isConverting = row.status === AssetStatus.Uploaded && row.fileType === AssetType.Dwg;
          const isFolder = row.fileType === 'folder';
          const { textBoxWidth } = this.state;

          let iconName = row.fileType;
          if (isError) iconName = 'convert_error';

          return (
            <div className="AssetTable__name">
              <div
                className={classNames('AssetTable__nameIcon', {
                  [`AssetTable__nameIcon--error`]: isError,
                  [`AssetTable__nameIcon--converting`]: isConverting,
                  [`AssetTable__nameIcon--${row.fileType}`]: true
                })}
              >
                {!isConverting && !isError && !isFolder && <div className="AssetTable__nameIconText">{iconName}</div>}
              </div>
              <span className="AssetTable__nameText">{middleTrimWithBoxWidth(row.name, textBoxWidth, 14)}</span>
            </div>
          );
        },
        headerRenderer: () => {
          const { order, by } = this.state.sortOptions;
          const sameHeader = by === 'name';
          const sortOptions: SortOptions = { order: sameHeader ? toggleSortOrder(order) : SortOrder.ASC, by: 'name' };

          return (
            <div className="AssetTable__header" onClick={() => this.setState({ sortOptions })}>
              {`${intl.get(lt.NAME)}`}
              {by === 'name' && (
                <div className={classNames('AssetTable__headerArrow', [`AssetTable__headerArrow--${order}`])} />
              )}
            </div>
          );
        }
      },
      {
        header: `${intl.get(lt.ASSETS)} ID`,
        accessor: 'assetId'
      },
      {
        header: `${intl.get(lt.FILE_TYPE)}`,
        accessor: 'fileType'
      },
      {
        header: `${intl.get(lt.CREATOR)}`,
        accessor: 'creator'
      },
      {
        headerRenderer: () => {
          const { order, by } = this.state.sortOptions;
          const sameHeader = by === 'createdAt';
          const sortOptions: SortOptions = {
            order: sameHeader ? toggleSortOrder(order) : SortOrder.ASC,
            by: 'createdAt'
          };

          return (
            <div className="AssetTable__header" onClick={() => this.setState({ sortOptions })}>
              {`${intl.get(lt.CREATE_AT)}`}
              {by === 'createdAt' && (
                <div className={classNames('AssetTable__headerArrow', [`AssetTable__headerArrow--${order}`])} />
              )}
            </div>
          );
        },
        accessor: 'createdAt'
      }
    ];
    return columns;
  }

  getTableMenus = () => {
    const { currentFolderId } = this.props;

    const menus: TableMenu<AssetRow>[] = [
      {
        name: `${intl.get(lt.CHANGE_FOLDER)}`,
        action: data => {
          this.props.toggleDialog(DialogName.MoveAsset, {
            data: { row: data.rowData },
            onCancel: () => {
              this.props.toggleDialog(DialogName.MoveAsset);
            },
            onConfirm: async (dialog: Dialog<MoveAssetDialogDataProps>) => {
              const isFolder = data.rowData.fileType === 'folder';
              const targetFolderId = dialog.data!.folderId;

              this.props.toggleDialog(DialogName.MoveAsset);
              data.done();

              if (isFolder) {
                await api.updateFolder({
                  folderType: 'asset',
                  folderId: Number(data.rowData.id),
                  parentFolderId: targetFolderId,
                  currentFolderId
                });
              } else {
                await api.updateAsset({
                  assetId: data.rowData.id,
                  parentFolderId: targetFolderId,
                  currentFolderId
                });
              }
            }
          });
        }
      },
      {
        name: `${intl.get(lt.RENAME_FOLDER)}`,
        shouldShow: data => {
          return data.rowData.fileType === 'folder';
        },
        action: data => {
          this.setState({ renameRowIndex: data.rowIndex }, data.done);
        }
      },
      {
        name: `${intl.get(lt.DOWNLOAD)}`,
        shouldShow: data => {
          return !['folder'].includes(data.rowData.fileType!);
        },
        action: async data => {
          const asset = await api.getAsset({ assetId: data.rowData.id });

          if (asset.downloadUrl) {
            window.open(asset.downloadUrl);
          }
        }
      },
      {
        name: `${intl.get(lt.DELETE)}`,
        action: data => {
          this.props.toggleDialog(DialogName.DeleteAsset, {
            onCancel: () => this.props.toggleDialog(DialogName.DeleteAsset),
            onConfirm: async dialog => {
              const isFolder = data.rowData.fileType === 'folder';

              this.props.toggleDialog(DialogName.DeleteAsset);
              data.done();

              if (isFolder) {
                await api.deleteFolder({ folderId: Number(data.rowData.id), currentFolderId });
              } else {
                await api.deleteAsset({ assetId: data.rowData.id, currentFolderId });
              }
            },
            data
          });
        }
      }
    ];

    return menus;
  };

  handleClickTable = (data: { rowIndex: number; rowData: AssetRow }) => {
    // clear selected rows
    this.props.changeSelectedRows([]);

    if (this.props.assetTable.showNewRow) {
      this.props.toggleNewRow();
    }

    if (data.rowData.status === AssetStatus.Error) {
      return;
    }

    // preview asset file
    if (data.rowData.fileType !== 'folder') {

      // use dwg viewer
      if (data.rowData.fileType === 'dwg') {
        this.props.history.push(`/dwg-viewer/${data.rowData.id}`);
        return;
      }

      // use pano viewer to show pano image
      if (data.rowData.type === AssetType.Image360) {
        this.props.history.push(`/pano-viewer/${data.rowData.id}`);
        return;
      }

      // asset viewer dialog
      // to preview normal image files
      if (previewTypes.includes(getExtension(data.rowData.name) as string)) {
        this.setState({ showAssetViewer: true, currentAssetId: data.rowData.id });
      }

      return;
    }

    const current = this.props.location.pathname;
    this.props.history.push(`${current}/${data.rowData.id}`);
  };

  handleInputBlur = async (data: { rowIndex: number; rowData: AssetRow; inputValue: string }) => {
    const { assetTable, projectId, currentFolderId } = this.props;

    if (assetTable.showNewRow) {
      this.props.toggleNewRow();

      await api.addFolder({
        folderType: 'asset',
        projectId,
        parentFolderId: currentFolderId,
        name: data.inputValue || intl.get(lt.NEW_FOLDER)
      });
    } else {
      await api.updateFolder({
        folderType: 'asset',
        folderId: Number(data.rowData.id),
        name: data.inputValue,
        currentFolderId
      });

      this.setState({ renameRowIndex: undefined });
    }
  };

  handleSelect = (data: { rowIndex: number; rowData: AssetRow }, tableData: TableData<AssetRow>) => {
    let { selectedRows } = this.props.assetTable;

    if (selectedRows.map(r => r.id).includes(data.rowData.id)) {
      selectedRows = selectedRows.filter(x => x.id !== data.rowData.id);
    } else {
      selectedRows = selectedRows.concat({ ...tableData[data.rowIndex], rowIndex: data.rowIndex });
    }

    this.props.changeSelectedRows(selectedRows);
  };

  render() {
    const columns = this.getColumns();
    const { currentFolderId, assetTable } = this.props;
    const { renameRowIndex, sortOptions, showAssetViewer, currentAssetId } = this.state;

    const menus = this.getTableMenus();

    return (
      <div className="AssetTable">
        <GetAssetFolderContentsComponent variables={{ folderId: currentFolderId }}>
          {({ loading, data, startPolling, stopPolling }) => {
            let tableData: AssetRow[] = [];
            let previewAssets: any[] = [];

            if (loading) {
              tableData = [];
            } else {
              if (data!.folder) {
                const assets = getAssets(data!.folder!.assets!, sortOptions);
                const folders = getFolders(data!.folder!.childFolders!, sortOptions);
                tableData = [...folders, ...assets];

                previewAssets = getPreviewAssets(data!.folder!.assets!).map(asset => asset.id);
              }
            }

            if (tableData.some(x => x.status === AssetStatus.Uploaded && x.fileType === AssetType.Dwg)) {
              startPolling(5000);
            } else {
              stopPolling();
            }

            return (
              <>
                <Table<AssetRow>
                  data={tableData}
                  columns={columns}
                  menus={menus}
                  isSelected={row => !!assetTable.selectedRows.find(x => x.id === row.rowData.id)}
                  onClick={this.handleClickTable}
                  onSelect={data => this.handleSelect(data, tableData)}
                  showNewRow={assetTable.showNewRow}
                  renameRowIndex={renameRowIndex}
                  onInputBlur={this.handleInputBlur}
                  showCheckbox={assetTable.selectedRows.length > 0}
                />

                {!loading && showAssetViewer && currentAssetId && (
                  <AssetViewer
                    assetIds={previewAssets}
                    currentAssetId={currentAssetId}
                    onClose={() =>
                      this.setState({
                        showAssetViewer: false
                      })
                    }
                  />
                )}
              </>
            );
          }}
        </GetAssetFolderContentsComponent>
      </div>
    );
  }
}

export default withProjectFolders(
  connect<StateProps, DispatchProps, {}, IState>(
    (state: IState) => ({ assetTable: state.asset.assetTable }),
    { toggleDialog, toggleNewRow, changeSelectedRows }
  )(withRouter(AssetTable))
);
