import React from 'react';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import './ErrorDialog.scss';
import intl from 'react-intl-universal';
import { lt } from '../i18n';

export type ErrorDialogDataProps = { 
    done: () => void,
    dialogContent : {
        title : string,
        content : string,
        image : string,
    },
};

type State = {
    name: string;
    selectedRows: string[];
};

export default class ErrorDialog extends React.Component< DialogProps<ErrorDialogDataProps> ,State >{

    static size = {
        width: 580,
        height: 672
    };
    
    static showCloseButton = false;
    render() {

        const propsData = this.props.dialog.data;
        const { onCancel } = this.props.dialog;
        if(!propsData){
            return null;
        }else{
            return (
                propsData.dialogContent && <div className='ErrorDialog'>
                    {propsData.dialogContent.title && (<div className='ErrorDialog_title'>{propsData.dialogContent.title}</div>)}
                    <div className='ErrorDialog_image' style={{backgroundImage: 'url(' + require(`../static/img/${propsData.dialogContent.image}`) + ')'}}></div>
                    <div className='ErrorDialog_footer'>
                        <p className='ErrorDialog_contentMessage'>{propsData.dialogContent.content}</p>
                        <div className='ErrorDialog_btnClose'>
                            <span onClick={onCancel}>{intl.get(lt.CLOSE)}</span>
                        </div>
                    </div>
                </div>
            )
        }

        
    }

}