export default {
  MODELO: '模袋',
  EMAIL: '电子邮箱',
  PASSWORD: '密码',
  LOGOUT: '登出',
  LOGIN: '登录',
  REGISTER: '注册',
  LOGIN_ACCOUNT_ERROR: '该用户名不存在。',
  LOGIN_PASSWORD_ERROR: '用户名和密码不匹配。',
  PLUGIN: '插件',
  USER_GUIDE: '使用指南',
  SWITCH_LOCALE: '切换语言',
  CHINESE: '中文',
  ENGLISH: '英文',
  NAME: '名称',
  UPLOAD: '上传',
  CANCEL: '取消',
  CREATE: '创建',
  CONFIRM: '确定',
  MOVE: '移动',
  DELETE: '删除',
  EDIT: '编辑',
  LINK: '链接',
  NEXT: '下一个',
  BACK: '返回',
  UPDATE: '更新',
  SEND: '发送',
  HIDE: '隐藏',
  CAUTION: '注意',
  COMMENT: '批注',
  COMMENT__TASK: '评论',
  SHOW: '展示',
  SETTINGS: '设置',
  TOP: '上',
  BOTTOM: '下',
  LEFT: '左',
  RIGHT: '右',
  FRONT: '前',
  BACK__CAMERA: '后',
  ISOMETRIC: '等轴视图',
  SECTION: '剖切',
  DOWNLOAD: '下载',
  SAVE: '保存',
  SAVE__SELECTION: '提取',
  VIEW: '查看',
  VERSION: '版本',
  SELECT: '选择',
  SEARCH: '搜索',
  CURRENT_VERSION: '当前版本',
  MANAGE_VERSIONS: '管理版本',
  OR: '或',
  TAG: '标签',
  TASK: '任务',
  ATTACHMENT: '附件',
  DESCRIBE: '描述',
  TASK_TREE: '任务树',
  START: '开始',
  FINISH: '结束',
  BI_OVERVIEW: 'BI总览',
  MODELS: '模型',
  MODEL: '模型',
  MODEL_LOWER_CASE: '模型',
  MODELS__COLUMN: '个模型',
  ASSETS: '文件',
  ASSETS_LOWER_CASE: '文件',
  ASSETS__COLUMN: '文件',
  TEAM_MEMBERS: '团队成员',
  PROJECT_WBS: '项目WBS',
  QUALITY_ASSURANCE: '质量安全',
  DOCUMENT: '文档',
  EXAMPLE: '示例',
  COPY_TOKEN: '复制密钥',
  INTRODUCE_APPLICATION_DEVELOPMENT_FRAMEWORK_HOW_TO_QUICKLY_STARED: '介绍应用开发框架、如何快速入门api等',
  UPDATE_LATEST_API_TO_USE_MORE_ENTERPRISE_APP: '最新的API，开发更好的企业应用',
  CHANGE_LOG: '更新日志',
  DOWNLOAD_PULGINS_UPLOAD_EXPERIENCE_MORE_OPTIMIZED: '更优化的上传体验',
  FILE_TYPE: '文件类型',
  CREATE_AT: '创建日期',
  DOWNLOAD_SOURCE_FILE: '下载源文件',
  ARE_YOU_SURE_YOU_WANT_TO_REGENERATE_THE_APP_TOKEN: '确定要重新生成Application Token吗?',
  FAILURE_TO_READ_THE_FOLLOWING_NOTICES_MAY_RESULT_IN_SERIOUS_DAMAGE: '不阅读以下通知可能会使您承受严重损失!',
  THIS_OPREAION_CANNOT_BE_UNDONE_THIS_WILL_REGENERATE_A_KEY: '本次操作无法撤销。这将会重新生成一个密钥。',
  PLEASE_ENTER_YOUR_PASSWORD: '请输入登录密码',
  THE_PASSWORD_YOU_ENTERED_IS_INCORRECT_PLEASE_REENTER: '您输入的密码不正确，请重新输入',
  NEW_GENERATE_KEY: '生成新密钥',
  THE_UTMOST_WEBGL_BASED_BIM_ENGINE: '集美观与性能为一体的WebGL引擎',
  FOLDER: '文件夹',
  ALL_FOLDERS: '所有文件夹',
  ARE_YOU_SURE_TO_DELETE: '您确定要删除',
  QUIT: '退出',
  ALREADY_SELECTED: '已选中{ count }项',
  DOWNLOAD_PROGRAMMING: '开发指南下载',
  PROGRAMMING_GUIDE: '开发指南',
  DOWNLOAD_API: 'API 下载',
  DOWNLOAD_PLUGINS: '插件下载',
  COMING_SOON: '即将发布',

  NO_DATA: '没有数据',
  NEW_FOLDER: '新建文件夹',
  UPLOAD_MODEL: '上传模型',
  COMBINE_MODELS: '合并模型',
  COMBINE: '直接合并',
  AFTERCOMBINE: '校准后合并',
  DRAG_AND_DROP_FILES_HERE: '将文件拖到此处',
  UPLOAD_ASSET: '上传文件',
  UPLOAD_PANORAMA: '上传全景图',
  CREATED_ON: '创建于',
  CREATOR: '创建者',
  RENAME_FOLDER: '重命名',
  CHANGE_FOLDER: '移动',
  SHARE_MODEL: '分享模型',
  REUPLOAD_MODEL: '重新上传模型',
  UPLOAD_NEW_VERSION: '上传新版本',
  MOVE_FILE: '移动文件至',
  SELECT_TARGET_FOLDER: '选择目标文件夹',
  FILE_TYPE_NOT_SUPPORTED: '不支持该文件类型',
  USING_REVIT: '使用Revit?',
  GET_EXPORTER_PLUGIN: '获取导出插件',
  CLICK_ON_THE_MODEL_TO_SELECT_THE_MODEL_FILE_HERE: '点击选择模型，也可以将模型文件拽到此处',
  REDUCE_TEXTURE_FILE_SIZE: '压缩文理大小加快加载速度',
  GET_UPLOAD_TOKEN: '获取上传代码',
  DELETE_MODEL: '删除模型',
  DELETE_FOLDER: '删除文件夹',
  DELETE_CURRENT_VERSION: '删除当前版本',
  DELETE_ALL_VERSIONS: '删除所有版本',
  CANNOT_BE_UNDONE: '无法撤销',
  CHECK_BOX_TO_CONFIRM: '选中复选框进行确认',
  CHECK_BOXES_TO_CONFIRM: '选中下列复选框进行确认',
  ALL_DESIGN_REVIEWS_WILL_BE_DELETED: '所有设计批注将被删除',
  ARE_YOU_SURE_TO_DELETE_FOLDER: '您确定要删除该文件夹吗',
  ARE_YOU_SURE_TO_DELETE_MODEL: '您确定要删除 "{model}" 吗？',
  ARE_YOU_SURE_TO_DELETE_MODEL__ALL_VERSIONS:
    '您确定要删除 "{model}" 的<span style="color: red;">所有版本</span>吗？',
  ALL_VERSIONS: '全部版本',
  ALL_MODELS_IN_FOLDER_WILL_BE_DELETED: '该文件夹中的所有模型将被删除',
  ALL_DESIGN_REVIEWS_IN_FOLDER_WILL_BE_DELETED: '该文件夹中所有设计批注将被删除',
  ALL_DESIGN_REVIEWS_ASSOCIATED_WITH_THIS_MODEL_WILL_BE_DELETED: '该文件中所有设计批注将被删除',
  UPLOADING_X_MODELS_X_ASSETS: '上传 { modelsLength } 个模型，{ assetsLength } 个文件',
  UPLOAD_MODEL_TO_CURRENT_FOLDER: '上传模型到当前文件夹',
  GENERATE_COMBINED_MODEL_IN_CURRENT_FOLDER: '组合模型到当前文件夹',

  DELETE_ASSET: '删除文件',
  ARE_YOU_SURE_TO_DELETE_ASSET: '您确定要删除该文件吗',
  ALL_ASSET_IN_FOLDER_WILL_BE_DELETED: '该文件夹中的所有文件将被删除',
  SHARE_ASSET: '分享文件',

  BIM_INFO: 'BIM信息',
  BIM_TREE: '模型树',
  GO_BACK: '返回上级',
  PROPERTY: '属性',
  VIEW_BY: '分类方式',
  LEVEL_VIEW: '楼层视图',
  CATEGORY_VIEW: '类别视图',
  VIEW_BROWSER_BY: '视图选项',
  ADD_FAMILY_PROPERTY: '添加族属性',

  ACCELERATED_RENDERING: '渲染加速',
  ENHANCED_RENDERING: '增强渲染',
  PROFILE_LINES: '轮廓线',
  BRIGHTNESS: '亮度',
  CUTTING_SPEED: '剖切速度',
  DOWNLOADING_AND_PROCESSING_MODEL: '模型加载处理中',
  DATA_EXTRACTION: '数据报表提取',
  DATA_OVERVIEW: '查看全部数据',
  CHECK_DATA: '查看数据',
  PICK_DATA: '提取数据',
  PICK: '提取',

  NO_WBS_CREATED: '未创建WBS',
  GET_STARTED: '开始',
  IMPORT_WBS_FILE: '导入WBS文件',
  LINK_BIM_MODEL: '链接BIM模型',
  IMPORT_WBS_TEMPLATE: '导入WBS模板',
  SUPPORTED_FORMAT: '支持格式',
  UPLOADING: '正在上传',
  UPLOADED: '上传完毕',
  HIDE_ALL: '隐藏所有',
  SHOW_ALL: '显示所有',
  TYPE_YOUR_FILTER_HERE: '请在此输入您的过滤器',
  RENAME_TASK: '重命名任务',
  ADD_TASK: '添加任务',
  DELETE_TASK: '删除任务',
  SHOW_3D: '查看模型',
  WBS_NOT_COMPLETED: '未完成WBS',
  QUALITY_ASSURANCE_WILL_BE_GENERATED: '将创建质量保证任务',

  LOAD_MODEL: '加载模型',

  UT_LENGTH_FEET: 'm',
  UT_AREA_FEET_2: 'm²',
  UT_VOLUME_FEET_3: 'm³',

  MODELO_PROPERTIES: 'Modelo 属性',
  MODELO_CONNECTED_DATA: '关联数据',
  PG_GEO_LOCATION: '地理位置',
  PG_STRUCTURAL_SECTION_GEOMETRY: '结构剖面几何图形',
  PG_ENERGY_ANALYSIS_BLDG_CONS_MTL_THERMAL_PROPS: '材质热属性',
  PG_ENERGY_ANALYSIS_ROOM_SPACE_DATA: '房间/空间数据',
  PG_ENERGY_ANALYSIS_BUILDING_DATA: '建筑数据',
  PG_COUPLER_ARRAY: '集',
  PG_ENERGY_ANALYSIS_ADVANCED: '高级',
  PG_RELEASES_MEMBER_FORCES: '释放/杆件力',
  PG_SECONDARY_END: '次端点',
  PG_PRIMARY_END: '主端点',
  PG_MOMENTS: '力矩',
  PG_FORCES: '力',
  PG_FABRICATION_PRODUCT_DATA: '预制产品数据',
  PG_REFERENCE: '参照',
  PG_GEOMETRY_POSITIONING: '几何图形位置',
  PG_DIVISION_GEOMETRY: '分区几何图形',
  PG_SEGMENTS_FITTINGS: '管段和管件',
  PG_CONTINUOUSRAIL_END_TOP_EXTENSION: '延伸(结束/顶部)',
  PG_CONTINUOUSRAIL_BEGIN_BOTTOM_EXTENSION: '延伸(起始/底部)',
  PG_STAIRS_WINDERS: '转角',
  PG_STAIRS_SUPPORTS: '支撑',
  PG_STAIRS_OPEN_END_CONNECTION: '终点连接',
  PG_RAILING_SYSTEM_SECONDARY_FAMILY_HANDRAILS: '扶手 2',
  PG_TERMINTATION: '终端',
  PG_STAIRS_TREADS_RISERS: '踏板/踢面',
  PG_STAIRS_CALCULATOR_RULES: '计算规则',
  PG_SPLIT_PROFILE_DIMENSIONS: '直径 (长度单位或厚度的百分比)',
  PG_LENGTH: '长度',
  PG_NODES: '节点',
  PG_ANALYTICAL_PROPERTIES: '分析属性',
  PG_ANALYTICAL_ALIGNMENT: '分析平差',
  PG_SYSTEMTYPE_RISEDROP: '上升/下降',
  PG_LINING: '内衬',
  PG_INSULATION: '绝缘层',
  PG_OVERALL_LEGEND: '整个图例',
  PG_VISIBILITY: '可见性',
  PG_SUPPORT: '支座',
  PG_RAILING_SYSTEM_SEGMENT_V_GRID: 'V 网格',
  PG_RAILING_SYSTEM_SEGMENT_U_GRID: 'U 网格',
  PG_RAILING_SYSTEM_SEGMENT_POSTS: '支柱',
  PG_RAILING_SYSTEM_SEGMENT_PATTERN_REMAINDER: '图案余数',
  PG_RAILING_SYSTEM_SEGMENT_PATTERN_REPEAT: '图案重复',
  PG_RAILING_SYSTEM_FAMILY_SEGMENT_PATTERN: '线段图案(默认)',
  PG_RAILING_SYSTEM_FAMILY_HANDRAILS: '扶手 1',
  PG_RAILING_SYSTEM_FAMILY_TOP_RAIL: '顶部扶栏',
  PG_CONCEPTUAL_ENERGY_DATA_BUILDING_SERVICES: '能量模型 - 建筑设备',
  PG_DATA: '数据',
  PG_ELECTRICAL_CIRCUITING: '电气 - 线路',
  PG_GENERAL: '常规',
  PG_FLEXIBLE: '自适应构件',
  PG_ENERGY_ANALYSIS_CONCEPTUAL_MODEL: '能量分析模型',
  PG_ENERGY_ANALYSIS_DETAILED_MODEL: '详图模型',
  PG_ENERGY_ANALYSIS_DETAILED_AND_CONCEPTUAL_MODELS: '基本',
  PG_FITTING: '管件',
  PG_CONCEPTUAL_ENERGY_DATA: '概念能量数据',
  PG_AREA: '面积',
  PG_ADSK_MODEL_PROPERTIES: '模型属性',
  PG_CURTAIN_GRID_V: 'V 网格',
  PG_CURTAIN_GRID_U: 'U 网格',
  PG_DISPLAY: '显示',
  PG_ANALYSIS_RESULTS: '分析结果',
  PG_SLAB_SHAPE_EDIT: '编辑板形状',
  PG_LIGHT_PHOTOMETRICS: '光域',
  PG_PATTERN_APPLICATION: '填充图案应用',
  PG_GREEN_BUILDING: '绿色建筑属性',
  PG_PROFILE_2: '轮廓 2',
  PG_PROFILE_1: '轮廓 1',
  PG_PROFILE: '轮廓',
  PG_TRUSS_FAMILY_BOTTOM_CHORD: '下弦杆',
  PG_TRUSS_FAMILY_TOP_CHORD: '上弦杆',
  PG_TRUSS_FAMILY_DIAG_WEB: '斜腹杆',
  PG_TRUSS_FAMILY_VERT_WEB: '竖向腹杆',
  PG_TITLE: '标题文字',
  PG_FIRE_PROTECTION: '消防系统',
  PG_ROTATION_ABOUT: '转动',
  PG_TRANSLATION_IN: '平动',
  PG_ANALYTICAL_MODEL: '分析模型',
  PG_REBAR_ARRAY: '钢筋集',
  PG_REBAR_SYSTEM_LAYERS: '图层',
  PG_CURTAIN_GRID: '网格',
  PG_CURTAIN_MULLION_2: '网格 2 竖梃',
  PG_CURTAIN_MULLION_HORIZ: '水平竖梃',
  PG_CURTAIN_MULLION_1: '网格 1 竖梃',
  PG_CURTAIN_MULLION_VERT: '垂直竖梃',
  PG_CURTAIN_GRID_2: '网格 2',
  PG_CURTAIN_GRID_HORIZ: '水平网格',
  PG_CURTAIN_GRID_1: '网格 1',
  PG_CURTAIN_GRID_VERT: '垂直网格',
  PG_IFC: 'IFC 参数',
  PG_AELECTRICAL: '电气',
  PG_ENERGY_ANALYSIS: '能量分析',
  PG_STRUCTURAL_ANALYSIS: '结构分析',
  PG_MECHANICAL_AIRFLOW: '机械 - 流量',
  PG_MECHANICAL_LOADS: '机械 - 负荷',
  PG_ELECTRICAL_LOADS: '电气 - 负荷',
  PG_ELECTRICAL_LIGHTING: '电气 - 照明',
  PG_TEXT: '文字',
  PG_VIEW_CAMERA: '相机',
  PG_VIEW_EXTENTS: '范围',
  PG_PATTERN: '填充图案',
  PG_CONSTRAINTS: '约束',
  PG_PHASING: '阶段化',
  PG_MECHANICAL: '机械',
  PG_STRUCTURAL: '结构',
  PG_PLUMBING: '卫浴',
  PG_ELECTRICAL: '电气工程',
  PG_STAIR_STRINGERS: '梯边梁',
  PG_STAIR_RISERS: '踢面',
  PG_STAIR_TREADS: '踏板',
  PG_UNDERLAY: '基线',
  PG_MATERIALS: '材质和装饰',
  PG_GRAPHICS: '图形',
  PG_CONSTRUCTION: '构造',
  PG_GEOMETRY: '尺寸标注',
  PG_IDENTITY_DATA: '标识数据',
  INVALID: '其他',

  ERROR_FILE_COULD_NOT_BE_LOADED: '错误: 无法正确加载文件',
  PRESENT: 'Present',
  EXIT: '返回',
  UNDO: '撤销',
  REDO: '重做',
  BORDER: 'Border',
  FILL: '填充',
  WIDTH: '宽度',
  HEIGHT: '高度',
  TEXT: '文字',

  LINK_INFO_FROM: '链接文件',
  SELECT_FILE_YOU_WOULD_LIKE_TO_LINK_TO: '选择想要链接的文件',
  SUBMIT: '提交',
  ADD_TO: '添加',
  RELATED_FILES: '关联文件',
  LOADING_DOTS: '加载中...',

  SPLIT: '拆分',
  MATCHES: '{matches}个匹配结果',

  COMBINATION: '选择要组合的模型',
  SAVE_FILENAME: '文件名保存为:',
  SAVE_COMBINED_MODEL_NAME: '组合文件保存为',
  SAVE_PATH: '保存路径：',
  COMBINED_MODELS: '组合模型',

  FILE_NOT_FOUND: '文件不存在',

  PROGRESS: '进度情况',
  PROGRESS_PLAN: '进度计划',
  SCHEDULE_QUALITY_MANAGE: '进度质量管理',
  PERCENTAGE_OF_PROGRESS: '进度百分比',
  TASK_TIME_CONSUMING_STATISTICS: '任务耗时统计',
  OUTPUT_VALUE: '产值',
  COST_EXPENDITURE: '成本支出',
  MONTHLY_STATISTICS_OF_QUALITY: '质量逐月统计',
  MONTHLY_STATISTICS_OF_SAFETY: '安全逐月统计',
  QUANTITY_STATISTICS: '工程量统计',

  FLOOR_PLAN: '平面图',
  ARRANGEMENT: '排列方式',
  THUMBNAIL: '缩略图模式',
  FILE_MODE: '文件模式',

  MEASURE: '测量',
  FEET_SHORTHAND: '英尺',
  INCH_SHORTHAND: '英寸',
  CM_SHORTHAND: '厘米',
  M_SHORTHAND: '米',
  FEET2_SHORTHAND: '平方英尺',
  M2_SHORTHAND: '平方米',

  TEXT_DOESNT_MATCH_DELETE: '与“删除”不匹配',
  PLEASE_ENTER_DELETE_TO_CONFIRM: '请输入“删除”来确认',
  PLEASE_ENTER_FILE_NAME: '请输入文件名',
  ARE_YOU_SURE_TO_DELETE_THIS_ITEM: '您确定要删除 "{item}" 吗？',
  DELETE_X: '删除{type}',
  DRAWING: '图纸',
  IMPORTED_SVGS: '导入图纸',
  SELECT_DRAWINGS_TO_IMPORT: '选择要导入的图纸',
  FAILED_TO_DELETE_MODEL_DWG_LINK: '删除DWG和模型链接失败',
  FAILED_TO_UPDATE_MODEL_DWG_LINK: '更新DWG和模型链接失败',

  ANGLE__DEGREE: '角度',
  ANGLE__RADIANS: '弧度',
  MEASURE_ANGLE: '角度测量',

  DO_YOU_WANT_TO_SAVE_TRANSFORM_INFORMATION: '需要保存地形模型校准后的位置信息吗？',
  SAVE_MODEL_TRANSFORM: '保存校准信息',
  MODEL_TRANSFORM: '模型调整',
  SCALING: '缩放',
  ROTATION: '旋转',
  SIZE: '倍数',
  ANGLE: '角度',
  INPUT_CANNOT_BE_NONE_NEGATIVE: '输入值不可为负数',
  INPUT_SHOULD_BE_IN_RANGE_0_TO_360: '请输入[0, 360)之间的数值',

  PROGRESS_PLAN_CREATE_NEW: '创建新进度计划',
  PROGRESS_PLAN_PROJECT: '项目',
  PROGRESS_PLAN_SORT_BY_TIME_ASC: '按时间从远到近排列',
  PROGRESS_PLAN_SORT_BY_LETTER_ASC: '按字母从A到Z排列',
  PROGRESS_PLAN_SORT_BY_LETTER_DESC: ' 按字母从Z到A排列',
  PROGRESS_PLAN_WARNING_PROJECT_WILL_BE_INVALID: '将无法访问该项目',
  PROGRESS_PLAN_WARNING_PROJECT_CANNOT_BE_RESTORE: '这将不能被撤销',
  PROGRESS_PLAN_FINISH_PERCENT: '已完成项目的{percent}%',
  PROGRESS_PLAN_LINK_MODEL: '链接模型',
  PROGRESS_PLAN_RENAME_PROJECT: '重命名项目',
  PROGRESS_PLAN_LEAVE_PROJECT: '离开项目',
  PROGRESS_PLAN_DELETE_PROJECT: '删除项目',
  PROGRESS_PLAN_SELECT_FROM_DISK: '从您的电脑里选择进度计划文件',
  PROGRESS_PLAN_GENERATE_FROM_MODEL: '从模型文件生成进度计划文件',
  NEXT_STEP: '下一步',
  PREV_STEP: '上一步',

  LEAVE_PROJECT: '离开项目',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PROJECT: '你确定要离开这个项目吗？',
  LEAVE: '离开',

  WBS_MODE: 'WBS模式',
  NETWORK_CHART: '网络图模式',
  GNATT_CHART: '甘特图模式',
  EARNED_VALUE: '挣值分析模式',
  TASK_CARD: '任务卡模式',
  MODE_5D: '5D模式',

  TASK_NAME: '任务名称',
  DURATION: '时长',
  SCHEDULE_STARTING_DAY: '计划开始时间',
  SCHEDULE_FINISHING_DAY: '计划结束时间',
  ACTUAL_STARTING_DAY: '实际开始时间',
  ACTUAL_FINISHING_DAY: '实际结束时间',
  ESTIMATED_STARTING_DAY: '预计开始时间',
  ESTIMATED_FINISHING_DAY: '预计结果时间',

  SCHEDULE: '时间计划',
  ASSIGN: '指派',

  GNATT_CHART__SHORT: '甘特图',
  ESTIMATE: '预计',
  ACTUAL: '实际',

  ADD_TASK_ABOVE: '在前面添加任务',
  ADD_TASK_BELOW: '在后面添加任务',
  ADD_CHILD_TASK: '添加子任务',
  DELETE_ROW: '删除任务',
  CUT: '剪切',
  COPY: '拷贝',
  PASTE: '粘贴',
  EMBED_CHILD_SCHEDULE_PLAN: '嵌入子进度计划',
  EMBED_BLANK_SCHEDULE_PLAN: '嵌入一个空白进度计划',

  RENAME: '重命名',
  DUPLICATE: '重复',
  EDIT_PREDECESSOR: '编辑前置任务',
  PREDECESSORS: '前置任务',
  CURRENT_TASK: '当前任务',
  LINKED_MODEL_ELEMENTS: '链接模型构建',
  BATCH_DELETE: '批量移除',

  EXPAND: '展开',
  EXPAND_ALL: '全部展开',
  COLLAPSE: '收起',
  COLLAPSE_ALL: '全部收起',
  EXPAND_TO_LEVEL_X_NODE: '展开至第{level}层节点',

  ASSIGNEE: '执行者',
  MANAGER: '指派者',

  LINKED_MODEL_COMPONENTS: '链接模型构件',
  BATCH_ASSIGN_TO_SELECTED_TASKS: '集体指派到所选取的任务',
  STATUS: '状态',
  SIMULATION_COLOR: '模拟颜色',

  VIEW_3D: '查看3D模型',
  CLOSE_3D: '关闭3D模型',
  MARK_AS_FINISHED: '标记为已完成',
  VIEW_QA_ISSUES: '查看质量安全问题',
  STARTING_DAY: '开始日期',
  FINISHING_DAY: '截止日期',
  START_TO_FINISH_DAY: '开始-截止日期',

  PRIORITY: '优先级',
  QA_ISSUE: '质量安全问题',
  QA_ISSUES_OVERVIEW: '质量安全问题总览',
  CREATE_NEW_QA_ISSUE: '创建新的质量安全问题',
  HAS_X_QA_ISSUES: '有{issues}个质量安全问题',
  TYPE_HERE: '在此输入...',
  ADD_PHOTO: '添加图片',

  /*task priority*/
  NORMAL: '一般',
  CRITICAL: '紧急',
  URGENT: '非常紧急',
  /*task progress*/
  TASK_NOTSTARTED: '任务未开始',
  TASK_NORMAL: '正常进行中',
  TASK_DELAY: '任务可能延误',
  TASK_COMPLETED: '任务已完成',

  TODO: '待办',
  DOING: '进行中',
  DONE: '已完成',

  TAGS: '标签',
  COMMENTS: '评论',

  SIMULATE_BY_TIME: '按时间模拟',
  START_DATE: '开始日期',
  END_DATE: '结束日期',
  DATE_RANGE: '起止时间',

  NOT_STARTED: '未开始',
  DELAYED: '有延误',
  ALREADY_FINISHED: '已完成',

  SELECT_ALL: '全选',
  UN_SELECT_ALL: '清除选择',
  SELECT_A_MESSAGE: '已选一个对话',
  CLASSIFY_TO: '归类到...',
  CREATE_QA_ISSUE_CARD: '新建质安卡',

  AI_ASSISTANT: 'AI助手',

  AI_TASK_HANDLING: 'AI任务整理',
  WECHAT_RECORD_ANALYSIS: '微信记录分析',
  SELECT_A_TASK_FROM_THE_LEFT_AND_THE_AI_WILL_TURN_IT_INTO_A_ISSUE:
    '从左侧选取任务，AI助手将任务转化为质安卡',

  WECHAT_CHAT_RECORDS: '微信聊天记录',
  IMAGE_RECORDS: '照片记录',

  NONE: '暂无',
  CHECK_DETAILS: '查看详情',
  BELONG_TO: '属于',

  OPEN: '待办',
  IN_PROGRESS: '进行中',
  RESOLVED: '已解决',

  FILTER: '筛选',
  CLEAR_ALL_SELECTIONS: '清除全部选项',
  CLEAR_DATE_RANGE: '清除时间',

  PREVIEW_DOES_NOT_SUPPORT_THIS_FILE_TYPE_YET: '该文件暂不支持预览',
  
  MERGE_SETTING_MOVE: '移动',
  MERGE_SETTING_SCALE: '缩放',
  MERGE_SETTING_TRANSFORM: '旋转',

  UPLOAD_MODEL_TODAM: '上传模型到DAM',
  OUT_OF_CALIBRATION_TITLE: '退出校准',
  OUT_OF_CALIBRATION_CONTENT: '您所做的编辑将不会被保存，请问是否要退出校准？',
  OUT_OF_CALIBRATION_BTNS_CANCEL: '取消',
  OUT_OF_CALIBRATION_BTNS_EXIT: '退出',

  COMBINE_MODEL_MESSAGE_DIALOG_TITLE: '合并模型',
  COMBINE_MODEL_MESSAGE_DIALOG_CONTENT: '模型合并后，单独的模型将不可再进行编辑。请问您是否确定合并模型？',
  COMBINE_MODEL_MESSAGE_DIALOG_BTNS_CANCEL: '取消',
  COMBINE_MODEL_MESSAGE_DIALOG_BTNS_MERGE: '合并',

  COMBINE_MODEL: '场景模型',
  EXIT_COMBINE_MODEL: '退出编辑',
  MERGE_MODEL: '合并模型',
  UPLOAD_TO_DAM: '上传到DAM',
  VERIFY_UPLOAD_CODE: '验证上传代码',

  COMBINE_MODELS_PLEASE_WAIT: '模型合并中，请稍等…',
  UPLOADING_MODELS_PLEASE_WAIT:'模型上传中，请稍等…',
  COMBINE_MODELS_FAILED: '模型合并时似乎发生了一些错误…我们已经通知贝聿铭先生。请稍后再试，或者直接联系我们。',
  MODEL_UPLOAD_FAILED: '模型上传失败…我们已经通知贝聿铭先生。请稍后再试，或者直接联系我们。',
  CLOSE: '关闭',

};
