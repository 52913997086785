import modelTable, { IModelTableState } from './modelTable';
import { combineReducers } from 'redux';

export interface IModelState {
  modelTable: IModelTableState;
}

export default combineReducers({
  modelTable
});
