import React from 'react';

export default function ChangeLogs() {
  return (
    <>
      <h1 id="update-log">Update Log</h1>
      <h1 id="api-ver-200-1532019">API Ver 2.0.0 (15/3/2019)</h1>
      <p>
        <a href="https://s3.cn-north-1.amazonaws.com.cn/api-release.modeloapp.com/prod/modeloapi-2.0-lts.js">
          modeloapi-2.0-lts.js
        </a>
      </p>
      <ul>
        <li>Replace callbacks with promise in load model functions </li>
        <li>Support multiple viewer on the same webpage (only active one though)</li>
        <li>Weighted-OIT. Turned off by default.</li>
        <li>WebGL 1.0 ready.</li>
        <li>Enabled line rendering for .skp files.</li>
        <li>Rollback to 1xAA when 4xAA framebuffer is not supported.</li>
        <li>Remove global variables.</li>
        <li>Compare BIM models.</li>
        <li>Improved comments.</li>
      </ul>
      <h1 id="api-ver-11-20181108">API Ver 1.1 (2018/11/08)</h1>
      <p>
        <a href="https://s3.cn-north-1.amazonaws.com.cn/api-release.modeloapp.com/prod/modeloapi-1.1-lts.js">
          modeloapi-1.1-lts.js
        </a>
      </p>
      <ul>
        <li>Support merge model.</li>
        <li>Has measurement notations.</li>
        <li>Comments.</li>
        <li>The viewer initialization gets changed.</li>
      </ul>
      <h1 id="api-ver-10-20181008">API Ver 1.0 (2018/10/08)</h1>
      <p>
        <a href="https://s3.cn-north-1.amazonaws.com.cn/api-release.modeloapp.com/prod/modeloapi-1.0-lts.js">
          modeloapi-1.0-lts.js
        </a>
      </p>
      <ul>
        <li>The first version released, modeloapi-1.0-lts.js.</li>
      </ul>
    </>
  );
}
