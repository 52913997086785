import React from 'react';
import './index.scss';
import classNames from 'classnames';

export default function Beian(props: { className?: string }) {
  return (
    <div className={classNames('beian', props.className)}>
      <a href="https://beian.miit.gov.cn/" target="__black">©2022 沪ICP备17003425号-2</a>
      <a target="__black" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102004368" className="icp">
        <img src="//qhstaticssl.kujiale.com/image/png/1655783308239/icp-icon.png" alt="" className="icp__img" />
        <div>沪公网安备31010102004368号</div>
      </a>
    </div>
  )
}
