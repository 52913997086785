
export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE'
}

export type SortOptions = {
  order: SortOrder;
  by: 'name' | 'creator' | 'createdAt';
};

export const getExtension = (name: string) => {
  const parts = name.split('.');
  if (parts.length === 1) return null;

  return parts[parts.length - 1];
};

export const groupByCharacterType = (collection: any[], key: string) => {
  return collection.reduce(
    (p, c) => {
      if (!/[a-zA-Z]/.test(c[key][0])) {
        p[0].push(c);
        return p;
      }
      if (/[A-Z]/.test(c[key][0])) {
        p[1].push(c);
        return p;
      }
      if (/[a-z]/.test(c[key][0])) {
        p[2].push(c);
        return p;
      }
    },
    [[], [], []]
  );
};

export function toggleSortOrder(order: SortOrder) {
  if (order === SortOrder.NONE) {
    return SortOrder.ASC;
  } else if (order === SortOrder.ASC) {
    return SortOrder.DESC;
  } else {
    return SortOrder.NONE;
  }
}

