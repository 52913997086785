import * as intl from 'react-intl-universal';
import locales from './locales';

export const CHANGE_LOCALE = '@@Locale/CHANGE_LOCALE';

export default (store: any) => (next: any) => (action: any) => {
  if (action.type === CHANGE_LOCALE) {
    intl.init({ currentLocale: action.payload.locale, locales });
  }

  next(action);
};
