import { createAction } from '../../Common/utils/actions';
import { Dispatch } from 'redux';
import api from '../../api';
// import { login } from '../utils';
//
export const LOGIN_USER = '@Auth/LOGIN_USER';
export const LOGIN_USER_FULFILLED = '@Auth/LOGIN_USER_FULFILLED';
export const LOGIN_USER_REJECTED = '@Auth/LOGIN_USER_REJECTED';
export const LOGOUT_USER = '@Auth/LOGOUT_USER';

export const loginUserSuccess = (resp: Pick<api.LoginResponse, 'username'>) => createAction(LOGIN_USER_FULFILLED, resp);
export const logoutUser = () => createAction(LOGOUT_USER);

// export const loginUserRequest = (username: string, password: string) =>
//   createAction(LOGIN_USER_REQUEST, { username, password });
// export const loginUserSuccess = (token: string) => createAction(LOGIN_USER_SUCCESS, { token });
// export const loginUserFailure = (error: Error) => createAction(LOGIN_USER_FAILURE, { error });
//
// export const loginUser = (username: string, password: string) => async (dispatch: Dispatch) => {
//   dispatch(loginUserRequest(username, password));
//   try {
//     const resp = await login(username, password);
//     localStorage.setItem('token', resp.token);
//     return dispatch(loginUserSuccess(resp.token));
//   } catch (e) {
//     return dispatch(loginUserFailure(e));
//   }
// };
