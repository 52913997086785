import React from 'react';
import classNames from 'classnames';
import ModelDropzone from './ModelDropzone';
import './UploadModelDialog.scss';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import intl from 'react-intl-universal';
import { lt } from '../i18n';

export type UploadModelDialogData = {
  file: File;
  reduceTextures: boolean;
};

type Props = DialogProps<UploadModelDialogData>;

type State = {
  selectedFile: File | null;
  uploadingProcess: number;
  reduceTextures: boolean;
};

class UploadModelDialog extends React.Component<Props, State> {
  static size = {
    width: 746,
    height: 514
  };

  state: State = {
    selectedFile: null,
    uploadingProcess: 0,
    reduceTextures: false
  };

  handleReduceTextures() {
    this.setState({ reduceTextures: !this.state.reduceTextures });
  }

  handleChangeFile = (file: File) => {
    this.setState({ selectedFile: file });
  };

  handleUpload = async () => {
    const { selectedFile, reduceTextures } = this.state;
    const { dialog } = this.props;

    if (dialog.onConfirm && selectedFile) {
      dialog.data = { file: selectedFile, reduceTextures };
      dialog.onConfirm(dialog);
    }
  };

  handleCancel = () => {
    const { onCancel } = this.props.dialog;

    if (onCancel) {
      onCancel();
    }
  };

  render() {
    const { selectedFile, reduceTextures } = this.state;
    const checkBox = classNames('UploadModelDialog__checkBox', {
      'UploadModelDialog__checkBox--selected': reduceTextures
    });

    return (
      <div className="UploadModelDialog">
        <div className="UploadModelDialog__titleContainer">
          <div className="UploadModelDialog__title">{intl.get(lt.UPLOAD_MODEL_TO_CURRENT_FOLDER)}</div>
        </div>
        <ModelDropzone selectedFile={selectedFile} onFileChange={this.handleChangeFile} />
        <div className="UploadModelDialog__footer">
          <div className="UploadModelDialog__reconfirm">
            <div className={checkBox} onClick={() => this.handleReduceTextures()} />
            <div className="UploadModelDialog__reduceTexture">{intl.get(lt.REDUCE_TEXTURE_FILE_SIZE)}</div>
          </div>
          <div className="UploadModelDialog__manipulateContainer">
            <div className="UploadModelDialog__cancel" onClick={this.handleCancel}>
              {intl.get(lt.CANCEL)}
            </div>
            <div
              className={classNames('UploadModelDialog__upload', {
                'UploadModelDialog__upload--disabled': !selectedFile
              })}
              onClick={this.handleUpload}
            >
              {intl.get(lt.UPLOAD)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadModelDialog;
