import React from 'react';
import { NavLink } from 'react-router-dom';
import './Tabs.scss';
import { changeSelectedRows as changeModelSelectedRows } from '../Model/actions/modelTable';
import { changeSelectedRows as changeAssetSelectedRows } from '../Asset/actions/assetTable';
import { connect } from 'react-redux';

function isExternalLink(link: string) {
  return !link.startsWith('/');
}

type DispatchProps = {
  changeModelSelectedRows: typeof changeModelSelectedRows;
  changeAssetSelectedRows: typeof changeAssetSelectedRows;
};

type Props = {
  links: { name: string; path: string }[];
};

class Tabs extends React.Component<Props & DispatchProps> {
  links: { [path: string]: HTMLAnchorElement | null } = {};

  componentDidUpdate(nextProps: Props) {
    Object.keys(this.links)
      .map(k => this.links[k])
      .forEach(link => {
        if (!link) return;

        link.style.background = '';
        setTimeout(() => {
          if (!link.classList.contains('Tabs__tab--active')) {
            link.style.background = 'linear-gradient(134deg, #0c9aff, #0561ff)';
            link.style.webkitBackgroundClip = 'text';
          }
        }, 0);
      });
  }

  render() {
    const { links } = this.props;
    return (
      <div className="Tabs">
        {links
          .map(link => ({ ...link, path: link.path.replace('*', '') }))
          .map(link => {
            if (!isExternalLink(link.path)) {
              return (
                <NavLink
                  key={link.path}
                  to={link.path}
                  className="Tabs__tab"
                  activeClassName="Tabs__tab--active"
                  innerRef={e => {
                    this.links[link.path] = e;
                  }}
                  onClick={() => {
                    this.props.changeModelSelectedRows([]);
                    this.props.changeAssetSelectedRows([]);
                  }}
                >
                  {link.name}
                </NavLink>
              );
            } else {
              return (
                <a
                  target="__blank"
                  href={link.path}
                  key={link.path}
                  className="Tabs__tab"
                  ref={e => {
                    this.links[link.path] = e;
                  }}
                >
                  {link.name}
                </a>
              );
            }
          })}
      </div>
    );
  }
}

export default connect(
  () => ({}),
  { changeModelSelectedRows, changeAssetSelectedRows }
)(Tabs);
