import React from 'react';
import ModelTable from './components/ModelTable';
import './ModelPage.scss';
import UploadProgress from '../Common/Files/components/UploadProgress';
import ModelPageBreadCrumb from './ModelPageBreadCrumb';

class ModelPage extends React.Component {
  render() {
    return (
      <div className="ModelPage">
        <ModelPageBreadCrumb />
        <ModelTable />
        <UploadProgress />
      </div>
    );
  }
}

export default ModelPage;
