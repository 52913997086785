import React, { useEffect, useState } from 'react'
import './CombineModelViewer.scss';
import CombineModelToolbar from './CombineModelToolbar/CombineModelToolbar';
import CombineModelTree from './CombineModelTree/CombineModelTree';
import CombineModel3d from './CombineModel3d/CombineModel3d';
import { RouteComponentProps } from 'react-router';
import api from '../api';
import intl from 'react-intl-universal';
import { lt } from '../i18n';

import { connect } from 'react-redux';
import { DialogName } from '../Common/Dialog/DialogTypes';
import { toggleDialog } from '../Common/Dialog/actions';
import { IModelTableState } from '../Model/reducers/modelTable';
import { IState } from '../rootReducer';
import { WithProjectFolders } from '../Model/withProjectFolders';
import { CombineModelItem, CombineModelStatus, combineModelStore } from './stores/combine-model-store';
import { CombineContext } from './CombineModelContext';
import { CombineModelEffect } from './effects/combine-model-effect';

type StateProps = {
  modelTable: IModelTableState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
};

type Props = StateProps & DispatchProps;

function CombineModelViewer(props: Props & RouteComponentProps & WithProjectFolders<Props>) {
  const modelIds = (props.match.params as any).modelIds.split('&');
  const currentFolderId = parseInt(modelIds.pop());
  const combineModelName = modelIds.pop();
  const [modelList, setModelList] = useState<CombineModelItem[]>([]);
  const [state, setState] = useState(combineModelStore.state);
  const [viewer, setViewer] = useState<any>(null);
  const [effect, setEffect] = useState<CombineModelEffect | null>(null);

  useEffect(() => {
    const load = async () => {
      const data = await api.getModels({ modelIds });
      const list = data.map(item => ({ id: item.id, name: item.name, visible: true, transformArray: [] }));
      setModelList(list);
      combineModelStore.update(state => {
        state.combineModelName = combineModelName;
        state.combineModels = list;
      })
    };
    load();
  }, []);

  useEffect(() => {
    if (!viewer || !effect) return () => {}
    const sub = combineModelStore.subscribe(patch => {
        setState(combineModelStore.state);
        if (patch.key === 'combine' && patch.value === CombineModelStatus.Combine) {
          const ids = combineModelStore.state.combineModels.map(i => i.id);
          const transforms = combineModelStore.state.combineModels.map(i => i.transformArray);
          Modelo.Model.merge(combineModelStore.state.combineModelName , ids, transforms as any).then((data:any)=>{
            return api.updateModel({ modelId: data, parentFolderId: currentFolderId, currentFolderId: currentFolderId });
          }).then(() => {
            loadingDialogState();
            props.history.goBack();
          }).catch(() => {
            loadingDialogState();
            errorDialogState();
          });
        }
      })
    return () => {
      sub && sub();
      effect && effect.stop();
    };
  }, [viewer, effect])

  const combineModelsActions = (actionFrom:string,callback:any) => {
    let actionFromGoBack = (actionFrom === 'GOBACKMODELS');
    const { toggleDialog } = props;
    toggleDialog(DialogName.CombineModelMessage, {
      onCancel: () => toggleDialog(DialogName.CombineModelMessage),
      data: { 
        done: () => toggleDialog(DialogName.CombineModelMessage),
        contentMessage:{
         title : intl.get(  actionFromGoBack ? lt.OUT_OF_CALIBRATION_TITLE : lt.COMBINE_MODEL_MESSAGE_DIALOG_TITLE ) ,
         content : intl.get(  actionFromGoBack ? lt.OUT_OF_CALIBRATION_CONTENT : lt.COMBINE_MODEL_MESSAGE_DIALOG_CONTENT ) ,
         buttons : actionFromGoBack ? getGobackModelsDialogBtns() : getCombineModelDialogBtns(callback)
        },
       }
    });
  }

  const loadingDialogState = () => {
    const { toggleDialog } = props;
    toggleDialog(DialogName.Loading,{
      onCancel: () => {
        toggleDialog(DialogName.Loading);
      },
      data : {
        done: () => toggleDialog(DialogName.Loading),
        dialogContent : {
          title : '',
          image : 'icon_loading.svg',
          content : intl.get(lt.COMBINE_MODELS_PLEASE_WAIT)
        }
      }
    });
  }

  const errorDialogState = () => {
    const { toggleDialog } = props;
    toggleDialog(DialogName.Error, {
      onCancel: () => {
        toggleDialog(DialogName.Error);
      },
      data:{
        dialogContent : {
          title : '',
          image : 'icon_error.jpg',
          content : intl.get(lt.COMBINE_MODELS_FAILED)
        }
      }
    })
  }

  const getGobackModelsDialogBtns = () : any =>{
    const { toggleDialog } = props;
    return [
      {
        name : intl.get(lt.OUT_OF_CALIBRATION_BTNS_CANCEL),
        state : '',
        action : () => toggleDialog(DialogName.CombineModelMessage)
      },
      {
        name : intl.get(lt.OUT_OF_CALIBRATION_BTNS_EXIT),
        state : 'highLight',
        action : () => {
          combineModelStore.update(S => {
            S.params = {
              translate : [0, 0, 0],
              scale : [0, 0, 0],
              rotate : [0, 0, 0],
            };
            S.paramsHistoryIndex = -1;
            S.paramsHistory = [];
            S.optModel = '';
          });
          toggleDialog(DialogName.CombineModelMessage);
          props.history.goBack();
        }
      }
    ]
  }

  const getCombineModelDialogBtns = (callback:any):any => {
    const { toggleDialog } = props;
    return [
      {
        name : intl.get(lt.COMBINE_MODEL_MESSAGE_DIALOG_BTNS_CANCEL),
        state : '',
        action : () => toggleDialog(DialogName.CombineModelMessage)
      },
      {
        name : intl.get(lt.COMBINE_MODEL_MESSAGE_DIALOG_BTNS_MERGE),
        state : 'highLight',
        action : () => {
          toggleDialog(DialogName.CombineModelMessage);
          loadingDialogState();
          combineModelStore.update(state => {
            state.combine = CombineModelStatus.Start;
          });
        }
      }
    ];
  }

  return (
    <CombineContext.Provider value={{state, viewer, setViewer, effect, setEffect}}>
      <div className="CombineModelViewer">
        { modelList && <CombineModelTree {...props} models={modelList}  combineModelsActions = {(actionFrom,callback) => combineModelsActions(actionFrom,callback) }/>}
        <CombineModelToolbar />
        <CombineModel3d modelIds={modelIds} />
      </div>
    </CombineContext.Provider>
  )
}


//export default CombineModelViewer

export default connect<{}, DispatchProps, {}, IState>(
  () => ({}),
  { toggleDialog }
)(CombineModelViewer);