import * as React from 'react';
import './Register.scss';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import api from '../api';
import { loginUserSuccess } from './actions/auth';
import intl from 'react-intl-universal';
import { lt } from '../i18n';
import classNames from 'classnames';
import { changeLocale } from '../i18n/action';
import config from '../config';

declare const gio: any;

type StateProps = {
  authed: boolean;
  locale: string;
};

type DispatchProps = {
  changeLocale: typeof changeLocale;
  loginUserSuccess: typeof loginUserSuccess;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps;

class Register extends React.Component<Props> {
  state = {
    error: ''
  };
  usernameRef: HTMLInputElement | null = null;
  passwordRef: HTMLInputElement | null = null;

  handleRegister = async () => {
    const username = this.usernameRef!.value;
    const password = this.passwordRef!.value;

    try {
      const resp = await api.register({ username, password });
      localStorage.setItem('token', resp.token);

      gio('setUserId', resp.username);
      gio('people.set', resp);

      Modelo.init({
        endpoint: config.MODELO_API_URI,
        appToken: resp.token
      });

      this.props.loginUserSuccess(resp);
    } catch (e) {
      this.setState({ error: intl.get(lt.LOGIN_PASSWORD_ERROR) });
      return;
    }

    const { data } = await api.getAppToken();
    const appToken = data.permanentAccessToken;
    localStorage.setItem('appToken', appToken);

    this.props.history.push('/');
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const username = this.usernameRef!.value;
    const password = this.passwordRef!.value;

    const allowSubmit = username && password;

    if (e.keyCode === 13) {
      if (allowSubmit) {
        this.handleRegister();
      }
    }
  };

  render() {
    const { changeLocale, locale } = this.props;
    const { error } = this.state;

    return (
      <div className="Register">
        <div className="Register__locale">
          <div
            className={classNames('Register__engilsh', {
              'Register__engilsh--active': locale === 'en'
            })}
            onClick={() => {
              if (locale !== 'en') {
                changeLocale('en');
                localStorage.setItem('locale', 'en');
              }
            }}
          >
            EN
          </div>
          <div className="Register__divid">|</div>
          <div
            className={classNames('Register__chinese', {
              'Register__chinese--active': locale === 'zh'
            })}
            onClick={() => {
              if (locale !== 'zh') {
                changeLocale('zh');
                localStorage.setItem('locale', 'zh');
              }
            }}
          >
            CN（中文）
          </div>
        </div>
        <div className="Register__container">
          <div className="Register__header">
            <img className="Register__header--logo" src={require('../static/svg/icon_login_MBUILD.svg')} />
          </div>
          <div className="Register__slogan">{intl.get(lt.THE_UTMOST_WEBGL_BASED_BIM_ENGINE)}</div>
          <div className="Register__form">
            <input
              ref={e => (this.usernameRef = e)}
              type="text"
              onKeyDown={this.handleKeyDown}
              placeholder={intl.get(lt.EMAIL)}
              className="Register__input"
            />
            <input
              ref={e => (this.passwordRef = e)}
              type="password"
              onKeyDown={this.handleKeyDown}
              placeholder={intl.get(lt.PASSWORD)}
              className="Register__input"
            />
            <div style={{ color: 'white' }}>{error}</div>
            <div className="Register__register" onClick={this.handleRegister}>
              {intl.get(lt.REGISTER)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps, IState>(
    (state: IState) => ({
      locale: state.locale,
      authed: state.auth.authed
    }),
    { loginUserSuccess, changeLocale }
  )(Register)
);
