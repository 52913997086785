import React from 'react';

export default function ChangeLogs() {
  return (
    <>
      <h1 id="更新日志">更新日志</h1>
      <h2 id="版本-202019315">版本 2.0(2019/3/15)</h2>
      <p>
        <a href="https://s3.cn-north-1.amazonaws.com.cn/api-release.modeloapp.com/prod/modeloapi-2.0-lts.js">
          modeloapi-2.0-lts.js
        </a>
      </p>
      <ul>
        <li>在加载模型函数中用 promise 替代回调函数。</li>
        <li>在同一网页上创建多个ModelViewer。（但是仅能同时激活一个窗口）。</li>
        <li>加权 OIT。默认情况下关闭。</li>
        <li>为.skp 文件启用线性渲染。</li>
        <li>当不支持 4xAA 帧缓冲时，回滚到 1xAA。</li>
        <li>支持comment批注</li>
        <li>支持模型对比</li>
      </ul>
      <h2 id="版本-11-20181108">版本 1.1 (2018/11/08)</h2>
      <p>
        <a href="https://s3.cn-north-1.amazonaws.com.cn/api-release.modeloapp.com/prod/modeloapi-1.1-lts.js">
          modeloapi-1.1-lts.js
        </a>
      </p>
      <ul>
        <li>支持合并模型。</li>
        <li>使用测量标记。</li>
        <li>支持批注标记。</li>
        <li>改变模型查看窗口初始状态。</li>
      </ul>
      <h2 id="版本-10-20181008">版本 1.0 (2018/10/08)</h2>
      <p>
        <a href="https://s3.cn-north-1.amazonaws.com.cn/api-release.modeloapp.com/prod/modeloapi-1.0-lts.js">
          modeloapi-1.0-lts.js
        </a>
      </p>
      <ul>
        <li>发布 1.0 版本, modeloapi-1.0-lts.js。</li>
      </ul>
    </>
  );
}
