import { createAction } from '../../Common/utils/actions';
import { TableData } from '../../Common/Table/Table';
import { ModelRow } from '../utils';

export const TOGGLE_NEW_ROW = '@Model/TOGGLE_NEW_ROW';
export const CHANGE_SELECTED_ROWS = '@Model/CHANGE_SELECTED_ROWS';

export const toggleNewRow = () => createAction(TOGGLE_NEW_ROW);
export const changeSelectedRows = (rows: TableData<ModelRow & { rowIndex: number }>) =>
  createAction(CHANGE_SELECTED_ROWS, rows);
