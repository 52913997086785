import classNames from 'classnames';
import React from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../api';
import { toggleDialog } from '../../Common/Dialog/actions';
import { Dialog, DialogName } from '../../Common/Dialog/DialogTypes';
import Table, { TableColumn, TableData, TableMenu } from '../../Common/Table/Table';
import { SortOptions, SortOrder, toggleSortOrder } from '../../Common/utils/table';
import { GetModelFolderContentsComponent, ModelStatus } from '../../generated/graphql';
import { lt } from '../../i18n';
import { IState } from '../../rootReducer';
import { changeSelectedRows, toggleNewRow } from '../actions/modelTable';
import { IModelTableState } from '../reducers/modelTable';
import { getFolders, getModels, ModelRow } from '../utils';
import withProjectFolders, { WithProjectFolders } from '../withProjectFolders';
import './ModelTable.scss';
import { MoveModelDialogDataProps } from './MoveModelDialog';
import { middleTrimWithBoxWidth } from '../utils/String';


type State = {
  renameRowIndex?: number;
  sortOptions: SortOptions;
  textBoxWidth: number;
};

type StateProps = {
  generatePDFDialog: Dialog;
  modelTable: IModelTableState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
  toggleNewRow: typeof toggleNewRow;
  changeSelectedRows: typeof changeSelectedRows;
};

type Props = WithProjectFolders<RouteComponentProps & StateProps & DispatchProps>;

class ModelTable extends React.Component<WithApolloClient<Props>, State> {
  private table: HTMLElement | null = null;

  state: State = {
    sortOptions: { order: SortOrder.DESC, by: 'createdAt' },
    textBoxWidth: 325
  };

  updateDimensions = () => {
    const columnWidth = this.table!.offsetWidth * 0.32 - 74;
    this.setState({
      textBoxWidth: columnWidth
    });
  };

  componentDidMount() {
    this.table = document.querySelector('.Table');
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getColumns() {
    const columns: TableColumn<ModelRow>[] = [
      {
        accessor: 'name',
        width: 2,
        renderer: ({ row }) => {
          const isError = row.status === ModelStatus.Error;
          const isConverting = row.status === ModelStatus.Uploaded;
          const isFolder = row.fileType === 'folder';
          const { textBoxWidth } = this.state;
          let iconName = row.fileType;
          if (isError) iconName = 'convert_error';

          return (
            <div className="ModelTable__name">
              <div
                className={classNames('ModelTable__nameIcon', {
                  [`ModelTable__nameIcon--error`]: isError,
                  [`ModelTable__nameIcon--converting`]: isConverting,
                  [`ModelTable__nameIcon--${row.fileType}`]: true
                })}
              >
                {!isConverting && !isError && !isFolder && <div className="ModelTable__nameIconText">{iconName}</div>}
              </div>
              <span className="ModelTable__nameText">{middleTrimWithBoxWidth(row.name, textBoxWidth, 14)}</span>
            </div>
          );
        },
        headerRenderer: () => {
          const { order, by } = this.state.sortOptions;
          const sameHeader = by === 'name';
          const sortOptions: SortOptions = { order: sameHeader ? toggleSortOrder(order) : SortOrder.ASC, by: 'name' };

          return (
            <div className="ModelTable__header" onClick={() => this.setState({ sortOptions })}>
              {`${intl.get(lt.NAME)}`}
              {by === 'name' && (
                <div className={classNames('ModelTable__headerArrow', [`ModelTable__headerArrow--${order}`])} />
              )}
            </div>
          );
        }
      },
      {
        header: `${intl.get(lt.MODEL)} ID`,
        accessor: 'modelId'
      },
      {
        header: `${intl.get(lt.FILE_TYPE)}`,
        accessor: 'fileType'
      },
      {
        header: `${intl.get(lt.CREATOR)}`,
        accessor: 'creator'
      },
      {
        headerRenderer: () => {
          const { order, by } = this.state.sortOptions;
          const sameHeader = by === 'createdAt';
          const sortOptions: SortOptions = {
            order: sameHeader ? toggleSortOrder(order) : SortOrder.ASC,
            by: 'createdAt'
          };

          return (
            <div className="ModelTable__header" onClick={() => this.setState({ sortOptions })}>
              {`${intl.get(lt.CREATE_AT)}`}
              {by === 'createdAt' && (
                <div className={classNames('ModelTable__headerArrow', [`ModelTable__headerArrow--${order}`])} />
              )}
            </div>
          );
        },
        accessor: 'createdAt'
      }
    ];
    return columns;
  }

  getTableMenus = () => {
    const { client, currentFolderId, generatePDFDialog } = this.props;
    const menus: TableMenu<ModelRow>[] = [
      {
        name: `${intl.get(lt.CHANGE_FOLDER)}`,
        action: data => {
          this.props.toggleDialog('MoveModel', {
            data: { row: data.rowData },
            onCancel: () => {
              this.props.toggleDialog('MoveModel');
            },
            onConfirm: async (dialog: Dialog<MoveModelDialogDataProps>) => {
              const isFolder = data.rowData.fileType === 'folder';
              const targetFolderId = dialog.data!.folderId;

              this.props.toggleDialog('MoveModel');
              data.done();

              console.log("updateModelTest:");
              if (isFolder) {
                await api.updateFolder({
                  folderType: 'model',
                  folderId: Number(data.rowData.id),
                  parentFolderId: targetFolderId,
                  currentFolderId
                });
              } else {
                await api.updateModel({
                  modelId: data.rowData.id + '',
                  parentFolderId: targetFolderId,
                  currentFolderId
                });
              }
            }
          });
        }
      },
      {
        name: `${intl.get(lt.RENAME_FOLDER)}`,
        shouldShow: data => {
          return data.rowData.fileType === 'folder';
        },
        action: data => {
          this.setState({ renameRowIndex: data.rowIndex }, data.done);
        }
      },
      {
        name: `${intl.get(lt.DOWNLOAD_SOURCE_FILE)}`,
        shouldShow: data => {
          return !['folder', 'm3d'].includes(data.rowData.fileType!);
        },
        action: async data => {
          const model = await api.getModel({ modelId: data.rowData.modelId });

          if (model.downloadUrl) {
            window.open(model.downloadUrl);
          }
        }
      },
      // {
      //   name: `${intl.get(lt.DOWNLOAD)}3D PDF`,
      //   shouldShow: data => {
      //     return data.rowData.fileType! !== 'folder';
      //   },
      //   action: async data => {
      //     let shouldRequest = true;
      //     const pdf = await api.get3DPDF({ modelId: data.rowData.id + '' });
      //     this.props.toggleDialog(DialogName.GeneratePDF, {
      //       onCancel: async () => {
      //         this.props.toggleDialog(DialogName.GeneratePDF);
      //         shouldRequest = false;
      //         await api.deleteAsset({ assetId: pdf.id, currentFolderId });
      //       }
      //     });

      //     let asset = await api.getAsset({ assetId: pdf.id });
      //     while ((asset.status === 'UPLOADED' || asset.status === 'INIT') && shouldRequest) {
      //       await delay(1000);
      //       asset = await api.getAsset({ assetId: pdf.id });
      //     }
      //     if (!shouldRequest) {
      //       return;
      //     }
      //     if (pdf.downloadUrl) {
      //       window.location.href = pdf.downloadUrl;
      //     }

      //     this.props.toggleDialog(DialogName.GeneratePDF);
      //     data.done();
      //   }
      // },
      {
        name: `${intl.get(lt.DELETE)}`,
        action: data => {
          this.props.toggleDialog('DeleteModel', {
            onCancel: () => this.props.toggleDialog('DeleteModel'),
            onConfirm: async dialog => {
              const isFolder = data.rowData.fileType === 'folder';

              this.props.toggleDialog('DeleteModel');
              data.done();

              if (isFolder) {
                await api.deleteFolder({ folderId: Number(data.rowData.id), currentFolderId });
              } else {
                await api.deleteModel({ modelId: data.rowData.id + '', currentFolderId });
              }
            },
            data
          });
        }
      }
    ];

    return menus;
  };

  handleClickTable = (data: { rowIndex: number; rowData: ModelRow }) => {
    if (this.props.modelTable.showNewRow) {
      this.props.toggleNewRow();
    }

    if (data.rowData.status === ModelStatus.Error) {
      return;
    }

    if (data.rowData.fileType !== 'folder') {
      this.props.history.push(`/viewer/${data.rowData.id}`);
      return;
    }

    const current = this.props.location.pathname;
    this.props.history.push(`${current}/${data.rowData.id}`);
    this.props.changeSelectedRows([]);
  };

  handleInputBlur = async (data: { rowIndex: number; rowData: ModelRow; inputValue: string }) => {
    const { modelTable, projectId, currentFolderId } = this.props;

    if (modelTable.showNewRow) {
      this.props.toggleNewRow();

      await api.addFolder({
        folderType: 'model',
        projectId,
        parentFolderId: currentFolderId,
        name: data.inputValue || intl.get(lt.NEW_FOLDER)
      });
    } else {
      await api.updateFolder({
        folderType: 'model',
        folderId: Number(data.rowData.id),
        name: data.inputValue,
        currentFolderId
      });

      this.setState({ renameRowIndex: undefined });
    }
    this.setState({ sortOptions: { order: SortOrder.NONE, by: 'name' } });
  };

  handleSelect = (data: { rowIndex: number; rowData: ModelRow }, tableData: TableData<ModelRow>) => {
    let { selectedRows } = this.props.modelTable;

    if (selectedRows.map(r => r.id).includes(data.rowData.id)) {
      selectedRows = selectedRows.filter(x => x.id !== data.rowData.id);
    } else {
      selectedRows = selectedRows.concat({ ...tableData[data.rowIndex], rowIndex: data.rowIndex });
    }

    this.props.changeSelectedRows(selectedRows);
  };

  render() {
    const columns = this.getColumns();
    const { modelTable, currentFolderId } = this.props;
    const { showNewRow } = modelTable;
    const { renameRowIndex, sortOptions } = this.state;

    const menus = this.getTableMenus();

    return (
      <GetModelFolderContentsComponent variables={{ folderId: currentFolderId }}>
        {({ data, loading, startPolling, stopPolling }) => {
          let tableData: ModelRow[] = [];
          if (loading) {
            tableData = [];
          } else {
            const models = getModels(data!.folder!.models!, sortOptions);
            const folders = getFolders(data!.folder!.childFolders!, sortOptions);
            tableData = [...folders, ...models];
          }

          if (tableData.some(x => x.status === ModelStatus.Uploaded)) {
            startPolling(5000);
          } else {
            stopPolling();
          }
          return (
            <div className="ModelTable">
              <Table<ModelRow>
                data={tableData}
                columns={columns}
                menus={menus}
                isSelected={row => !!modelTable.selectedRows.find(x => x.id === row.rowData.id)}
                onClick={this.handleClickTable}
                onSelect={data => this.handleSelect(data, tableData)}
                showNewRow={showNewRow}
                renameRowIndex={renameRowIndex}
                onInputBlur={this.handleInputBlur}
                showCheckbox={modelTable.selectedRows.length > 0}
              />
            </div>
          );
        }}
      </GetModelFolderContentsComponent>
    );
  }
}

export default withProjectFolders(
  connect<StateProps, DispatchProps, {}, IState>(
    (state: IState) => ({
      generatePDFDialog: state.dialogs.GeneratePDF,
      modelTable: state.model.modelTable
    }),
    { toggleDialog, toggleNewRow, changeSelectedRows }
  )(withRouter(withApollo<Props>(ModelTable)))
);
