import React from 'react';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import './GeneratePDFDialog.scss';

class GeneratePDFDialog extends React.Component<DialogProps> {
  static size = {
    width: 367,
    height: 103
  };

  static showCloseButton = false;

  render() {
    return <div className="GeneratePDFDialog">正在生成3D PDF...</div>;
  }
}

export default GeneratePDFDialog;
