import React, {useRef} from 'react';
import './MergeSettingParams.scss';
import { useState, useEffect ,useContext } from 'react';
import _ from 'lodash';
import { CombineContext } from '../CombineModelContext';

export type MergeSettingParam = {
  label: string;
  unit: string;
}

type Props = {
  params: MergeSettingParam[];
  values: number[];
  onChange: (v: number, index: number) => void;
}

function MergeSettingParams(props: Props) {
  const {params, values, onChange} = props;

  const [data, setData] = useState(values ? values : [0, 0, 0]);

  const { state } = useContext(CombineContext);

  const mergeSrttingRef = useRef<HTMLDivElement>(null); 

  useEffect(() => {
    setData(values);
  }, [values]);

  const handleParamsChange = (v: string | number, index: number) => {
    if (state.optModel === '') return;
    let dataList : any[]= [...data];
    v = v.toString();
    const firstCharacterReg = new RegExp(/^0([0-9]|-)$/);
    const firstCharacter = v.match(firstCharacterReg);
    v = firstCharacter ? firstCharacter[1] : v;
    if (v.length > 1) {
      let pointMatch = v.match(/\./g);
      if(pointMatch && pointMatch.length > 1){//输入第二个小数点
        v = v.slice(0,v.length-1);
      }else{
        const currentCharacterReg = new RegExp(/[0-9]|\./);
        const currentCharacter = v.slice(v.length-1);
        if(!currentCharacterReg.test(currentCharacter)){//当前输入字符非 0-9 和小数点
          v = v.slice(0,v.length-1);
        }
      }
    }
    const regForPoint = new RegExp(/^-?0\d+$/);
    if(regForPoint.test(v)){
      v = v.slice(1);
    }
    dataList[index] =  v;
    setData(dataList);
  }

  const handleOnKeyUp = (e:React.KeyboardEvent,index:number) => {
    if(e.keyCode === 13){
      ((mergeSrttingRef.current as HTMLDivElement).children[index].children[1].children[0] as HTMLInputElement).blur();
    }
  }

  const onBlurHander = (index : number) => {
    let dataList : any[]= [...data];
    if(isNaN(Number(data[index]))){
      dataList[index] = 0;
      setData(dataList);
      onChange(0, index);
    }else{
      onChange(Number(data[index]), index);
    }
  }

  return (
    <div className="MergeSettingParams" ref={mergeSrttingRef}>
      {
        params && params.map((param, index) => {
          return (
            <div className="MergeSettingParams__item" key={param.label}>
              <span className="MergeSettingParams__label">{param.label}</span>
              <div className="MergeSettingParams__inputGroup">
                <input className="MergeSettingParams__input" type="text"  onBlur={e => onBlurHander(index)}  onKeyUp={(e:React.KeyboardEvent) => handleOnKeyUp(e,index) } value={data[index]} onChange={e => handleParamsChange(e.target.value, index)}/>
                <span className="MergeSettingParams__unit">{param.unit}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default MergeSettingParams

