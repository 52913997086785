import React from 'react';
import './ChangeLogDialog.scss';
import ChangeLogs from './ChangeLogs';
import ChangeLogsEn from './ChangeLogsEn';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';

type StateProps = {
  locale: string;
};

type Props = StateProps;

class ChangeLogDialog extends React.Component<Props, {}> {
  static size = {
    width: 900,
    height: 680
  };

  render() {
    return <div className="ChangeLogDialog">{this.props.locale === 'zh' ? <ChangeLogs /> : <ChangeLogsEn />}</div>;
  }
}

export default connect<StateProps, {}, {}, IState>(
  (state: IState) => ({
    locale: state.locale
  }),
  {}
)(ChangeLogDialog) as any;
