import { Store } from './store';

export type CombineModelParams = {
  translate: number[];
  scale: number[];
  rotate: number[];
  fromToolBar?: boolean;
}

export type CombineModelItem = {
  id: string;
  name: string;
  visible: boolean;
  transformArray?: number[];
}

export enum CombineModelStatus {
  Default,
  Start,
  Cancel,
  Combine,
  Success,
  Error
}

export enum CombineModelType {
  Translate = 'translation',
  // Scale = 'scale',
  Rotate = 'rotation'
}

export type CombineModelState = {
  treeVisible: boolean;
  lastTransformMatrix: any;
  lastInitTranslation: any;
  optModel: string;
  combineModels: CombineModelItem[];

  params: CombineModelParams;
  settingType: CombineModelType;
  paramsHistoryIndex: number;
  paramsHistory: CombineModelParams[];
  combine: CombineModelStatus;
  combineModelName: string;
  modelVisible: [string, boolean];
};

const initialState: CombineModelState = {
  treeVisible: true,
  lastTransformMatrix: null,
  lastInitTranslation: null,
  optModel: '',
  params: {
    fromToolBar: false,
    translate: [0, 0, 0],
    scale: [0, 0, 0],
    rotate: [0, 0, 0]
  },
  paramsHistoryIndex: -1,
  paramsHistory: [{
    translate: [0, 0, 0],
    scale: [0, 0, 0],
    rotate: [0, 0, 0]
  }],
  settingType: CombineModelType.Translate,
  combineModels: [],
  combine: CombineModelStatus.Default,
  combineModelName: '',
  modelVisible: ['', true]
};

export const combineModelStore = new Store(initialState)
