import React from 'react';
import Tree, { TreeNode, TreeNodeType } from '../../Common/Tree/Tree';
import {
  GetProjectsQuery,
  GetProjectFoldersQuery,
  GetProjectFoldersVariables,
  GetAssetFolderContentsQuery,
  GetAssetFolderContentsVariables,
  AssetStatus
} from '../../generated/graphql';
import { loader } from 'graphql.macro';
import { WithApolloClient, withApollo } from 'react-apollo';
import withProjectFolders, { WithProjectFolders } from '../../Model/withProjectFolders';
import intl from 'react-intl-universal';
import { lt } from '../../i18n';

function getNodes(data: GetAssetFolderContentsQuery) {
  const assets = data.folder!.assets!.map(asset => ({
    id: asset.id + '',
    title: asset.name,
    type: TreeNodeType.Asset,
    isLeaf: true,
    status: asset.status
  }));
  const folders = data.folder!.childFolders!.map(folder => ({
    id: folder.id + '',
    title: folder.name,
    type: TreeNodeType.Folder,
    isLeaf: folder.childFolders!.length === 0,
    status: AssetStatus.Converted
  }));

  return [...folders, ...assets];
}

type Props = {
  hideAssets?: boolean;
  disableFolderSelection?: boolean;
  selectedRows: string[];
  disabledRows?: string[];
  onExpand?: (data: TreeNode) => void;
  onSelect: (data: TreeNode) => void;
};

type State = {
  treeRefs: {
    [id: string]: TreeNode;
  };
  treeData: TreeNode[];
  allowMove: boolean;
};

class AssetTree extends React.Component<WithProjectFolders<WithApolloClient<Props>>, State> {
  state: State = {
    treeRefs: {},
    treeData: [],
    allowMove: false
  };

  async componentDidMount() {
    const { client, projectFolders } = this.props;

    const getProjectsData = await client.query<GetProjectsQuery>({
      query: loader('../../graphql/GetProjects.gql'),
      variables: {}
    });
    const projectId = getProjectsData!.data.user!.projects![0].id;
    const getProjectFoldersData = await client.query<GetProjectFoldersQuery, GetProjectFoldersVariables>({
      query: loader('../../graphql/GetProjectFolders.gql'),
      variables: { projectId }
    });
    const rootAssetFolderId = getProjectFoldersData!.data.project!.assetFolder.id;
    const getAssetFolderContentsData = await client.query<GetAssetFolderContentsQuery, GetAssetFolderContentsVariables>(
      { query: loader('../../graphql/GetAssetFolderContents.gql'), variables: { folderId: rootAssetFolderId } }
    );

    const rootNode = {
      id: projectFolders.assetFolder.id + '',
      title: intl.get(lt.ASSETS),
      subNodes: [],
      type: TreeNodeType.Folder,
      isLeaf: false,
      status: AssetStatus.Converted
    };

    const treeRefs: { [id: string]: TreeNode } = {
      [rootNode.id]: rootNode
    };
    const treeData: TreeNode[] = [rootNode];
    const nodes = getNodes(getAssetFolderContentsData.data);

    nodes.forEach(node => {
      treeRefs[node.id] = node;
      treeData[0].subNodes!.push(node);
    });

    this.setState({ treeRefs, treeData });
  }

  handleExpandNode = async (node: TreeNode) => {
    const { client } = this.props;
    const { treeData, treeRefs } = this.state;

    const { data } = await client.query<GetAssetFolderContentsQuery, GetAssetFolderContentsVariables>({
      query: loader('../../graphql/GetAssetFolderContents.gql'),
      variables: { folderId: Number(node.id) }
    });

    const nodes = getNodes(data);

    if (treeRefs[node.id].subNodes) {
      treeRefs[node.id].subNodes = undefined;
    } else {
      treeRefs[node.id].subNodes = nodes;
    }

    nodes.forEach(node => {
      treeRefs[node.id] = node;
    });

    this.setState({ treeRefs, treeData });
    this.props.onExpand && this.props.onExpand(node);
  };

  handleSelectNode = (node: TreeNode) => {
    this.props.onSelect(node);
  };

  render() {
    const { treeData } = this.state;
    const { selectedRows, hideAssets, disableFolderSelection, disabledRows } = this.props;

    return (
      <Tree
        data={treeData}
        selectedRows={selectedRows}
        onExpand={this.handleExpandNode}
        onSelect={this.handleSelectNode}
        hideFiles={hideAssets}
        disableFolderSelection={disableFolderSelection}
        disabledRows={disabledRows}
      />
    );
  }
}

export default withApollo<Props>(withProjectFolders(AssetTree));
