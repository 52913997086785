import en from './en';
import zh from './zh';

export const lt = Object.keys(en).reduce(
  (p, c) => {
    p[c] = c;
    return p;
  },
  {} as any
) as typeof en;

export default {
  en,
  zh
};
