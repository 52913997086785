import React from 'react';
import * as _ from 'ramda';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Breadcrumb from '../Common/Breadcrumb/Breadcrumb';
import api from '../api';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';

type IbreadcrumbItem = {
  name: string;
  link: string;
};

type StateProps = {
  locale: string;
};

type State = {
  items: IbreadcrumbItem[];
};

type Props = {} & RouteComponentProps & StateProps;

const extractIds = (pathname: string): number[] => {
  return pathname
    .split('/')
    .map(p => parseInt(p))
    .filter(Boolean);
};

class AssetPageBreadCrumb extends React.Component<RouteComponentProps & Props, State> {
  state: State = {
    items: []
  };

  updateBreadcrumb = async (pathIds: any) => {
    const locale = this.props.locale;
    const items = [
      {
        name: locale === 'zh' ? '所有文件夹' : 'All Folders',
        link: `/assets`
      }
    ];

    const data = await Promise.all(pathIds.map((value: any) => api.getFolder({ folderId: value })));
    const newItem = data.map((v: any, index: any) => {
      const path = pathIds.slice(0, index + 1).join('/');
      return {
        name: v.data!.folder!.name,
        link: `/assets${path.length > 0 ? '/' : ''}${path}`
      };
    });
    this.setState({ items: [...items, ...newItem] });
  };

  getBreadcrumb = async () => {
    const ids = extractIds(this.props.location.pathname);
    this.updateBreadcrumb(ids);
  };

  async componentDidMount() {
    this.getBreadcrumb();
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname || this.props.locale !== prevProps.locale) {
      this.getBreadcrumb();
    }
  }

  render() {
    return <Breadcrumb items={this.state.items} />;
  }
}

export default withRouter(
  connect(
    (state: IState) => ({
      locale: state.locale
    }),
    null
  )(AssetPageBreadCrumb)
);
