import appConfig from '../config';
import pWaterfall from 'p-waterfall';
import ApolloClient from 'apollo-client';
import { loader } from 'graphql.macro';
import {
  UpdateModelMutation,
  UpdateModelVariables,
  UpdateFolderMutation,
  UpdateFolderVariables,
  AddFolderMutation,
  AddFolderVariables,
  DeleteModelMutation,
  DeleteModelVariables,
  DeleteFolderVariables,
  DeleteFolderMutation,
  GetAppTokenQuery,
  GetAppTokenVariables,
  UpdateAppTokenMutation,
  UpdateAppTokenVariables,
  GetProjectsQuery,
  GetProjectsVariables,
  UpdateAssetMutation,
  UpdateAssetVariables,
  DeleteAssetMutation,
  DeleteAssetVariables,
  GetFolderQuery,
  GetFolderVariables,
  GenerateCombinedModelMutation,
  GenerateCombinedModelVariables,
  GenerateCombinedModelQueryQuery,
  GenerateCombinedModelQueryVariables

} from '../generated/graphql';

const apiConfig: { client?: ApolloClient<any> } = {};

export function resetCache() {
  apiConfig.client!.resetStore();
}

export type LoginResponse = {
  id: number;
  username: string;
  token: string;
  expiresIn: number;
};

export type RegisterResponse = LoginResponse;

export async function loginUser(config: { username: string; password: string }): Promise<LoginResponse> {
  const { username, password } = config;

  return fetch(appConfig.REST_URI + '/login', {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  }).then((response: any) => {
    if (response.ok) {
      return response.json().then((data: any) => {
        return { ...data, password };
      });
    }
    return response.json().then((err: string) => {
      return Promise.reject(err);
    });
  });
}

export async function register(config: { username: string; password: string }): Promise<RegisterResponse> {
  const { username, password } = config;

  return fetch(appConfig.REST_URI + '/signup', {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  }).then((response: any) => {
    if (response.ok) {
      return response.json().then((data: any) => {
        return { ...data, password };
      });
    }
    return response.json().then((err: string) => {
      return Promise.reject(err);
    });
  });
}

export async function getAppToken() {
  return apiConfig.client!.query<GetAppTokenQuery, GetAppTokenVariables>({
    query: loader('../graphql/GetAppToken.gql'),
    variables: {},
    fetchPolicy: 'no-cache'
  });
}

export async function getModelPath(config: { folderId: number }) {
  const { folderId } = config;
  return apiConfig.client!.query({
    query: loader('../graphql/GetFolderParent.gql'),
    variables: { folderId }
  });
}

export async function getModelFolder(config: { modelId: string }) {
  const { modelId } = config;
  return apiConfig.client!.query({
    query: loader('../graphql/GetModelFolder.gql'),
    variables: { modelId }
  });
}

type ProgressFn = (progress: number) => void;

export function init(client: ApolloClient<any>) {
  apiConfig.client = client;
}

export async function getModel(config: { modelId: string }) {
  const { modelId } = config;
  return Modelo.Model.get(modelId);
}

export async function getModels(config: { modelIds: string[] }) {
  const { modelIds } = config;
  return Promise.all(modelIds.map(id => Modelo.Model.get(id)));
}

export async function getAsset(config: { assetId: number }) {
  const { assetId } = config;
  return Modelo.Asset.get(assetId);
}

export async function getAssets(config: { assetIds: number[] }) {
  const { assetIds } = config;
  return Promise.all(assetIds.map(id => Modelo.Asset.get(id)));
}

export type UploadModelConfig = {
  file: File;
  reduceTextures: boolean;
  folderId: number;
  onProgress?: ProgressFn;
};

export async function uploadModel(config: UploadModelConfig) {
  const { file, reduceTextures, folderId, onProgress } = config;

  const tasks = [
    () => Modelo.Model.upload(file, { reduceTextures, onProgress }),
    (model: Modelo.Model.IModel) =>
      updateModel({ modelId: model.id, parentFolderId: folderId, currentFolderId: folderId })
  ];

  return pWaterfall(tasks);
}

export type UploadAssetConfig = {
  file: File;
  folderId: number;
  onProgress?: ProgressFn;
  type?: string;
};

export async function uploadAsset(config: UploadAssetConfig) {
  const { file, folderId, onProgress, type } = config;

  const tasks = [
    () => (type ? Modelo.Asset.upload(file, { onProgress, type }) : Modelo.Asset.upload(file, onProgress)),
    (asset: Modelo.Asset.IAsset) =>
      updateAsset({ assetId: asset.id, parentFolderId: folderId, currentFolderId: folderId })
  ];

  return pWaterfall(tasks);
}

export async function updateModel(config: { modelId: string; parentFolderId: number; currentFolderId: number }) {
  const { modelId, parentFolderId, currentFolderId } = config;

  const refetchQueries = [
    {
      query: loader('../graphql/GetModelFolderContents.gql'),
      variables: { folderId: currentFolderId }
    },
    {
      query: loader('../graphql/GetModelFolderContents.gql'),
      variables: { folderId: parentFolderId }
    }
  ];

  return apiConfig.client!.mutate<UpdateModelMutation, UpdateModelVariables>({
    mutation: loader('../graphql/UpdateModel.gql'),
    variables: { modelId, folderId: parentFolderId },
    refetchQueries
  });
}

export async function updateAsset(config: { assetId: number; parentFolderId: number; currentFolderId: number }) {
  const { assetId, parentFolderId, currentFolderId } = config;

  const refetchQueries = [
    {
      query: loader('../graphql/GetAssetFolderContents.gql'),
      variables: { folderId: currentFolderId }
    },
    {
      query: loader('../graphql/GetAssetFolderContents.gql'),
      variables: { folderId: parentFolderId }
    }
  ];

  return apiConfig.client!.mutate<UpdateAssetMutation, UpdateAssetVariables>({
    mutation: loader('../graphql/UpdateAsset.gql'),
    variables: { assetId, folderId: parentFolderId },
    refetchQueries
  });
}

export async function updateFolder(config: {
  folderType: 'model' | 'asset';
  folderId: number;
  parentFolderId?: number;
  currentFolderId: number;
  name?: string;
}) {
  const { folderId, parentFolderId, currentFolderId, name, folderType } = config;

  const refetchQueries = [
    {
      query:
        folderType === 'model'
          ? loader('../graphql/GetModelFolderContents.gql')
          : loader('../graphql/GetAssetFolderContents.gql'),
      variables: { folderId: currentFolderId }
    }
  ];

  if (parentFolderId) {
    refetchQueries.push({
      query: loader('../graphql/GetModelFolderContents.gql'),
      variables: { folderId: parentFolderId }
    });
  }

  return apiConfig.client!.mutate<UpdateFolderMutation, UpdateFolderVariables>({
    mutation: loader('../graphql/UpdateFolder.gql'),
    variables: { folderId, parentFolderId, name },
    refetchQueries
  });
}

export async function addFolder(config: {
  projectId: number;
  parentFolderId: number;
  name: string;
  folderType: 'model' | 'asset';
}) {
  const { projectId, parentFolderId, name, folderType } = config;

  const refetchQueries = [
    {
      query:
        folderType === 'model'
          ? loader('../graphql/GetModelFolderContents.gql')
          : loader('../graphql/GetAssetFolderContents.gql'),
      variables: { folderId: parentFolderId }
    }
  ];

  return apiConfig.client!.mutate<AddFolderMutation, AddFolderVariables>({
    mutation: loader('../graphql/AddFolder.gql'),
    variables: { projectId, parentFolderId, name },
    refetchQueries
  });
}

export async function deleteModels(config: { modelIds: string[]; currentFolderId: number }) {
  const { modelIds, currentFolderId } = config;

  const tasks = modelIds.map(id => deleteModel({ modelId: id, currentFolderId }));

  return Promise.all(tasks);
}

export async function deleteModel(config: { modelId: string; currentFolderId: number }) {
  const { modelId, currentFolderId } = config;

  const refetchQueries = [
    {
      query: loader('../graphql/GetModelFolderContents.gql'),
      variables: { folderId: currentFolderId }
    }
  ];

  return apiConfig.client!.mutate<DeleteModelMutation, DeleteModelVariables>({
    mutation: loader('../graphql/DeleteModel.gql'),
    variables: { modelId },
    refetchQueries
  });
}

export async function deleteAssets(config: { assetIds: number[]; currentFolderId: number }) {
  const { assetIds, currentFolderId } = config;

  const tasks = assetIds.map(id => deleteAsset({ assetId: id, currentFolderId }));

  return Promise.all(tasks);
}

export async function deleteAsset(config: { assetId: number; currentFolderId: number }) {
  const { assetId, currentFolderId } = config;

  const refetchQueries = [
    { query: loader('../graphql/GetAssetFolderContents.gql'), variables: { folderId: currentFolderId } }
  ];

  return apiConfig.client!.mutate<DeleteAssetMutation, DeleteAssetVariables>({
    mutation: loader('../graphql/DeleteAsset.gql'),
    variables: { assetId },
    refetchQueries
  });
}

export async function deleteFolders(config: { folderIds: number[]; currentFolderId: number }) {
  const { folderIds, currentFolderId } = config;

  const tasks = folderIds.map(id => deleteFolder({ folderId: id, currentFolderId }));

  return Promise.all(tasks);
}

export async function deleteFolder(config: { folderId: number; currentFolderId: number }) {
  const { folderId, currentFolderId } = config;

  const refetchQueries = [
    {
      query: loader('../graphql/GetModelFolderContents.gql'),
      variables: { folderId: currentFolderId }
    }
  ];

  return apiConfig.client!.mutate<DeleteFolderMutation, DeleteFolderVariables>({
    mutation: loader('../graphql/DeleteFolder.gql'),
    variables: { folderId },
    refetchQueries
  });
}

export async function mergeModels(config: { name: string; modelIds: string[]; folderId: number }) {
  const { name, modelIds, folderId } = config;

  const tasks = [
    () => Modelo.Model.merge(name, modelIds),
    (modelId: string) => updateModel({ modelId: modelId, parentFolderId: folderId, currentFolderId: folderId })
  ];

  return pWaterfall(tasks);
}

export async function get3DPDF(config: { modelId: string }): Promise<Modelo.Asset.IAsset> {
  const { modelId } = config;

  const tasks = [() => Modelo.Model.generate3DPDF(modelId), (assetId: number) => Modelo.Asset.get(assetId)];

  return pWaterfall(tasks);
}

export async function updateAppToken() {
  return apiConfig.client!.mutate<UpdateAppTokenMutation, UpdateAppTokenVariables>({
    mutation: loader('../graphql/UpdateAppToken.gql'),
    variables: {}
  });
}

export async function uploadModelToDAM(config: { modelId: string, uploadToken: string }) {
  const { modelId, uploadToken } = config;

  return apiConfig.client!.mutate<GenerateCombinedModelMutation, GenerateCombinedModelVariables>({
    mutation: loader('../graphql/GenerateCombinedModel.gql'),
    variables: { modelId,uploadToken },
  });
}

export async function getProjects() {
  return apiConfig.client!.query<GetProjectsQuery, GetProjectsVariables>({
    query: loader('../graphql/GetProjects.gql'),
    variables: {}
  });
}

export async function getFolder(config: { folderId: number }) {
  const { folderId } = config;
  return apiConfig.client!.query<GetFolderQuery, GetFolderVariables>({
    query: loader('../graphql/GetFolder.gql'),
    variables: { folderId }
  });
}

export async function getUploadToDAMState(config: {id: number}){
  const { id } = config;
  return apiConfig.client!.query<GenerateCombinedModelQueryQuery, GenerateCombinedModelQueryVariables>({
    query: loader('../graphql/GenerateCombinedModelQuery.gql'),
    variables: { id },
    fetchPolicy: 'no-cache'
  });

}


