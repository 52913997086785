import { AssetTableActions } from '../actions';
import { TOGGLE_NEW_ROW, CHANGE_SELECTED_ROWS } from '../actions/assetTable';
import { TableData } from '../../Common/Table/Table';
import { AssetRow } from '../utils';

export interface IAssetTableState {
  showNewRow: boolean;
  selectedRows: TableData<AssetRow & { rowIndex: number }>;
}

export const initialState: IAssetTableState = {
  showNewRow: false,
  selectedRows: []
};

export default (state: IAssetTableState = initialState, action: AssetTableActions) => {
  switch (action.type) {
    case TOGGLE_NEW_ROW: {
      return { ...state, showNewRow: !state.showNewRow };
    }

    case CHANGE_SELECTED_ROWS: {
      return { ...state, selectedRows: action.payload };
    }

    default:
      return state;
  }
};
