import React from "react";
import './Content.scss';

type Props = {
  children: JSX.Element
}

class Content extends React.Component<Props> {
  render() {
    return <div className="Content">{this.props.children}</div>;
  }
}

export default Content;
