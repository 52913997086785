import React, { useContext, useEffect } from 'react'
import MergeSettingParams from './MergeSettingParams';
import classNames from 'classnames';
import './CombineModelToolbar.scss';
import { useState } from 'react';
import * as intl from 'react-intl-universal';
import { lt } from '../../i18n';
import IconUndo from '../../static/svg/undo.svg';
import IconRedo from '../../static/svg/redo.svg';
import { combineModelStore, CombineModelType, CombineModelParams } from '../stores/combine-model-store';
import { CombineContext } from '../CombineModelContext';

type Props = {
}

function CombineModelToolbar(props: Props) {
  const TabTitles = {
    [CombineModelType.Translate]: intl.get(lt.MERGE_SETTING_MOVE),
    // [CombineModelType.Scale]: intl.get(lt.MERGE_SETTING_SCALE),
    [CombineModelType.Rotate]: intl.get(lt.MERGE_SETTING_TRANSFORM)
  } as {[key: string]: string}

  const Tabs = {
    [CombineModelType.Translate]: [
      {
        label: 'X',
        unit: 'mm'
      },
      {
        label: 'Y',
        unit: 'mm'
      },
      {
        label: 'Z',
        unit: 'mm'
      }
    ],
    // [CombineModelType.Scale]: [
    //   {
    //     label: '长',
    //     unit: '倍'
    //   },
    //   {
    //     label: '宽',
    //     unit: '倍'
    //   },
    //   {
    //     label: '高',
    //     unit: '倍'
    //   }
    // ],
    [CombineModelType.Rotate]: [
      {
        label: 'X',
        unit: '°'
      },
      {
        label: 'Y',
        unit: '°'
      },
      {
        label: 'Z',
        unit: '°'
      }
    ]
  }
  
  const [values, setValues] = useState([0, 0, 0]);
  const { state } = useContext(CombineContext);

  useEffect(() => {
    switch(state.settingType) {
      case CombineModelType.Translate: {
        setValues(state.params.translate)
        break;
      }
      // case CombineModelType.Scale: {
      //   setValues(state.params.scale)
      //   break;
      // }
      case CombineModelType.Rotate: {
        setValues(state.params.rotate)
        break;
      }
    }
  }, [state.params])
  
  const handleParamsChange = (value: any, index: number) => {
    if (state.optModel === '') return;
    const {paramsHistory,paramsHistoryIndex} = combineModelStore.state;
    let currentParamsHistory : CombineModelParams[] = [];
    Object.assign(currentParamsHistory,paramsHistory);
    if((paramsHistory.length > 1) && (paramsHistoryIndex < paramsHistory.length-1)){
      currentParamsHistory.length = paramsHistoryIndex + 1;
    }
    const newValues = [...values.map(i => i ? i : 0)];
    newValues[index] = value; 
    combineModelStore.update(state => {
      const newParams = {...state.params};
      if (CombineModelType.Translate === state.settingType) {
        newParams.translate = newValues
      } else {
        newParams.rotate = newValues
      }
      newParams.fromToolBar = true;
      state.params = newParams;
      state.paramsHistory = [...currentParamsHistory, newParams];
      state.paramsHistoryIndex = state.paramsHistory.length - 1;
    })
  }

  const handleHistory = (isBack: boolean) => {
    combineModelStore.update(state => {
      if (isBack) {
        state.paramsHistoryIndex = state.paramsHistoryIndex - 1;
      } else {
        state.paramsHistoryIndex = state.paramsHistoryIndex + 1
      }
    });
  }

  const handleChangeType = (type: CombineModelType) => {
    combineModelStore.update(state => {
      state.settingType = type;
    })

    setValues( type === CombineModelType.Translate ? state.params.translate : state.params.rotate);
  }

  return (
    <div className={classNames('CombineModelToolbar', {'CombineModelToolbar__left': state.treeVisible})}>
      <div className="CombineModelToolbar__setting">
        <div className="CombineModelToolbar__tabs">
          {
            Object.keys(TabTitles).map(item => 
            <span key={item} 
              className={classNames('CombineModelToolbar__tab', {'CombineModelToolbar__tab--active': item === state.settingType})} 
              onClick={() => handleChangeType(item as CombineModelType)}>{TabTitles[item]}</span> )
          }
        </div>
        <div className="CombineModelToolbar__actions">
          <div className={classNames('CombineModelToolbar__btn', { 'CombineModelToolbar__btn--disable': state.paramsHistoryIndex <= 0 })} onClick={() => handleHistory(true)} style={{ background: `url(${IconUndo}) no-repeat center center` }}></div>
          <div className={classNames('CombineModelToolbar__btn', { 'CombineModelToolbar__btn--disable': state.paramsHistoryIndex === -1 || state.paramsHistoryIndex === state.paramsHistory.length - 1 })} onClick={() =>handleHistory(false)} style={{ background: `url(${IconRedo}) no-repeat center center` }}></div>
        </div>
      </div>
      <div className="CombineModelToolbar__content">
          <MergeSettingParams values={values} params={Tabs[state.settingType]}  onChange={(value, index) => handleParamsChange(value, index)}/>
        </div>
    </div>
  )
}

export default CombineModelToolbar

