import { DialogActions, DialogsState, initialDialogs } from './DialogTypes';
import { TOGGLE_DIALOG } from './actions';

export default (state: DialogsState = initialDialogs, action: DialogActions) => {
  switch (action.type) {
    case TOGGLE_DIALOG: {
      const { dialogName, options = {} } = action.payload!;
      const { onConfirm, onCancel, data } = options;

      return {
        ...state,
        [dialogName]: {
          ...state[dialogName],
          visible: !state[dialogName].visible,
          onCancel,
          onConfirm,
          data
        }
      };
    }

    default:
      return state;
  }
};
