import './Breadcrumb.scss';
import * as _ from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export interface IBreadcrumbItem {
  name: string;
  link: string;
}

interface BreadcrumbProps {
  items: IBreadcrumbItem[];
}

class Breadcrumb extends React.Component<BreadcrumbProps> {
  state: { items: IBreadcrumbItem[] } = {
    items: this.props.items
  };

  private breadcrumb: HTMLDivElement | null = null;

  renderItem(item: IBreadcrumbItem, isCurrent: boolean, isFirst: boolean) {
    const classString = classNames('BreadcrumbItem', {
      'BreadcrumbItem--current': isCurrent,
      'BreadcrumbItem--first': isFirst
    });

    if (isCurrent) {
      return <div className={classString}>{item.name}</div>;
    }

    return (
      <div className={classString}>
        {item.link ? (
          <Link style={{ color: '#0983ff' }} to={item.link}>
            {item.name}
          </Link>
        ) : (
          item.name
        )}
      </div>
    );
  }

  renderItems() {
    const { items } = this.props;
    return items.map((item, index) => {
      return (
        <div className="BreadcrumbItem__wrapper" key={item.name + item.link}>
          {this.renderItem(item, index === items.length - 1, index === 0)}
          {index !== items.length - 1 ? <div className="BreadcrumbItem__separator" /> : null}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="Breadcrumb" ref={e => (this.breadcrumb = e)}>
        {this.renderItems()}
      </div>
    );
  }
}

export default Breadcrumb;
