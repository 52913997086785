import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Provider as IntlProvider, localeMiddleware } from './i18n';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './rootReducer';
import { ApolloProvider } from 'react-apollo';
import { client } from './apollo';
import promise from 'redux-promise-middleware';
import api from './api';
import { changeLocale } from './i18n/action';
import 'rodal/lib/rodal.css';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, promise, localeMiddleware)));

api.init(client);

const locale = localStorage.getItem('locale');
if (locale) {
  store.dispatch(changeLocale(locale));
} else {
  store.dispatch(changeLocale('zh'));
}

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <IntlProvider>
        <App />
      </IntlProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
