import { UploadActions } from '../actions';
import { UPDATE_PROGRESS, UPLOAD_MODEL_PENDING, UPLOAD_ASSET_PENDING } from '../actions/upload';

export interface IUploadFile {
  fileId: string;
  progress: number;
  name: string;
  fileType: string;
}

export interface IUploadState {
  [fileId: string]: IUploadFile;
}

export default (state: IUploadState = {}, action: UploadActions) => {
  switch (action.type) {
    case UPDATE_PROGRESS: {
      const { fileId, progress } = action.payload;
      return { ...state, [fileId]: { ...state[fileId], progress } };
    }

    case UPLOAD_MODEL_PENDING: {
      const { file, tempFileId } = action.meta!;

      return { ...state, [tempFileId]: { fileId: tempFileId, progress: 0, name: file.name, fileType: 'model' } };
    }

    case UPLOAD_ASSET_PENDING: {
      const { file, tempFileId } = action.meta!;

      return { ...state, [tempFileId]: { fileId: tempFileId, progress: 0, name: file.name, fileType: 'asset' } };
    }

    default:
      return state;
  }
};
