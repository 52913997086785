import React from 'react';
import classNames from 'classnames';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import ReactResizeDetector from 'react-resize-detector';
import memoize from 'memoize-one';
import './Tree.scss';
import equals from 'ramda/es/equals';
import { getExtension } from '../utils/table';
import { ModelStatus, AssetStatus } from '../../generated/graphql';

const rowHeight = 38;

export enum TreeNodeType {
  Model = 'Model',
  Folder = 'Folder',
  Asset = 'Asset'
}

export type TreeNode = {
  id: string;
  title: string;
  type: TreeNodeType;
  level?: number;
  subNodes?: TreeNode[];
  isLeaf: boolean;
  status: ModelStatus | AssetStatus;
};

type Props = {
  height?: number;
  data: TreeNode[];
  selectedRows: string[];
  onExpand: (data: TreeNode) => void;
  onSelect: (data: TreeNode) => void;
  hideFiles?: boolean;
  disableFolderSelection?: boolean;
  disabledRows?: string[];
};

type RowProps = {
  disableFolderSelection?: boolean;
  disabled?: boolean;
  selectedRows: string[];
  onExpand: (data: TreeNode) => void;
  onSelect: (data: TreeNode) => void;
};

type State = {
  selectedRow: string;
};

class Row extends React.Component<ListChildComponentProps & RowProps, State> {
  handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isError: boolean) => {
    e.stopPropagation();
    const { disableFolderSelection, onSelect, index, disabled } = this.props;
    if (disabled || isError) return;

    const data = this.props.data[index] as TreeNode;

    if (!disableFolderSelection || data.type === TreeNodeType.Model) {
      onSelect(data);
    }
  };

  render() {
    const { style, index, selectedRows, onExpand, disabled } = this.props;
    const data = this.props.data[index] as TreeNode;
    const isError = data.status === (AssetStatus.Error || ModelStatus.Error);
    let currentLevel = 0;
    if (data.level) {
      currentLevel = data.level;
    }
    const isFolder = data.type === TreeNodeType.Folder;
    // arrow的width约是checkbox一半，模型要多右移5px,使图标在同一垂直线上。如果文件有二级目录，就多右移一个arrow图标占位大小为30px。
    const paddingLeft = (isFolder ? 10 : 15) + currentLevel * 40 + (data.isLeaf && isFolder ? 30 : 0);
    let iconName: any = '';
    if (!isError && data.type !== TreeNodeType.Folder) {
      iconName = getExtension(data.title) || 'm3d';
    }
    return (
      <div
        className={classNames('TreeNode', {
          'TreeNode--selected': selectedRows.includes(data.id),
          'TreeNode--disabled': disabled || isError
        })}
        style={{
          ...style,
          paddingLeft
        }}
        onClick={e => this.handleSelect(e, isError)}
      >
        {!data.isLeaf && (
          <div
            className={classNames('TreeNode__arrow', { 'TreeNode__arrow--active': data.subNodes })}
            onClick={e => {
              e.stopPropagation();
              if (!disabled) {
                onExpand(data);
              }
            }}
          />
        )}
        {!isFolder && <div className="TreeNode__checkbox" />}
        <div
          className={classNames('TreeNode__icon', {
            'TreeNode__icon--error': isError,
            'TreeNode__icon--folder': iconName === ''
          })}
          onClick={e => this.handleSelect(e, isError)}
        >
          <div className="TreeNode__iconText">{iconName}</div>
        </div>
        <div className="TreeNode__name">{data.title}</div>
      </div>
    );
  }
}

function flattenTreeData(data: TreeNode[]) {
  const result: TreeNode[] = [];
  const level = 0;

  function flatten(data: TreeNode[], level: number, result: TreeNode[]) {
    data.forEach(node => {
      if (node.subNodes) {
        result.push({ ...node, level });
        flatten(node.subNodes, level + 1, result);
      } else {
        result.push({ ...node, level });
      }
    });
  }

  flatten(data, level, result);

  return result;
}

class Tree extends React.Component<Props> {
  render() {
    const {
      height,
      selectedRows,
      onExpand,
      onSelect,
      hideFiles,
      disableFolderSelection,
      disabledRows = [],
      data
    } = this.props;

    let itemData = flattenTreeData(data);

    if (hideFiles) {
      itemData = itemData.filter(node => node.type === TreeNodeType.Folder);
    }

    return (
      <List
        height={height || 260}
        itemCount={itemData.length}
        itemSize={() => rowHeight}
        width={'100%'}
        itemData={itemData}
        className="List"
        style={{
          border: 'solid 2px rgba(130, 130, 130, 0.5)',
          overflowX: 'hidden',
          overflowY: 'scroll'
        }}
      >
        {args => (
          <Row
            {...args}
            selectedRows={selectedRows}
            onExpand={onExpand}
            onSelect={onSelect}
            disableFolderSelection={disableFolderSelection}
            disabled={disabledRows.includes((args.data as TreeNode[])[args.index].id)}
          />
        )}
      </List>
    );
  }
}

export default Tree;
