import React from 'react';
import { withApollo } from 'react-apollo';
import { IState } from '../rootReducer';
import { connect } from 'react-redux';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import { DialogName, Dialog } from '../Common/Dialog/DialogTypes';
import { TreeNode } from '../Common/Tree/Tree';
import './CombineModelDialog.scss';
import ModelTree from './components/ModelTree';
import intl from 'react-intl-universal';
import { lt } from '../i18n';
import withProjectFolders, { WithProjectFolders } from './withProjectFolders';
import api from '../api';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { toggleDialog } from '../Common/Dialog/actions';
import { CombineModelEffect } from '../CombineModelViewer/effects/combine-model-effect';

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
};

type StateProps = {  
};

type Props = DispatchProps & StateProps;

export type CombineModelDialogDataProps = { done: () => void };

type State = {
  name: string;
  selectedRows: string[];
};

class CombineModelDialog extends React.Component<
  WithProjectFolders<Props> & DialogProps<CombineModelDialogDataProps> & RouteComponentProps,
  State
> {
  static size = {
    width: 746,
    height: 596
  };

  state: State = {
    selectedRows: [],
    name: ''
  };

  handleSelectNode = (node: TreeNode) => {
    let { selectedRows } = this.state;

    if (selectedRows.includes(node.id)) {
      selectedRows = selectedRows.filter(x => x !== node.id);
    } else {
      selectedRows = selectedRows.concat(node.id);
    }

    this.setState({ selectedRows });
  };

  handleCancel = () => {
    const { dialog } = this.props;

    if (dialog.onCancel) {
      dialog.onCancel();
    }
  };

  handleCombine = () => {
    const { currentFolderId } = this.props;
    const { selectedRows, name } = this.state;

    if (!name || selectedRows.length < 2) {
      return;
    }
    this.props.dialog.data!.done();
    this.loadingDialogState();
    api.mergeModels({ folderId: currentFolderId, modelIds: selectedRows, name }).then((response:any) => {
      this.loadingDialogState();
      if(response.errors){
        this.errorDialogState();
      }
    }).catch(() => {
      this.loadingDialogState();
      this.errorDialogState();
    });

    
  };

  handleCombineAfterAdjust = () => {
    const { currentFolderId } = this.props;
    const { name,selectedRows } = this.state;
    if(selectedRows.length > 1 && name){
      this.props.history.push(`/combine/${selectedRows.join('&')}&${encodeURI(name)}&${currentFolderId}`);
      this.props.dialog.data!.done();
    }
  }

  loadingDialogState = () => {
    const { toggleDialog } = this.props;
    toggleDialog(DialogName.Loading,{
      data : {
        done: () => toggleDialog(DialogName.Loading),
        dialogContent : {
          title : '',
          image : 'icon_loading.svg',
          content : intl.get(lt.UPLOADING_MODELS_PLEASE_WAIT)
        }
      }
    });
  }

  errorDialogState = () => {
    const { toggleDialog } = this.props;
    toggleDialog(DialogName.Error, {
      onCancel: () => {
        toggleDialog(DialogName.Error);
      },
      data:{
        dialogContent : {
          title : '',
          image : 'icon_error.jpg',
          content : intl.get(lt.MODEL_UPLOAD_FAILED)
        }
      }
    })
  }

  render() {
    const { selectedRows, name } = this.state;

    return (
      <div className="CombineModelDialog">
        <div className="CombineModelDialog__title">{intl.get(lt.GENERATE_COMBINED_MODEL_IN_CURRENT_FOLDER)}</div>
        <ModelTree selectedRows={selectedRows} onSelect={this.handleSelectNode} disableFolderSelection />
        <div className="CombineModelDialog__fileSaveContainer">
          <div className="CombineModelDialog__saveAs">
            <div className="CombineModelDialog__saveAsTitle">{intl.get(lt.SAVE_COMBINED_MODEL_NAME)}:</div>
            <div className="CombineModelDialog__saveAsInput">
              <input
                value={name}
                onChange={e => this.setState({ name: e.target.value })}
                className="CombineModelDialog__saveAsInputStyle"
                placeholder={intl.get(lt.PLEASE_ENTER_FILE_NAME)}
              />
            </div>
          </div>
          {/* <div className="CombineModelDialog__savePath">
            <div className="CombineModelDialog__savePathTitle">{intl.get(lt.SAVE_PATH)}</div>
            <div className="CombineModelDialog__savePathWrapper" />
          </div> */}
        </div>
        <div className="CombineModelDialog__manipulateContainer">
          <div className="CombineModelDialog__cancel" onClick={this.handleCancel}>
            {intl.get(lt.CANCEL)}
          </div>
          <div className="CombineModelDialog__btns">
            <div
              className={classNames('CombineModelDialog__combine', {
                'CombineModelDialog__combine--disabled': !( name && selectedRows.length > 1)
              })}
              onClick={this.handleCombineAfterAdjust}
            >
              {intl.get(lt.AFTERCOMBINE)}
            </div>
            <div
              className={classNames('CombineModelDialog__combine', {
                'CombineModelDialog__combine--disabled': !(name && selectedRows.length > 1)
              })}
              onClick={this.handleCombine}
            >
              {intl.get(lt.COMBINE)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//export default withApollo(withProjectFolders(CombineModelDialog));

export default connect<StateProps, DispatchProps, {}, IState>(
  (state: IState) => ({
  }),
  { toggleDialog }
)(withProjectFolders(CombineModelDialog));