import { combineReducers } from 'redux';
import auth, { IAuthState } from './Auth/reducers';
import dialogs from './Common/Dialog/reducer';
import { DialogsState } from './Common/Dialog/DialogTypes';
import model, { IModelState } from './Model/reducers';
import asset, { IAssetState } from './Asset/reducers';
import upload, { IUploadState } from './Common/Files/reducers/upload';
import locale from './i18n/reducer';

export default combineReducers({
  locale,
  auth,
  dialogs,
  upload,
  model,
  asset
});

export interface IState {
  locale: string;
  auth: IAuthState;
  dialogs: DialogsState;
  model: IModelState;
  asset: IAssetState;
  upload: IUploadState;
}
