import * as React from 'react';
import './Auth.scss';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import api from '../api';
import { loginUserSuccess } from './actions/auth';
import intl from 'react-intl-universal';
import { lt } from '../i18n';
import classNames from 'classnames';
import { changeLocale } from '../i18n/action';
import config from '../config';
import Beian from '../Beian';

declare const gio: any;

type StateProps = {
  authed: boolean;
  locale: string;
};

type DispatchProps = {
  changeLocale: typeof changeLocale;
  loginUserSuccess: typeof loginUserSuccess;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps;

class Auth extends React.Component<Props> {
  state = {
    error: ''
  };
  usernameRef: HTMLInputElement | null = null;
  passwordRef: HTMLInputElement | null = null;

  handleLogin = async () => {
    const username = this.usernameRef!.value;
    const password = this.passwordRef!.value;

    try {
      const resp = await api.loginUser({ username, password });
      localStorage.setItem('token', resp.token);

      gio('setUserId', resp.username);
      gio('people.set', resp);

      Modelo.init({
        endpoint: config.MODELO_API_URI,
        appToken: resp.token
      });

      this.props.loginUserSuccess(resp);
    } catch (e) {
      this.setState({ error: intl.get(lt.LOGIN_PASSWORD_ERROR) });
      return;
    }

    const { data } = await api.getAppToken();
    const appToken = data.permanentAccessToken;
    localStorage.setItem('appToken', appToken);

    this.props.history.push('/');
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const username = this.usernameRef!.value;
    const password = this.passwordRef!.value;

    const allowSubmit = username && password;

    if (e.keyCode === 13) {
      if (allowSubmit) {
        this.handleLogin();
      }
    }
  };

  render() {
    const { changeLocale, locale } = this.props;
    const { error } = this.state;

    return (
      <>
        <div className="Auth">
          <div className="Auth__locale">
            <div
              className={classNames('Auth__engilsh', {
                'Auth__engilsh--active': locale === 'en'
              })}
              onClick={() => {
                if (locale !== 'en') {
                  changeLocale('en');
                  localStorage.setItem('locale', 'en');
                }
              }}
            >
              EN
          </div>
            <div className="Auth__divid">|</div>
            <div
              className={classNames('Auth__chinese', {
                'Auth__chinese--active': locale === 'zh'
              })}
              onClick={() => {
                if (locale !== 'zh') {
                  changeLocale('zh');
                  localStorage.setItem('locale', 'zh');
                }
              }}
            >
              CN（中文）
          </div>
          </div>
          <div className="Auth__container">
            <div className="Auth__header">
              <img className="Auth__header--logo" src={require('../static/svg/icon_login_MBUILD.svg')} />
            </div>
            <div className="Auth__slogan">{intl.get(lt.THE_UTMOST_WEBGL_BASED_BIM_ENGINE)}</div>
            <div className="Auth__form">
              <input
                ref={e => (this.usernameRef = e)}
                type="text"
                onKeyDown={this.handleKeyDown}
                placeholder={intl.get(lt.EMAIL)}
                className="Auth__input"
              />
              <input
                ref={e => (this.passwordRef = e)}
                type="password"
                onKeyDown={this.handleKeyDown}
                placeholder={intl.get(lt.PASSWORD)}
                className="Auth__input"
              />
              {/* <div className="Auth__forgetPassWord">忘记密码?</div> */}
              <div style={{ color: 'white' }}>{error}</div>
              <div className="Auth__login" onClick={this.handleLogin}>
                {intl.get(lt.LOGIN)}
              </div>
              {/* <div className="Auth__divLine" />
            <div className="Auth__register">
              还没有注册吗？<span>注册</span>
            </div> */}
            </div>
          </div>
          {/* <div className="Auth__help">
          <img src={require('../static/svg/icon_help.svg')} />
        </div> */}
        </div>
        <Beian className="AuthPage" />
      </>
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps, IState>(
    (state: IState) => ({
      locale: state.locale,
      authed: state.auth.authed
    }),
    { loginUserSuccess, changeLocale }
  )(Auth)
);
