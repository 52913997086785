import React, { useState, useEffect, useContext } from 'react'
import {RouteComponentProps } from "react-router-dom";
import './CombineModelTree.scss';
import classNames from 'classnames';
import intl from 'react-intl-universal';
import { lt } from '../../i18n';
import { CombineModelItem, combineModelStore } from '../stores/combine-model-store';
import { CombineContext } from '../CombineModelContext';
import _ from 'lodash';

type Pops = {
  models: CombineModelItem[];
  combineModelsActions: (actionFrom:string,callback:any) => void;
}

function CombineModelTree(props: Pops & RouteComponentProps ) {
  const {models , combineModelsActions} = props;
  const [data, setData] = useState<CombineModelItem[]>([]);
  const {state} = useContext(CombineContext);

  useEffect(() => {
    setData(_.cloneDeep(models));
  }, [models])

  const handleSelect = (id: string) => {
    combineModelStore.update(state => {
      state.optModel = id
    })
  }

  const handleSearch = (value: string) => {
    const filters = models.filter(model => model.name.includes(value))
    setData(filters);
  }

  const handleVisible = (e: any, model: any, index: number) => {
    const newArr = [...data];
    data[index].visible = !data[index].visible;
    setData(newArr);
    combineModelStore.update(state => {
      state.modelVisible = [model.id, data[index].visible]
    })
    e.stopPropagation();
  }

  const combineModels = () => {}

  const handleCloseTreePanel = () => {
    combineModelStore.update(state => {
      state.treeVisible = !state.treeVisible;
    })
  }

  return (
    <div className={classNames('CombineModelTree', {'CombineModelTree--close': !state.treeVisible })}>
      <div className="CombineModelTree__title">{intl.get(lt.COMBINE_MODEL)}</div>
      <div className="CombineModelTree__close" onClick={() => handleCloseTreePanel()}></div>
      <div className="CombineModelTree__content">
        <input className="CombineModelTree__search" type="text" onChange={e => handleSearch(e.target.value)} placeholder={intl.get(lt.SEARCH)} />
        <ul className="CombineModelTree__list">
          {
            data.map((item, index) => 
            <li key={item.id} className={classNames('CombineModelTree__item', {'CombineModelTree__item--selected': state.optModel === item.id})} onClick={() => handleSelect(item.id)}>
              <div className={classNames('CombineModelTree__visible', {'CombineModelTree__visible--none': !item.visible})} onClick={(e) => handleVisible(e, item, index)}></div>
              <span>{item.name}</span>
            </li>)
          }
        </ul>
      </div>
      <div className="CombineModelTree__btns">
        <div className="CombineModelTree__btn" onClick={() => combineModelsActions('GOBACKMODELS','')}>{intl.get(lt.EXIT_COMBINE_MODEL)}</div>
        <div className="CombineModelTree__btn CombineModelTree__btn--color" onClick={() => combineModelsActions('COMBINEMODELS',combineModels)}>{intl.get(lt.MERGE_MODEL)}</div>
      </div>
    </div>
  )
}

export default CombineModelTree

