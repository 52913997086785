import ApolloClient from 'apollo-client';
import classNames from 'classnames';
import React from 'react';
import { withApollo } from 'react-apollo';
import intl from 'react-intl-universal';
import { DialogProps } from '../../Common/Dialog/DialogTypes';
import { TreeNode, TreeNodeType } from '../../Common/Tree/Tree';
import { lt } from '../../i18n';
import AssetTree from './AssetTree';
import './MoveAssetDialog.scss';
import { AssetRow } from '../utils';

type Props = {
  client: ApolloClient<any>;
};

type QueryProps = Props;

export type MoveAssetDialogDataProps = { folderId: number; row: AssetRow };

type State = {
  selectedRows: string[];
  allowMove: boolean;
};

class MoveAssetDialog extends React.Component<QueryProps & DialogProps<MoveAssetDialogDataProps>, State> {
  static size = {
    width: 746,
    height: 514
  };

  state: State = {
    selectedRows: [],
    allowMove: false
  };

  handleExpandNode = async (node: TreeNode) => {};

  handleSelectNode = (node: TreeNode) => {
    this.setState({ selectedRows: [node.id], allowMove: node.type === TreeNodeType.Folder });
  };

  handleCancel = () => {
    const { dialog } = this.props;

    if (dialog.onCancel) {
      dialog.onCancel();
    }
  };

  handleMove = () => {
    const { dialog } = this.props;
    const { allowMove } = this.state;

    if (!allowMove) return;

    if (dialog.onConfirm) {
      dialog.data = { folderId: Number(this.state.selectedRows[0]), row: dialog.data!.row };
      dialog.onConfirm(dialog);
    }
  };

  render() {
    const { selectedRows, allowMove } = this.state;
    const { dialog } = this.props;
    const { data } = dialog;

    return (
      <div className="MoveAssetDialog">
        <div className="MoveAssetDialog__title">{intl.get(lt.MOVE_FILE)}</div>
        <div className="MoveAssetDialog__manipulateTitle">{intl.get(lt.SELECT_TARGET_FOLDER)}</div>
        <AssetTree
          selectedRows={selectedRows}
          onExpand={this.handleExpandNode}
          onSelect={this.handleSelectNode}
          disabledRows={data ? [data.row.id + ''] : []}
          hideAssets
        />
        <div className="MoveAssetDialog__manipulateContainer">
          <div className="MoveAssetDialog__cancel" onClick={this.handleCancel}>
            {intl.get(lt.CANCEL)}
          </div>
          <div
            className={classNames('MoveAssetDialog__delete', { 'MoveAssetDialog__delete--disabled': !allowMove })}
            onClick={this.handleMove}
          >
            {intl.get(lt.MOVE)}
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(MoveAssetDialog);
