import React from 'react';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import { AssetRow } from './utils';
import './PreviewAsetDialog.scss';
import api from '../api';
import { getExtension } from '../Common/utils/table';
import intl from 'react-intl-universal';
import { lt } from '../i18n';

type PreviewAssetDialogData = {
  asset: AssetRow;
};

type Props = DialogProps<PreviewAssetDialogData>;

class PreviewAssetDialog extends React.Component<Props> {
  static size = {
    width: 800,
    height: 600
  };

  state = {
    url: '',
    fileType: ''
  };

  async componentDidMount() {
    const { dialog } = this.props;
    const { data } = dialog;

    if (!data) return;
    const asset = await api.getAsset({ assetId: data.asset.id });
    this.setState({ url: asset.downloadUrl, fileType: getExtension(asset.name) });
  }

  async componentDidUpdate(prevProps: Props) {
    const { dialog } = this.props;
    const { data } = dialog;

    if (!data) return;
    if (prevProps.dialog.data === this.props.dialog.data) return;

    const asset = await api.getAsset({ assetId: data.asset.id });

    this.setState({ url: asset.downloadUrl, fileType: getExtension(asset.name) });
  }

  render() {
    const { url, fileType } = this.state;
    const imageTypes = ['png', 'jpg', 'jpeg', 'svg'];
    const showPreview = fileType ? imageTypes.includes(fileType) : true;

    return (
      <div className="PreviewAssetDialog">
        {showPreview && <img className="PreviewAssetDialog__image" src={url} />}
        {!showPreview && (
          <div className="PreviewAssetDialog__message">{intl.get(lt.PREVIEW_DOES_NOT_SUPPORT_THIS_FILE_TYPE_YET)}</div>
        )}
      </div>
    );
  }
}

export default PreviewAssetDialog;
