import React from 'react';
import intl from 'react-intl-universal';
import { lt } from '../i18n';
import withProjectFolders, { WithProjectFolders } from './withProjectFolders';
import { DialogName, Dialog } from '../Common/Dialog/DialogTypes';
import { UploadModelDialogData } from './UploadModelDialog';
import nanoid from 'nanoid';
import { toggleDialog } from '../Common/Dialog/actions';
import { IState } from '../rootReducer';
import { connect } from 'react-redux';
import { toggleNewRow, changeSelectedRows } from './actions/modelTable';
import { updateProgress, uploadModel } from '../Common/Files/actions/upload';
import { IModelTableState } from './reducers/modelTable';
import api from '../api';
import './ModelSider.scss';
import deleteIcon from '../static/svg/icon_delete.svg';
import quit from '../static/svg/icon_quit.svg';

type StateProps = {
  modelTable: IModelTableState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
  toggleNewRow: typeof toggleNewRow;
  uploadModel: typeof uploadModel;
  updateProgress: typeof updateProgress;
  changeSelectedRows: typeof changeSelectedRows;
};

type Props = StateProps & DispatchProps;

class ModelSider extends React.Component<WithProjectFolders<Props>> {
  handleNewFolder = () => {
    this.props.toggleNewRow();
  };

  handleToggleUploadModelDialog = () => {
    const { toggleDialog } = this.props;
    toggleDialog(DialogName.UploadModel, {
      onCancel: () => {
        toggleDialog(DialogName.UploadModel);
      },
      onConfirm: (dialog: Dialog<UploadModelDialogData>) => {
        const { currentFolderId } = this.props;
        const { file, reduceTextures } = dialog.data!;
        const tempId = nanoid();
        const onProgress = (percentage: number) => {
          this.props.updateProgress(tempId, percentage);
        };

        this.props.uploadModel({
          file,
          reduceTextures,
          folderId: currentFolderId,
          onProgress,
          tempFileId: tempId
        });

        toggleDialog(DialogName.UploadModel);
      }
    });
  };

  uploadModelToDAM = () => {
    const { toggleDialog } = this.props;

    toggleDialog(DialogName.UploadModelToDAM, {
      onCancel: () => { 
        toggleDialog(DialogName.UploadModelToDAM)
      },
      data: { 
        uploadSelectedModelToDAMRequest: (modelId :string,uploadToken:string) => {
        this.uploadSelectedModelToDAMRequest(modelId,uploadToken);
        } 
      }
    })
  }


  uploadSelectedModelToDAMRequest = (modelId: string, uploadToken:string) => {
    this.loadingDialogState();
    api.uploadModelToDAM({modelId: modelId, uploadToken: uploadToken}).then((response) => {
      this.getUploadToModelState(response.data.generateCombinedModel.id)
    });
  }

  getUploadToModelState = (id:number) => {
    let status : any = '';
    const interval = setInterval( () => {
      api.getUploadToDAMState({id}).then(response => {
        if(status !== "") return;
        if(response.errors || response.data.queryGenerateCombinedModel!.status == 'ERROR'){
          status = "ERROR";
          this.loadingDialogState();
          clearInterval(interval)
          this.errorDialogState();
        }else if(response.data.queryGenerateCombinedModel!.status === 'DONE'){
          status = "DONE";
          this.loadingDialogState();
          clearInterval(interval)
        } 
      }); 
    },2000);
  }

  loadingDialogState = () => {
    const { toggleDialog } = this.props;
    toggleDialog(DialogName.Loading,{
      data : {
        done: () => toggleDialog(DialogName.Loading),
        dialogContent : {
          title : '',
          image : 'icon_loading.svg',
          content : intl.get(lt.UPLOADING_MODELS_PLEASE_WAIT)
        }
      }
    });
  }

  errorDialogState = () => {
    const { toggleDialog } = this.props;
    toggleDialog(DialogName.Error, {
      onCancel: () => {
        toggleDialog(DialogName.Error);
      },
      data:{
        dialogContent : {
          title : '',
          image : 'icon_error.jpg',
          content : intl.get(lt.MODEL_UPLOAD_FAILED)
        }
      }
    })
  }



  render() {
    const { toggleDialog, modelTable } = this.props;

    const selectionMode = modelTable.selectedRows.length > 0;

    return (
      <div className="ModelSider">
        {selectionMode && (
          <ModelSelectionSider
            selectedRows={modelTable.selectedRows.map(row => row.rowIndex)}
            onDownload={async () => {
              const { modelTable } = this.props;
              const modelIds = modelTable.selectedRows.map(r => r.id);
              const models = await api.getModels({ modelIds });

              models.forEach(model => {
                if (model.downloadUrl) {
                  window.open(model.downloadUrl);
                }
              });
            }}
            onDelete={async () => {
              const { currentFolderId, modelTable } = this.props;
              let modelIds: string[] = [];
              let folderIds: number[] = [];
              modelTable.selectedRows.forEach(x => {
                if (x.fileType === 'folder') {
                  folderIds.push(Number(x.id));
                } else {
                  modelIds.push(x.id);
                }
              });
              this.props.toggleDialog(DialogName.SiderDelete, {
                onCancel: () => {
                  this.props.toggleDialog(DialogName.SiderDelete);
                },
                onConfirm: async () => {
                  await Promise.all([
                    ...modelIds.map(modelId => api.deleteModel({ modelId, currentFolderId })),
                    ...folderIds.map(folderId => api.deleteFolder({ folderId, currentFolderId }))
                  ]);
                  this.props.changeSelectedRows(
                    modelTable.selectedRows.filter(r => ![...modelIds, ...folderIds.map(String)].includes(r.id))
                  );
                  this.props.toggleDialog(DialogName.SiderDelete);
                }
              });
            }}
            onExit={() => {
              this.props.changeSelectedRows([]);
            }}
          />
        )}
        {!selectionMode && (
          <div className="Sider__manipulateContainer">
            <div className="Sider__manipulateButton" onClick={this.handleToggleUploadModelDialog}>
              <div className="Sider__manipulateButtonContent">{intl.get(lt.UPLOAD_MODEL)}</div>
            </div>
            <div
              className="Sider__manipulateButton"
              onClick={() =>
                toggleDialog(DialogName.CombineModel, {
                  onCancel: () => toggleDialog(DialogName.CombineModel),
                  data: { done: () => toggleDialog(DialogName.CombineModel) }
                })
              }
            >
              <div className="Sider__manipulateButtonContent">{intl.get(lt.COMBINE_MODELS)}</div>
            </div>

            <div
              className="Sider__manipulateButton"
              onClick={ this.uploadModelToDAM }
            >
              <div className="Sider__manipulateButtonContent">{intl.get(lt.UPLOAD_TO_DAM)}</div>
            </div>

            <div className="Sider__newFolderButton" onClick={this.handleNewFolder}>
              <div className="Sider__newFolderButtonContent">{intl.get(lt.NEW_FOLDER)}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class ModelSelectionSider extends React.Component<{
  selectedRows: number[];
  onDelete: () => void;
  onDownload: () => void;
  onExit: () => void;
}> {
  render() {
    const { selectedRows, onExit, onDownload, onDelete } = this.props;
    const btns = [
      // { icon: 'download', label: '下载', onClick: onDownload },
      { icon: deleteIcon, label: intl.get(lt.DELETE), onClick: onDelete },
      { icon: quit, label: intl.get(lt.QUIT), onClick: onExit }
    ];

    return (
      <div className="ModelSelectionSider">
        <div className="ModelSelectionSider__header">
          {intl.get(lt.ALREADY_SELECTED, { count: selectedRows.length })}
        </div>
        <div className="ModelSelectionSider__btns">
          {btns.map(btn => {
            return (
              <div key={btn.label} className="ModelSelectionSider__btn" onClick={btn.onClick}>
                <div
                  className="ModelSelectionSider__btnIcon"
                  style={{ background: `url(${btn.icon}) no-repeat center center` }}
                />
                <div className="ModelSelectionSider__btnText">{btn.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, {}, IState>(
  (state: IState) => ({
    modelTable: state.model.modelTable
  }),
  { toggleDialog, toggleNewRow, uploadModel, updateProgress, changeSelectedRows }
)(withProjectFolders(ModelSider));
