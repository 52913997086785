import { AuthActions } from '../actions';
import { LOGIN_USER_FULFILLED, LOGOUT_USER } from '../actions/auth';

export interface IAuthState {
  authed: boolean;
  username: string;
}

const initialState: IAuthState = {
  authed: false,
  username: ''
};

export default (state: IAuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case LOGIN_USER_FULFILLED:
      return {
        ...state,
        authed: true,
        username: action.payload.username
      };

    case LOGOUT_USER:
      return {
        ...state,
        authed: false,
        username: ''
      };

    default:
      return state;
  }
};
