import React from 'react';
import './UploadProgress.scss';
import { connect } from 'react-redux';
import { IState } from '../../../rootReducer';
import { IUploadState, IUploadFile } from '../reducers/upload';
import intl from 'react-intl-universal';
import { lt } from '../../../i18n';

type State = {
  percentage: number;
};

type Props = StateProps;

type StateProps = {
  upload: IUploadState;
};

class UploadProgress extends React.Component<Props, State> {
  render() {
    const { upload } = this.props;

    let models: IUploadFile[] = [];
    let assets: IUploadFile[] = [];
    Object.keys(upload).forEach(tempId => {
      const file = upload[tempId];
      if (file.progress === 1) return;

      if (file.fileType === 'asset') {
        assets.push(file);
      }

      if (file.fileType === 'model') {
        models.push(file);
      }
    });

    if (models.length === 0 && assets.length === 0) return null;

    return (
      <div className="UploadProgress">
        <div className="UploadProgress__message">
          {intl.get(lt.UPLOADING_X_MODELS_X_ASSETS, { modelsLength: models.length, assetsLength: assets.length })}
        </div>
        {[...models, ...assets].map(file => {
          const percentage = Math.round(file.progress * 100);
          return (
            <div key={file.fileId} className="UploadProgress__progressBar">
              <div className="UploadProgress__fileName">{file.name}</div>
              <div className="UploadProgress__barWrapper">
                <div className="UploadProgress__barInner" style={{ width: `${percentage}%` }} />
              </div>
              <div className="UploadProgress__percentage">{percentage}%</div>
              {/* <div className="UploadProgress__cancel">close</div> */}
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect<StateProps, {}, {}, IState>((state: IState) => ({
  upload: state.upload
}))(UploadProgress);
