import React, { Component } from 'react';
import intl from 'react-intl-universal';
// import { FileExtensionIcon } from './FileExtensionIcon';
import './ModelDropzone.scss';
import Dropzone from 'react-dropzone';
import { lt } from '../i18n';

// import MiddleTrim from '../../../../ModelViewer/components/MiddleTrim/MiddleTrim';

type Props = {
  selectedFile: File | null;
  onFileChange: (file: File) => void;
};
type State = {
  wasRejected: boolean;
};

export enum ModelFileType {
  zip = 'zip',
  skp = 'skp',
  '3dm' = '3dm',
  fbx = 'fbx',
  s3d = 's3d',
  rfa = 'rfa',
  rvt = 'rvt',
  ifc = 'ifc',
  stl = 'stl',
  m3d = 'm3d',
  max = 'max',
  dgn = 'dgn'
}

function FileIcon(props: { fileType: ModelFileType }) {
  return (
    <div className="ModelDropzone__fileIcon">
      <div className="ModelDropzone__fileIconText">{props.fileType}</div>
    </div>
  );
}

const allowedFileTypes = Object.keys(ModelFileType).map(k => (ModelFileType as any)[k as any]);

function getFileExt(name: string) {
  return name.split('.').pop();
}

class ModelDropzone extends Component<Props, State> {
  state = {
    wasRejected: false
  };

  handleDropFile = (files: File[]) => {
    this.props.onFileChange(files[0]);
    this.setState({ wasRejected: false });
  };

  handleCancelDialog = () => {
    return;
  };

  render() {
    const { selectedFile } = this.props;
    const { wasRejected } = this.state;

    return (
      <Dropzone
        onDrop={this.handleDropFile}
        onFileDialogCancel={this.handleCancelDialog}
        accept={'.zip,.rvt,.skp,.3dm,.fbx,.s3d,.rfa,.ifc,.stl,.m3d, .max, .dgn'}
        onDropRejected={e => {
          this.setState({ wasRejected: true });
        }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div className="ModelDropzone" {...getRootProps()}>
              <input {...getInputProps()} />

              {!selectedFile && (
                <div className="ModelDropzone__fileTypesWrapper">
                  {allowedFileTypes.map(fileType => (
                    <FileIcon key={fileType} fileType={fileType as any} />
                  ))}
                </div>
              )}

              {selectedFile && (
                <div className="ModelDropzone__modelFile">
                  <FileIcon fileType={getFileExt(selectedFile.name) as any} />
                  <div className="ModelDropzone__modelFileName">{selectedFile.name}</div>
                </div>
              )}

              {wasRejected && <div className="ModelDropzone__rejectText">{intl.get(lt.FILE_TYPE_NOT_SUPPORTED)}</div>}

              {!selectedFile && (
                <div className="ModelDropzone__text">
                  {intl.get(lt.CLICK_ON_THE_MODEL_TO_SELECT_THE_MODEL_FILE_HERE)}
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default ModelDropzone;
