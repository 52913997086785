import { ModelTableActions } from '../actions';
import { TOGGLE_NEW_ROW, CHANGE_SELECTED_ROWS } from '../actions/modelTable';
import { TableData } from '../../Common/Table/Table';
import { ModelRow } from '../utils';

export interface IModelTableState {
  showNewRow: boolean;
  selectedRows: TableData<ModelRow & { rowIndex: number }>;
}

export const initialState: IModelTableState = {
  showNewRow: false,
  selectedRows: []
};

export default (state: IModelTableState = initialState, action: ModelTableActions) => {
  switch (action.type) {
    case TOGGLE_NEW_ROW: {
      return { ...state, showNewRow: !state.showNewRow };
    }

    case CHANGE_SELECTED_ROWS: {
      return { ...state, selectedRows: action.payload };
    }

    default:
      return state;
  }
};
