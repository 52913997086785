import React from 'react';
import * as intl from 'react-intl-universal';
import * as _ from 'ramda';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Breadcrumb from '../Common/Breadcrumb/Breadcrumb';
import api from '../api';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import { lt } from '../i18n';

type IbreadcrumbItem = {
  name: string;
  link: string;
};

type StateProps = {
  locale: string;
};

type State = {
  items: IbreadcrumbItem[];
};
type Props = {} & RouteComponentProps & StateProps;

const extractIds = (pathname: string): number[] => {
  return pathname
    .split('/')
    .map(p => parseInt(p))
    .filter(Boolean);
};

class ModelPageBreadCrumb extends React.Component<RouteComponentProps & Props, State> {
  state: State = {
    items: []
  };

  updateBreadcrumb = async (pathIds: number[]) => {
    const locale = this.props.locale;
    const items = [
      {
        name: intl.get(lt.ALL_FOLDERS),
        link: `/models`
      }
    ];

    const data = await Promise.all(pathIds.map(value => api.getFolder({ folderId: value })));
    const newItem = data.map((v, index) => {
      const path = pathIds.slice(0, index + 1).join('/');
      return {
        name: v.data!.folder!.name,
        link: `/models${path.length > 0 ? '/' : ''}${path}`
      };
    });
    this.setState({ items: [...items, ...newItem] });
  };

  getBreadcrumb = async () => {
    const ids = extractIds(this.props.location.pathname);
    this.updateBreadcrumb(ids);
  };

  async componentDidMount() {
    this.getBreadcrumb();
  }
  async componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname || this.props.locale !== prevProps.locale) {
      this.getBreadcrumb();
    }
  }

  render() {
    return <Breadcrumb items={this.state.items} />;
  }
}

export default withRouter(
  connect(
    (state: IState) => ({
      locale: state.locale
    }),
    null
  )(ModelPageBreadCrumb)
);
