import assetTable, { IAssetTableState } from './assetTable';
import { combineReducers } from 'redux';

export interface IAssetState {
  assetTable: IAssetTableState;
}

export default combineReducers({
  assetTable
});
