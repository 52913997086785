import * as React from 'react';
import classNames from 'classnames';
import './DeleteAssetDialog.scss';
import { DialogProps } from '../../Common/Dialog/DialogTypes';
import intl from 'react-intl-universal';
import { lt } from '../../i18n';
import { AssetRow } from '../utils';

type State = {
  isSelected: boolean;
};

type DeleteDialogData = {
  rowIndex: number;
  rowData: AssetRow;
};

class DeleteAssetDialog extends React.Component<DialogProps<DeleteDialogData>, State> {
  static size = {
    width: 774,
    height: 290
  };

  state: State = {
    isSelected: false
  };

  handleReConfirm() {
    this.setState({
      isSelected: !this.state.isSelected
    });
  }

  handleCancel = () => {
    const { dialog } = this.props;

    if (dialog.onCancel) {
      dialog.onCancel();
    }
  };

  handleDelete = (isFolder: boolean) => {
    const { isSelected } = this.state;
    const { dialog } = this.props;
    if (!isSelected && isFolder) return;

    if (dialog.onConfirm) {
      dialog.onConfirm(dialog);
    }
  };

  render() {
    const checkBox = classNames('DeleteAssetDialog__checkBox', {
      'DeleteAssetDialog__checkBox--selected': this.state.isSelected
    });

    if (!this.props.dialog.data) return null;

    const { rowData } = this.props.dialog.data!;
    const isFolder = rowData.fileType === 'folder';
    const title = `${intl.get(lt.DELETE)} ${isFolder ? intl.get(lt.FOLDER) : intl.get(lt.ASSETS_LOWER_CASE)}`;
    const text = `${intl.get(lt.ARE_YOU_SURE_TO_DELETE)} ${rowData.name} ？`;

    return (
      <div className="DeleteAssetDialog">
        <div className="DeleteAssetDialog__title">{title}</div>
        <div className="DeleteAssetDialog__confirm">{text}</div>
        {isFolder && (
          <div className="DeleteAssetDialog__warning">
            {intl.get(lt.CAUTION)}：{intl.get(lt.CANNOT_BE_UNDONE)} {intl.get(lt.CHECK_BOX_TO_CONFIRM)}
          </div>
        )}
        {isFolder && (
          <div className="DeleteAssetDialog__reconfirm">
            <div className={checkBox} onClick={() => this.handleReConfirm()} />
            <div className="DeleteAssetDialog__reconfirmTip">{intl.get(lt.ALL_ASSET_IN_FOLDER_WILL_BE_DELETED)}</div>
          </div>
        )}
        <div className="DeleteAssetDialog__manipulateContainer">
          <div className="DeleteAssetDialog__cancel" onClick={this.handleCancel}>
            {intl.get(lt.CANCEL)}
          </div>
          <div
            className={classNames('DeleteAssetDialog__delete', {
              'DeleteAssetDialog__delete--disabled': !this.state.isSelected && isFolder
            })}
            onClick={() => this.handleDelete(isFolder)}
          >
            {intl.get(lt.DELETE)}
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteAssetDialog;
