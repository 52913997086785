import React from 'react';
import Rodal from 'rodal';
import { DialogsState, Dialog, dialogComponents, DialogType, CustomDialog } from './DialogTypes';
import { connect } from 'react-redux';
import { IState } from '../../rootReducer';
import { toggleDialog } from './actions';

type StateProps = {
  authed: boolean;
  dialogs: DialogsState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
};

type OwnProps = {};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  dialogs: Dialog[];
  shouldRender: boolean;
};

class Dialogs extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { dialogs } = props;
    const currentDialogs = Object.keys(dialogs)
      // .filter(name => dialogs[name].visible)
      .map(name => {
        let dialog = dialogs[name];
        if (dialog.type === DialogType.Custom) {
          dialog = { ...dialog, component: dialogComponents[name] } as any;
        }
        return dialog;
      });

    return {
      dialogs: currentDialogs
    };
  }

  state: State = {
    dialogs: [],
    shouldRender: true,
  };

  componentDidUpdate(prevProps: Props) {
    /***
     * 
    *****/
    let timer: NodeJS.Timeout;
    Object.keys(this.props.dialogs).forEach(k => {
      if (prevProps.dialogs[k].visible && !this.props.dialogs[k].visible && this.state.shouldRender) {
        timer = setTimeout(() => {
          this.setState({ shouldRender: false });
        }, 300);
      }
      if (!prevProps.dialogs[k].visible && this.props.dialogs[k].visible && this.state.shouldRender) {
          clearTimeout(timer);
          this.setState({ shouldRender: true });
      }
    });
  }
  
  render() {
    const { toggleDialog, authed } = this.props;
    const { dialogs, shouldRender } = this.state;
    if (!authed) return null;

    return (
      <>
        {dialogs.map(dialog => {
          const { onCancel } = dialog;
          const Comp = (dialog as CustomDialog).component;

          return (
            <Rodal
              width={dialog.width || 580}
              height={dialog.height || 290}
              showCloseButton={dialog.showCloseButton}
              closeMaskOnClick={false}
              closeOnEsc={true}
              key={dialog.name}
              animation="slideDown"
              visible={dialog.visible}
              onClose={onCancel || (() => toggleDialog(dialog.name))}
              customStyles={{
                padding: 0
              }}
            >
              { dialog.visible && <Comp dialog={dialog} {...dialog.props} />}
            </Rodal>
          );
        })}
      </>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps, IState>(
  (state: IState) => ({
    authed: state.auth.authed,
    dialogs: state.dialogs
  }),
  { toggleDialog }
)(Dialogs);
