import * as React from 'react';
import classNames from 'classnames';
import './SiderDeleteFolderDialog.scss';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import intl from 'react-intl-universal';
import { lt } from '../i18n';

type DeleteDialogData = {};

class SiderDeleteFolderDialog extends React.Component<DialogProps<DeleteDialogData>> {
  static size = {
    width: 774,
    height: 222
  };

  handleCancel = () => {
    const { dialog } = this.props;

    if (dialog.onCancel) {
      dialog.onCancel();
    }
  };

  handleDelete = () => {
    const { dialog } = this.props;

    if (dialog.onConfirm) {
      dialog.onConfirm(dialog);
    }
  };

  render() {
    return (
      <div className="SiderDeleteFolderDialog">
        <div className="SiderDeleteFolderDialog__title">{intl.get(lt.DELETE)}</div>
        <div className="SiderDeleteFolderDialog__confirm">{intl.get(lt.ARE_YOU_SURE_TO_DELETE)}?</div>

        <div className="SiderDeleteFolderDialog__manipulateContainer">
          <div className="SiderDeleteFolderDialog__cancel" onClick={this.handleCancel}>
            {intl.get(lt.CANCEL)}
          </div>
          <div className={classNames('SiderDeleteFolderDialog__delete', {})} onClick={this.handleDelete}>
            {intl.get(lt.DELETE)}
          </div>
        </div>
      </div>
    );
  }
}

export default SiderDeleteFolderDialog;
