import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './PanoViewer.scss';

declare const Modelo: any;

class PanoViewer extends React.Component<RouteComponentProps<{ panoId: string }>> {
  state = {
    error: '',
    folderIds: []
  };

  app: any;

  componentWillUnmount() {
    this.app && this.app.destroy();
  }

  componentDidMount() {
    const { panoId } = this.props.match.params;

    const assetId = parseInt(panoId);

    if (!assetId) {
      this.setState({ error: 'Not valid asset' });
      return;
    }

    this.app = new (Modelo as any).UI.PanoViewer({
      locale: 'zh',
      containerId: 'panoContainer',
      assetId,
      onClose: () => {
        this.props.history.goBack();
      }
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <div className="">{error}</div>;
    }

    return <div className="PanoViewer" id="panoContainer" />;
  }
}

export default withRouter(PanoViewer);
