import { Store } from "../stores/store";

export type EntryObject<T> = {
  [K in keyof T]: { key: K; value: T[K]; path: (string | number)[] };
}[keyof T];

type EffectConfig = {
  showLog?: boolean | undefined;
};

export abstract class Effect<T> {
  private _store: Store<T>;
  private _unSubscribe: (() => void) | null | undefined;
  private _noop = () => {};
  private _showLog: boolean | undefined = false;

  constructor(store: Store<T>) {
    this._store = store;
  }

  abstract handleEffect(patch: EntryObject<T>): void;

  run(config: EffectConfig = {}) {
    const { showLog } = config;
    this._showLog = showLog;

    if (this._unSubscribe) {
      return;
    } else {
      this._unSubscribe = this._store.subscribe(patch => {
        if (this._showLog) {
          const [tag, ...message] = this.getLogMessage(patch);
          // log(tag, ...message);
          console.log(`%c ${tag} `, 'background: #ededed; color: #ff5555', ...message);
        }

        this.handleEffect(patch);
      });
    }
  }

  stop() {
    this._unSubscribe && this._unSubscribe();
  }

  getLogger() {
    return this._showLog ? console : { info: this._noop };
  }

  enableLog() {
    this._showLog = true;
  }

  disableLog() {
    this._showLog = false;
  }

  getLogMessage(patch: EntryObject<T>): any[] {
    return ['EFFECT', patch];
  }
}
