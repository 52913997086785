import { format } from 'date-fns';
import memoize from 'memoize-one';
import { getExtension, SortOptions, groupByCharacterType, SortOrder } from '../../Common/utils/table';
import { AssetStatus, GetAssetFolderContentsAssets, GetAssetFolderContentsChildFolders, AssetType } from '../../generated/graphql';
import { unnest } from 'ramda';

export type AssetRow = {
  id: number;
  name: string;
  assetId: string;
  fileType: string | null;
  creator: string;
  createdAt: string;
  status: AssetStatus;
  type: AssetType | null;
};

export const getAssets = memoize((data: GetAssetFolderContentsAssets[], sortOptions?: SortOptions) => {
  const assets = data.map(asset => {
    return {
      id: asset.id,
      name: asset.name,
      assetId: asset.id + '',
      fileType: getExtension(asset.name) || 'unknown',
      type: asset.type,
      createdAt: format(new Date(asset.createdAt), 'YYYY-MM-DD'),
      creator: asset.createdBy.username,
      status: asset.status
    };
  });

  if (!sortOptions) {
    return assets;
  }

  return sorted(sortOptions, assets);
});

export const getFolders = memoize((data: GetAssetFolderContentsChildFolders[], sortOptions?: SortOptions) => {
  const folders = data.map(folder => {
    return {
      id: folder.id,
      name: folder.name,
      assetId: '--',
      fileType: 'folder',
      createdAt: format(new Date(folder.createdAt), 'YYYY-MM-DD'),
      creator: folder.createdBy.username,
      status: AssetStatus.Converted,
      type: null
    };
  });

  if (!sortOptions) {
    return folders;
  }

  return sorted(sortOptions, folders);
});

function sorted(options: SortOptions, data: AssetRow[]) {
  const { order, by } = options;
  const [zh, upper, lower] = groupByCharacterType(data, by) as [AssetRow[], AssetRow[], AssetRow[]];

  if (order === SortOrder.NONE) {
    return data;
  }

  if (by === 'name' || by === 'creator') {
    const normalSort = (a: AssetRow, b: AssetRow) => {
      if (order === SortOrder.ASC) {
        if (a[by] < b[by]) {
          return -1;
        }
        if (a[by] > b[by]) {
          return 1;
        }
        return 0;
      } else {
        if (a[by] < b[by]) {
          return 1;
        }
        if (a[by] > b[by]) {
          return -1;
        }
        return 0;
      }
    };
    return unnest([
      zh.sort((a, b) => {
        if (order === SortOrder.ASC) {
          return (a[by] as string).localeCompare(b[by], 'zh-Hans-CN', { sensitivity: 'accent' });
        } else {
          return (b[by] as string).localeCompare(a[by], 'zh-Hans-CN', { sensitivity: 'accent' });
        }
      }),
      upper.sort(normalSort),
      lower.sort(normalSort)
    ]);
  }

  if (by === 'createdAt') {
    return data.sort((a, b) =>
      order === SortOrder.ASC
        ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }

  return data;
}

export const previewTypes = ['png', 'jpg', 'jpeg', 'svg'];

export function getPreviewAssets(assets: GetAssetFolderContentsAssets[]) {
  return assets.filter(asset => previewTypes.includes(getExtension(asset.name) as string));
}
