export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P, E = any> extends Action<T> {
  payload: P;
  meta?: E;
}

export interface AsyncAction<T extends string, P> extends Action<T> {
  payload: Promise<P>;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload?: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P, E>(type: T, payload?: P, meta?: E): ActionWithPayload<T, P, E>;
export function createAction<T extends string, P, E = any>(type: T, payload?: P, meta?: E) {
  if (payload === undefined) {
    if (meta === undefined) {
      return { type };
    } else {
      return { type, payload };
    }
  } else {
    return { type, payload, meta };
  }
}

export function createAsyncAction<T extends string, P>(type: T, payload: Promise<P>): AsyncAction<T, P> {
  return { type, payload };
}

type ActionCreator<T extends string = string> = (...args: any[]) => Action<T> | ActionWithPayload<T, any>;
type AsyncActionCreator<T extends string = string> = (...args: any[]) => AsyncAction<T, any>;

export type SyncActionsUnion<A extends any> = ReturnType<
// @ts-ignore
  A[{ [K in keyof A]: A[K] extends ActionCreator ? K : never }[keyof A]]
>;

export type ActionsUnion<A> = SyncActionsUnion<A>;

// export type AsyncActionsUnion<A extends any> = ReturnType<
//   A[{ [K in keyof A]: A[K] extends AsyncActionCreator ? K : never }[keyof A]]
// >;
//
// type RawActionsUnion<A extends any> = SyncActionsUnion<A> | AsyncActionsUnion<A>;
//
// type MappedActionsUnion<U> = U extends ActionWithPayload<infer T, infer P>
//   ? P extends Promise<infer PD>
//     ? ActionWithPayload<T, PD>
//     : ActionWithPayload<T, P>
//   : never;
//
// export type ActionsUnion<A> = MappedActionsUnion<RawActionsUnion<A>>;
