import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { toggleDialog } from '../Common/Dialog/actions';
// import { toggleNewRow, changeSelectedRows } from './actions/modelTable';
import { updateProgress, uploadAsset } from '../Common/Files/actions/upload';
import { lt } from '../i18n';
import withProjectFolders, { WithProjectFolders } from '../Model/withProjectFolders';
import { IState } from '../rootReducer';
import { changeSelectedRows, toggleNewRow } from './actions/assetTable';
import './AssetSider.scss';
import { IAssetTableState } from './reducers/assetTable';
import { AssetType } from '../generated/graphql';
import nanoid from 'nanoid';
import IconDelete from '../static/svg/icon_delete.svg';
import IconExit from '../static/svg/icon_quit.svg';
import api from '../api';
import { DialogName } from '../Common/Dialog/DialogTypes';

type StateProps = {
  assetTable: IAssetTableState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
  toggleNewRow: typeof toggleNewRow;
  uploadAsset: typeof uploadAsset;
  updateProgress: typeof updateProgress;
  changeSelectedRows: typeof changeSelectedRows;
};

type Props = StateProps & DispatchProps;

class AssetSider extends React.Component<WithProjectFolders<Props>> {
  fileUpload: HTMLInputElement | null = null;

  onFileInput = async (event: any) => {
    const { currentFolderId } = this.props;
    const file = event.target.files[0];
    if (!file) return;

    const tempFileId = nanoid();
    const onProgress = (percentage: number) => {
      this.props.updateProgress(tempFileId, percentage);
    };

    if (this.isPanorama) {
      // if uploaded asset is a panorama image
      // have to set asset type to '360' to let converter take over
      this.props.uploadAsset({ file, folderId: currentFolderId, tempFileId, onProgress, type: 'image360' });
    } else {
      this.props.uploadAsset({ file, folderId: currentFolderId, tempFileId, onProgress });
    }

    // reset file input or it cannot accept file again
    event.target.value = null;
  };

  handleNewFolder = () => {
    this.props.toggleNewRow();
  };

  isPanorama: boolean = false;

  render() {
    const { assetTable } = this.props;

    const selectionMode = assetTable.selectedRows.length > 0;

    return (
      <div className="AssetSider">
        {selectionMode && (
          <AssetSelectionSider
            selectedRows={assetTable.selectedRows.map(row => row.rowIndex)}
            onDelete={async () => {
              const { currentFolderId, assetTable } = this.props;
              let assetIds: number[] = [];
              let folderIds: number[] = [];
              assetTable.selectedRows.forEach(x => {
                if (x.fileType === 'folder') {
                  folderIds.push(x.id);
                } else {
                  assetIds.push(x.id);
                }
              });
              this.props.toggleDialog(DialogName.SiderDelete, {
                onCancel: () => {
                  this.props.toggleDialog(DialogName.SiderDelete);
                },
                onConfirm: async () => {
                  await Promise.all([
                    ...assetIds.map(assetId => api.deleteAsset({ assetId, currentFolderId })),
                    ...folderIds.map(folderId => api.deleteFolder({ folderId, currentFolderId }))
                  ]);
                  this.props.changeSelectedRows(
                    assetTable.selectedRows.filter(r => ![...assetIds, ...folderIds].includes(r.id))
                  );
                  this.props.toggleDialog(DialogName.SiderDelete);
                }
              });
            }}
            onExit={() => {
              this.props.changeSelectedRows([]);
            }}
          />
        )}

        {!selectionMode && (
          <div className="Sider__manipulateContainer">
            <div
              className="Sider__manipulateButton"
              onClick={() => {
                this.isPanorama = false;
                this.fileUpload && this.fileUpload.click();
              }}
            >
              <div className="Sider__manipulateButtonContent">{intl.get(lt.UPLOAD_ASSET)}</div>
            </div>
            <div
              className="Sider__manipulateButton"
              onClick={() => {
                this.isPanorama = true;
                this.fileUpload && this.fileUpload.click();
              }}
            >
              <div className="Sider__manipulateButtonContent">{intl.get(lt.UPLOAD_PANORAMA)}</div>
            </div>
            <div className="Sider__newFolderButton" onClick={this.handleNewFolder}>
              <div className="Sider__newFolderButtonContent">{intl.get(lt.NEW_FOLDER)}</div>
            </div>
          </div>
        )}

        <input
          id="upload"
          ref={fileUpload => {
            this.fileUpload = fileUpload;
          }}
          style={{ display: 'none' }}
          type="file"
          // accept="image/*"
          onChange={this.onFileInput}
        />
      </div>
    );
  }
}

class AssetSelectionSider extends React.Component<{
  selectedRows: number[];
  onDelete: () => void;
  onExit: () => void;
}> {
  render() {
    const { selectedRows, onExit, onDelete } = this.props;
    const btns = [
      // { icon: 'download', label: '下载', onClick: onDownload },
      { icon: IconDelete, label: intl.get(lt.DELETE), onClick: onDelete },
      { icon: IconExit, label: intl.get(lt.QUIT), onClick: onExit }
    ];

    return (
      <div className="AssetSelectionSider">
        <div className="AssetSelectionSider__header">
          {intl.get(lt.ALREADY_SELECTED, { count: selectedRows.length })}
        </div>
        <div className="AssetSelectionSider__btns">
          {btns.map(btn => {
            return (
              <div key={btn.label} className="AssetSelectionSider__btn" onClick={btn.onClick}>
                <div
                  className="AssetSelectionSider__btnIcon"
                  style={{ background: `url(${btn.icon}) no-repeat center center` }}
                />
                <div className="AssetSelectionSider__btnText">{btn.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, {}, IState>(
  (state: IState) => ({
    assetTable: state.asset.assetTable
  }),
  { toggleDialog, toggleNewRow, uploadAsset, updateProgress, changeSelectedRows }
)(withProjectFolders(AssetSider));
