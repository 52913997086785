import React from 'react';
import AssetTable from './components/AssetTable';
import './AssetPage.scss';
import UploadProgress from '../Common/Files/components/UploadProgress';
import AssetPageBreadCrumb from './AssetPageBreadCrumb';

class ModelPage extends React.Component {
  render() {
    return (
      <div className="AssetPage">
        <AssetPageBreadCrumb />
        <AssetTable />
        <UploadProgress />
      </div>
    );
  }
}

export default ModelPage;
