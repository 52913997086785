import React from 'react';
import classname from 'classnames';
import Avatar from '../static/svg/icon_userAvatar.svg';
import AppToken from '../static/svg/icon_appToken.svg';
import DisplayToken from '../static/img/icon_displayToken.png';
import HidePassToken from '../static/svg/icon_hideToken.svg';
import ResetToken from '../static/svg/icon_resetToken.svg';
import { GetAppTokenComponent } from '../generated/graphql';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import { toggleDialog } from '../Common/Dialog/actions';
import './UserProfile.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { logoutUser } from '../Auth/actions/auth';
import { DialogName } from '../Common/Dialog/DialogTypes';
import { IAuthState } from '../Auth/reducers';
import intl from 'react-intl-universal';
import { lt } from '../i18n';
import api from '../api';

declare const gio: any;

type StateProps = {
  auth: IAuthState;
};

type DispatchProps = {
  toggleDialog: typeof toggleDialog;
  logoutUser: typeof logoutUser;
};

type Props = DispatchProps & StateProps;

type State = {
  isUserMenuVisible: boolean;
  isAppTokenVisible: boolean;
};

class UserProfile extends React.Component<RouteComponentProps & Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isUserMenuVisible: false,
      isAppTokenVisible: false
    };
  }

  toogleResetAppTokenVisible() {
    this.props.toggleDialog(DialogName.RestAppToken, {
      onConfirm: () => {
        this.props.toggleDialog(DialogName.RestAppToken);
        this.forceUpdate();
      }
    });
  }

  toogleAppTokenVisible() {
    this.setState({
      isAppTokenVisible: !this.state.isAppTokenVisible
    });
  }

  toggleUserVisible() {
    this.setState({
      isUserMenuVisible: !this.state.isUserMenuVisible
    });
    if (!this.state.isUserMenuVisible) {
      this.setState({
        isAppTokenVisible: false
      });
    }
  }

  copyAppToken(token: string) {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', token);
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
    }

    document.body.removeChild(input);
  }

  handleLogout = () => {
    this.props.logoutUser();
    api.resetCache();
    localStorage.removeItem('token');
    localStorage.removeItem('appToken');

    gio('clearUserId');

    this.props.history.push('/login');
  };

  render() {
    const userMenuVisible = classname('UserProfile__userMenu', {
      'UserProfile__userMenu--visible': this.state.isUserMenuVisible
    });
    const appTokenVisible = classname('UserProfile__appTokenContainer', {
      'UserProfile__appTokenContainer--visible': this.state.isAppTokenVisible
    });

    return (
      <GetAppTokenComponent variables={{}}>
        {({ loading, data }) => {
          if (loading) return null;

          return (
            <div className="UserProfile__container">
              <div className={userMenuVisible}>
                <div className="UserProfile__userAppToken">
                  <img className="UserProfile__userAppToken--tokenIcon" src={AppToken} />
                  App Token
                  <div
                    onClick={() => {
                      this.toogleResetAppTokenVisible();
                    }}
                  >
                    <img className="UserProfile__userAppToken--resetToken" src={ResetToken} />
                  </div>
                  <div onClick={() => this.toogleAppTokenVisible()}>
                    {this.state.isAppTokenVisible ? (
                      <img className="UserProfile__userAppToken--displayToken" src={HidePassToken} />
                    ) : (
                      <img className="UserProfile__userAppToken--displayToken" src={DisplayToken} />
                    )}
                  </div>
                </div>
                <div className={appTokenVisible}>
                  <div className="UserProfile__appToken">{localStorage.getItem('appToken')}</div>
                  <div className="UserProfile__copyToken" onClick={() => this.copyAppToken(data!.permanentAccessToken)}>
                    {intl.get(lt.COPY_TOKEN)}
                  </div>
                </div>

                <div className="UserProfile__userSignOut" onClick={this.handleLogout}>
                  {intl.get(lt.LOGOUT)}
                </div>
              </div>
              <div className="UserProfile__UserProfileGlobal">
                <div className="UserProfile__userUserProfile" onClick={() => this.toggleUserVisible()}>
                  <img src={Avatar} />
                </div>
              </div>
            </div>
          );
        }}
      </GetAppTokenComponent>
    );
  }
}

export default connect<StateProps, DispatchProps, {}, IState>(
  (state: IState) => ({ auth: state.auth }),
  { toggleDialog, logoutUser }
)(withRouter(UserProfile));
