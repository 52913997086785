import React from "react";
import { CombineModelEffect } from "./effects/combine-model-effect";
import { CombineModelState, combineModelStore } from "./stores/combine-model-store";

export type CombineModelContext = {
  readonly viewer: any;
  state: CombineModelState;
  setViewer: (viewer: any) => void;
  effect: CombineModelEffect | null;
  setEffect: (effect: CombineModelEffect | null) => void;
}

export const initialContext: CombineModelContext = {
  viewer: null,
  setViewer: () => {},
  state: combineModelStore.state,
  effect: null,
  setEffect: () => {}
};

export const CombineContext = React.createContext(initialContext);