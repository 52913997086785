import { Dialog } from './DialogTypes';
import { createAction } from '../utils/actions';

type ToggleDialogOptions<T = any> = {
  data?: T;
  onConfirm?: (dialog: Dialog) => void;
  onCancel?: () => void;
};
export const TOGGLE_DIALOG = '@Dialog/TOGGLE_DIALOG';

export const toggleDialog = <T = any>(dialogName: string, options?: ToggleDialogOptions<T>) =>
  createAction(TOGGLE_DIALOG, { dialogName, options });
