export type Maybe<T> = T | null;

export enum ComStatus {
  Init = 'INIT',
  Error = 'ERROR',
  Done = 'DONE'
}

export enum AssetType {
  Wbs = 'wbs',
  Img = 'img',
  File = 'file',
  Dwg = 'dwg',
  ModelConvertedPdf = 'modelConvertedPdf',
  Image360 = 'image360'
}

export enum TaskPriority {
  Urgent = 'urgent',
  Critical = 'critical',
  Normal = 'normal'
}

export enum TaskCommentType {
  Img = 'img',
  Txt = 'txt'
}

export enum IssuePriority {
  Urgent = 'urgent',
  Critical = 'critical',
  Normal = 'normal'
}

export enum IssueStatus {
  Open = 'open',
  InProgress = 'inProgress',
  Resolved = 'resolved',
  Bot = 'bot'
}

export enum ModelType {
  Normal = 'normal',
  Versioned = 'versioned',
  Partial = 'partial'
}

export enum ModelStatus {
  Error = 'ERROR',
  Init = 'INIT',
  Uploaded = 'UPLOADED',
  Converted = 'CONVERTED'
}

export enum SelectionType {
  ElementId = 'elementId',
  Type = 'type',
  Family = 'family',
  Category = 'category',
  Level = 'level'
}

export enum ModelShaderSetting {
  Architect = 'Architect',
  Product = 'Product'
}

export enum AssetStatus {
  Error = 'ERROR',
  Init = 'INIT',
  Uploaded = 'UPLOADED',
  Converted = 'CONVERTED'
}

export enum WechatMessageType {
  Txt = 'txt',
  Img = 'img',
  File = 'file'
}

/** Id custom scalar type */
export type Id = string;

/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type JsonObject = any;

// ====================================================
// Documents
// ====================================================

export type AddFolderVariables = {
  projectId: number;
  name: string;
  parentFolderId: number;
};

export type AddFolderMutation = {
  __typename?: 'Mutation';

  addFolder: AddFolderAddFolder;
};

export type AddFolderAddFolder = {
  __typename?: 'Folder';

  id: number;

  name: string;
};

export type DeleteAssetVariables = {
  assetId: number;
};

export type DeleteAssetMutation = {
  __typename?: 'Mutation';

  deleteAsset: string;
};

export type DeleteFolderVariables = {
  folderId: number;
};

export type DeleteFolderMutation = {
  __typename?: 'Mutation';

  deleteFolder: string;
};

export type DeleteModelVariables = {
  modelId: string;
};

export type DeleteModelMutation = {
  __typename?: 'Mutation';

  deleteModel: string;
};

export type GenerateCombinedModelVariables = {
  modelId: string;
  uploadToken: string;
};

export type GenerateCombinedModelMutation = {
  __typename?: 'Mutation';

  generateCombinedModel: Maybe<GenerateCombinedModelGenerateCombinedModel>;
};

export type GenerateCombinedModelGenerateCombinedModel = {
  __typename?: 'GenerateCombinedModel';

  id: Maybe<number>;

  modelId: Maybe<string>;

  userId: Maybe<number>;

  uploadToken: Maybe<string>;

  status: Maybe<ComStatus>;

  message: Maybe<string>;
};

export type GenerateCombinedModelQueryVariables = {
  id: number;
};

export type GenerateCombinedModelQueryQuery = {
  __typename?: 'Query';

  queryGenerateCombinedModel: Maybe<GenerateCombinedModelQueryQueryGenerateCombinedModel>;
};

export type GenerateCombinedModelQueryQueryGenerateCombinedModel = {
  __typename?: 'GenerateCombinedModel';

  id: Maybe<number>;

  modelId: Maybe<string>;

  userId: Maybe<number>;

  uploadToken: Maybe<string>;

  status: Maybe<ComStatus>;

  message: Maybe<string>;
};

export type GetAppTokenVariables = {};

export type GetAppTokenQuery = {
  __typename?: 'Query';

  permanentAccessToken: string;
};

export type GetAssetFolderContentsVariables = {
  folderId: number;
};

export type GetAssetFolderContentsQuery = {
  __typename?: 'Query';

  folder: Maybe<GetAssetFolderContentsFolder>;
};

export type GetAssetFolderContentsFolder = {
  __typename?: 'Folder';

  id: number;

  childFolders: Maybe<GetAssetFolderContentsChildFolders[]>;

  assets: Maybe<GetAssetFolderContents_Assets[]>;
};

export type GetAssetFolderContentsChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetAssetFolderContentsCreatedBy;

  childFolders: Maybe<GetAssetFolderContents_ChildFolders[]>;

  assets: Maybe<GetAssetFolderContentsAssets[]>;
};

export type GetAssetFolderContentsCreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetAssetFolderContents_ChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetAssetFolderContents_CreatedBy;
};

export type GetAssetFolderContents_CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetAssetFolderContentsAssets = {
  __typename?: 'Asset';

  id: number;

  type: AssetType;

  name: string;

  status: AssetStatus;

  createdAt: string;

  createdBy: GetAssetFolderContents__CreatedBy;

  downloadUrls: Maybe<GetAssetFolderContentsDownloadUrls[]>;

  convertedUrls: Maybe<GetAssetFolderContentsConvertedUrls[]>;
};

export type GetAssetFolderContents__CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetAssetFolderContentsDownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetAssetFolderContentsConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetAssetFolderContents_Assets = {
  __typename?: 'Asset';

  id: number;

  type: AssetType;

  name: string;

  status: AssetStatus;

  createdAt: string;

  createdBy: GetAssetFolderContents___CreatedBy;

  downloadUrls: Maybe<GetAssetFolderContents_DownloadUrls[]>;

  convertedUrls: Maybe<GetAssetFolderContents_ConvertedUrls[]>;
};

export type GetAssetFolderContents___CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetAssetFolderContents_DownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetAssetFolderContents_ConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetFolderVariables = {
  folderId: number;
};

export type GetFolderQuery = {
  __typename?: 'Query';

  folder: Maybe<GetFolderFolder>;
};

export type GetFolderFolder = {
  __typename?: 'Folder';

  id: number;

  name: string;
};

export type GetFolderParentVariables = {
  folderId: number;
};

export type GetFolderParentQuery = {
  __typename?: 'Query';

  folder: Maybe<GetFolderParentFolder>;
};

export type GetFolderParentFolder = {
  __typename?: 'Folder';

  id: number;

  parentFolderId: Maybe<number>;

  parentHierarchy: Maybe<GetFolderParentParentHierarchy[]>;
};

export type GetFolderParentParentHierarchy = {
  __typename?: 'FolderInfo';

  id: number;

  name: string;
};

export type GetModelFolderVariables = {
  modelId: string;
};

export type GetModelFolderQuery = {
  __typename?: 'Query';

  model: Maybe<GetModelFolderModel>;
};

export type GetModelFolderModel = {
  __typename?: 'Model';

  id: string;

  folderId: Maybe<number>;
};

export type GetModelFolderContentsVariables = {
  folderId: number;
};

export type GetModelFolderContentsQuery = {
  __typename?: 'Query';

  folder: Maybe<GetModelFolderContentsFolder>;
};

export type GetModelFolderContentsFolder = {
  __typename?: 'Folder';

  id: number;

  childFolders: Maybe<GetModelFolderContentsChildFolders[]>;

  models: Maybe<GetModelFolderContents__Models[]>;
};

export type GetModelFolderContentsChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetModelFolderContentsCreatedBy;

  childFolders: Maybe<GetModelFolderContents_ChildFolders[]>;

  models: Maybe<GetModelFolderContents_Models[]>;
};

export type GetModelFolderContentsCreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelFolderContents_ChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetModelFolderContents_CreatedBy;

  models: Maybe<GetModelFolderContentsModels[]>;
};

export type GetModelFolderContents_CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelFolderContentsModels = {
  __typename?: 'Model';

  id: string;

  name: string;

  status: ModelStatus;

  createdAt: string;

  createdBy: GetModelFolderContents__CreatedBy;

  downloadUrls: Maybe<GetModelFolderContentsDownloadUrls[]>;

  convertedUrls: Maybe<GetModelFolderContentsConvertedUrls[]>;
};

export type GetModelFolderContents__CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelFolderContentsDownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelFolderContentsConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelFolderContents_Models = {
  __typename?: 'Model';

  id: string;

  name: string;

  status: ModelStatus;

  createdAt: string;

  createdBy: GetModelFolderContents___CreatedBy;

  downloadUrls: Maybe<GetModelFolderContents_DownloadUrls[]>;

  convertedUrls: Maybe<GetModelFolderContents_ConvertedUrls[]>;
};

export type GetModelFolderContents___CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelFolderContents_DownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelFolderContents_ConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelFolderContents__Models = {
  __typename?: 'Model';

  id: string;

  name: string;

  status: ModelStatus;

  createdAt: string;

  createdBy: GetModelFolderContents____CreatedBy;

  downloadUrls: Maybe<GetModelFolderContents__DownloadUrls[]>;

  convertedUrls: Maybe<GetModelFolderContents__ConvertedUrls[]>;
};

export type GetModelFolderContents____CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelFolderContents__DownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelFolderContents__ConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelsVariables = {
  folderId: number;
};

export type GetModelsQuery = {
  __typename?: 'Query';

  folder: Maybe<GetModelsFolder>;
};

export type GetModelsFolder = {
  __typename?: 'Folder';

  id: number;

  name: string;

  childFolders: Maybe<GetModelsChildFolders[]>;

  models: Maybe<GetModelsModels[]>;
};

export type GetModelsChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetModelsCreatedBy;
};

export type GetModelsCreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelsModels = {
  __typename?: 'Model';

  id: string;

  name: string;

  status: ModelStatus;

  createdAt: string;

  createdBy: GetModels_CreatedBy;

  downloadUrls: Maybe<GetModelsDownloadUrls[]>;

  convertedUrls: Maybe<GetModelsConvertedUrls[]>;
};

export type GetModels_CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetModelsDownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelsConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelUrlsVariables = {
  modelId: string;
};

export type GetModelUrlsQuery = {
  __typename?: 'Query';

  model: Maybe<GetModelUrlsModel>;
};

export type GetModelUrlsModel = {
  __typename?: 'Model';

  id: string;

  modelAdjustmentArr: Maybe<number[]>;

  downloadUrls: Maybe<GetModelUrlsDownloadUrls[]>;

  convertedUrls: Maybe<GetModelUrlsConvertedUrls[]>;
};

export type GetModelUrlsDownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetModelUrlsConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetProjectFoldersVariables = {
  projectId: number;
};

export type GetProjectFoldersQuery = {
  __typename?: 'Query';

  project: Maybe<GetProjectFoldersProject>;
};

export type GetProjectFoldersProject = {
  __typename?: 'Project';

  id: number;

  modelFolder: GetProjectFoldersModelFolder;

  assetFolder: GetProjectFoldersAssetFolder;

  wbsAssetFolder: GetProjectFoldersWbsAssetFolder;
};

export type GetProjectFoldersModelFolder = {
  __typename?: 'Folder';

  id: number;
};

export type GetProjectFoldersAssetFolder = {
  __typename?: 'Folder';

  id: number;
};

export type GetProjectFoldersWbsAssetFolder = {
  __typename?: 'Folder';

  id: number;
};

export type GetProjectsVariables = {};

export type GetProjectsQuery = {
  __typename?: 'Query';

  user: Maybe<GetProjectsUser>;
};

export type GetProjectsUser = {
  __typename?: 'User';

  id: number;

  username: string;

  projects: Maybe<GetProjectsProjects[]>;
};

export type GetProjectsProjects = {
  __typename?: 'Project';

  id: number;

  name: string;
};

export type GetRootLevelAssetsVariables = {};

export type GetRootLevelAssetsQuery = {
  __typename?: 'Query';

  user: Maybe<GetRootLevelAssetsUser>;
};

export type GetRootLevelAssetsUser = {
  __typename?: 'User';

  id: number;

  projects: Maybe<GetRootLevelAssetsProjects[]>;
};

export type GetRootLevelAssetsProjects = {
  __typename?: 'Project';

  id: number;

  assetFolder: GetRootLevelAssetsAssetFolder;
};

export type GetRootLevelAssetsAssetFolder = {
  __typename?: 'Folder';

  id: number;

  childFolders: Maybe<GetRootLevelAssetsChildFolders[]>;

  assets: Maybe<GetRootLevelAssetsAssets[]>;
};

export type GetRootLevelAssetsChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetRootLevelAssetsCreatedBy;
};

export type GetRootLevelAssetsCreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetRootLevelAssetsAssets = {
  __typename?: 'Asset';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetRootLevelAssets_CreatedBy;

  downloadUrls: Maybe<GetRootLevelAssetsDownloadUrls[]>;

  convertedUrls: Maybe<GetRootLevelAssetsConvertedUrls[]>;
};

export type GetRootLevelAssets_CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetRootLevelAssetsDownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetRootLevelAssetsConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetRootLevelModelsVariables = {};

export type GetRootLevelModelsQuery = {
  __typename?: 'Query';

  user: Maybe<GetRootLevelModelsUser>;
};

export type GetRootLevelModelsUser = {
  __typename?: 'User';

  id: number;

  projects: Maybe<GetRootLevelModelsProjects[]>;
};

export type GetRootLevelModelsProjects = {
  __typename?: 'Project';

  id: number;

  modelFolder: GetRootLevelModelsModelFolder;
};

export type GetRootLevelModelsModelFolder = {
  __typename?: 'Folder';

  id: number;

  childFolders: Maybe<GetRootLevelModelsChildFolders[]>;

  models: Maybe<GetRootLevelModelsModels[]>;
};

export type GetRootLevelModelsChildFolders = {
  __typename?: 'Folder';

  id: number;

  name: string;

  createdAt: string;

  createdBy: GetRootLevelModelsCreatedBy;
};

export type GetRootLevelModelsCreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetRootLevelModelsModels = {
  __typename?: 'Model';

  id: string;

  name: string;

  createdAt: string;

  createdBy: GetRootLevelModels_CreatedBy;

  downloadUrls: Maybe<GetRootLevelModelsDownloadUrls[]>;

  convertedUrls: Maybe<GetRootLevelModelsConvertedUrls[]>;
};

export type GetRootLevelModels_CreatedBy = {
  __typename?: 'User';

  id: number;

  username: string;
};

export type GetRootLevelModelsDownloadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type GetRootLevelModelsConvertedUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type UpdateAppTokenVariables = {};

export type UpdateAppTokenMutation = {
  __typename?: 'Mutation';

  updatePermanentAccessToken: string;
};

export type UpdateAssetVariables = {
  assetId: number;
  name?: Maybe<string>;
  folderId?: Maybe<number>;
};

export type UpdateAssetMutation = {
  __typename?: 'Mutation';

  updateAsset: UpdateAssetUpdateAsset;
};

export type UpdateAssetUpdateAsset = {
  __typename?: 'Asset';

  id: number;
};

export type UpdateFolderVariables = {
  folderId: number;
  name?: Maybe<string>;
  parentFolderId?: Maybe<number>;
};

export type UpdateFolderMutation = {
  __typename?: 'Mutation';

  updateFolder: UpdateFolderUpdateFolder;
};

export type UpdateFolderUpdateFolder = {
  __typename?: 'Folder';

  id: number;

  name: string;
};

export type UpdateModelVariables = {
  modelId: string;
  name?: Maybe<string>;
  folderId?: Maybe<number>;
};

export type UpdateModelMutation = {
  __typename?: 'Mutation';

  updateModel: UpdateModelUpdateModel;
};

export type UpdateModelUpdateModel = {
  __typename?: 'Model';

  id: string;
};

export type UploadAssetVariables = {
  folderId?: Maybe<number>;
  assetName: string;
  fileNames: string[];
  type: AssetType;
};

export type UploadAssetMutation = {
  __typename?: 'Mutation';

  uploadAsset: UploadAssetUploadAsset;
};

export type UploadAssetUploadAsset = {
  __typename?: 'UploadAssetSession';

  id: number;

  uploadUrls: UploadAssetUploadUrls[];
};

export type UploadAssetUploadUrls = {
  __typename?: 'FileUrl';

  filename: string;

  url: string;
};

export type UploadAssetSuccessVariables = {
  assetId: number;
};

export type UploadAssetSuccessMutation = {
  __typename?: 'Mutation';

  uploadAssetSuccess: UploadAssetSuccessUploadAssetSuccess;
};

export type UploadAssetSuccessUploadAssetSuccess = {
  __typename?: 'Asset';

  id: number;

  name: string;

  folderId: Maybe<number>;

  type: AssetType;

  status: AssetStatus;

  filenames: string[];

  createdBy: UploadAssetSuccessCreatedBy;

  createdAt: string;

  updatedAt: string;
};

export type UploadAssetSuccessCreatedBy = {
  __typename?: 'User';

  username: string;
};

import * as ReactApollo from 'react-apollo';
import * as React from 'react';

import gql from 'graphql-tag';

// ====================================================
// Components
// ====================================================

export const AddFolderDocument = gql`
  mutation AddFolder($projectId: Int!, $name: String!, $parentFolderId: Int!) {
    addFolder(projectId: $projectId, name: $name, parentFolderId: $parentFolderId) {
      id
      name
    }
  }
`;
export class AddFolderComponent extends React.Component<
  Partial<ReactApollo.MutationProps<AddFolderMutation, AddFolderVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<AddFolderMutation, AddFolderVariables>
        mutation={AddFolderDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type AddFolderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AddFolderMutation, AddFolderVariables>
> &
  TChildProps;
export type AddFolderMutationFn = ReactApollo.MutationFn<AddFolderMutation, AddFolderVariables>;
export function AddFolderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, AddFolderMutation, AddFolderVariables, AddFolderProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, AddFolderMutation, AddFolderVariables, AddFolderProps<TChildProps>>(
    AddFolderDocument,
    operationOptions
  );
}
export const DeleteAssetDocument = gql`
  mutation DeleteAsset($assetId: Int!) {
    deleteAsset(id: $assetId)
  }
`;
export class DeleteAssetComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteAssetMutation, DeleteAssetVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteAssetMutation, DeleteAssetVariables>
        mutation={DeleteAssetDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type DeleteAssetProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteAssetMutation, DeleteAssetVariables>
> &
  TChildProps;
export type DeleteAssetMutationFn = ReactApollo.MutationFn<DeleteAssetMutation, DeleteAssetVariables>;
export function DeleteAssetHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, DeleteAssetMutation, DeleteAssetVariables, DeleteAssetProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, DeleteAssetMutation, DeleteAssetVariables, DeleteAssetProps<TChildProps>>(
    DeleteAssetDocument,
    operationOptions
  );
}
export const DeleteFolderDocument = gql`
  mutation DeleteFolder($folderId: Int!) {
    deleteFolder(id: $folderId)
  }
`;
export class DeleteFolderComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteFolderMutation, DeleteFolderVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteFolderMutation, DeleteFolderVariables>
        mutation={DeleteFolderDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type DeleteFolderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteFolderMutation, DeleteFolderVariables>
> &
  TChildProps;
export type DeleteFolderMutationFn = ReactApollo.MutationFn<DeleteFolderMutation, DeleteFolderVariables>;
export function DeleteFolderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, DeleteFolderMutation, DeleteFolderVariables, DeleteFolderProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, DeleteFolderMutation, DeleteFolderVariables, DeleteFolderProps<TChildProps>>(
    DeleteFolderDocument,
    operationOptions
  );
}
export const DeleteModelDocument = gql`
  mutation DeleteModel($modelId: Id!) {
    deleteModel(id: $modelId)
  }
`;
export class DeleteModelComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteModelMutation, DeleteModelVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteModelMutation, DeleteModelVariables>
        mutation={DeleteModelDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type DeleteModelProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteModelMutation, DeleteModelVariables>
> &
  TChildProps;
export type DeleteModelMutationFn = ReactApollo.MutationFn<DeleteModelMutation, DeleteModelVariables>;
export function DeleteModelHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, DeleteModelMutation, DeleteModelVariables, DeleteModelProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, DeleteModelMutation, DeleteModelVariables, DeleteModelProps<TChildProps>>(
    DeleteModelDocument,
    operationOptions
  );
}
export const GenerateCombinedModelDocument = gql`
  mutation GenerateCombinedModel($modelId: Id!, $uploadToken: String!) {
    generateCombinedModel(modelId: $modelId, uploadToken: $uploadToken) {
      id
      modelId
      userId
      uploadToken
      status
      message
    }
  }
`;
export class GenerateCombinedModelComponent extends React.Component<
  Partial<ReactApollo.MutationProps<GenerateCombinedModelMutation, GenerateCombinedModelVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<GenerateCombinedModelMutation, GenerateCombinedModelVariables>
        mutation={GenerateCombinedModelDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GenerateCombinedModelProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<GenerateCombinedModelMutation, GenerateCombinedModelVariables>
> &
  TChildProps;
export type GenerateCombinedModelMutationFn = ReactApollo.MutationFn<
  GenerateCombinedModelMutation,
  GenerateCombinedModelVariables
>;
export function GenerateCombinedModelHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GenerateCombinedModelMutation,
        GenerateCombinedModelVariables,
        GenerateCombinedModelProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GenerateCombinedModelMutation,
    GenerateCombinedModelVariables,
    GenerateCombinedModelProps<TChildProps>
  >(GenerateCombinedModelDocument, operationOptions);
}
export const GenerateCombinedModelQueryDocument = gql`
  query GenerateCombinedModelQuery($id: Int!) {
    queryGenerateCombinedModel(id: $id) {
      id
      modelId
      userId
      uploadToken
      status
      message
    }
  }
`;
export class GenerateCombinedModelQueryComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GenerateCombinedModelQueryQuery, GenerateCombinedModelQueryVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GenerateCombinedModelQueryQuery, GenerateCombinedModelQueryVariables>
        query={GenerateCombinedModelQueryDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GenerateCombinedModelQueryProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GenerateCombinedModelQueryQuery, GenerateCombinedModelQueryVariables>
> &
  TChildProps;
export function GenerateCombinedModelQueryHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GenerateCombinedModelQueryQuery,
        GenerateCombinedModelQueryVariables,
        GenerateCombinedModelQueryProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GenerateCombinedModelQueryQuery,
    GenerateCombinedModelQueryVariables,
    GenerateCombinedModelQueryProps<TChildProps>
  >(GenerateCombinedModelQueryDocument, operationOptions);
}
export const GetAppTokenDocument = gql`
  query GetAppToken {
    permanentAccessToken
  }
`;
export class GetAppTokenComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetAppTokenQuery, GetAppTokenVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetAppTokenQuery, GetAppTokenVariables>
        query={GetAppTokenDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetAppTokenProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetAppTokenQuery, GetAppTokenVariables>
> &
  TChildProps;
export function GetAppTokenHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, GetAppTokenQuery, GetAppTokenVariables, GetAppTokenProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, GetAppTokenQuery, GetAppTokenVariables, GetAppTokenProps<TChildProps>>(
    GetAppTokenDocument,
    operationOptions
  );
}
export const GetAssetFolderContentsDocument = gql`
  query GetAssetFolderContents($folderId: Int!) {
    folder(id: $folderId) {
      id
      childFolders {
        id
        name
        createdAt
        createdBy {
          id
          username
        }
        childFolders {
          id
          name
          createdAt
          createdBy {
            id
            username
          }
        }
        assets {
          id
          type
          name
          status
          createdAt
          createdBy {
            id
            username
          }
          downloadUrls {
            filename
            url
          }
          convertedUrls {
            filename
            url
          }
        }
      }
      assets {
        id
        type
        name
        status
        createdAt
        createdBy {
          id
          username
        }
        downloadUrls {
          filename
          url
        }
        convertedUrls {
          filename
          url
        }
      }
    }
  }
`;
export class GetAssetFolderContentsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetAssetFolderContentsQuery, GetAssetFolderContentsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetAssetFolderContentsQuery, GetAssetFolderContentsVariables>
        query={GetAssetFolderContentsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetAssetFolderContentsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetAssetFolderContentsQuery, GetAssetFolderContentsVariables>
> &
  TChildProps;
export function GetAssetFolderContentsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetAssetFolderContentsQuery,
        GetAssetFolderContentsVariables,
        GetAssetFolderContentsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetAssetFolderContentsQuery,
    GetAssetFolderContentsVariables,
    GetAssetFolderContentsProps<TChildProps>
  >(GetAssetFolderContentsDocument, operationOptions);
}
export const GetFolderDocument = gql`
  query GetFolder($folderId: Int!) {
    folder(id: $folderId) {
      id
      name
    }
  }
`;
export class GetFolderComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetFolderQuery, GetFolderVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetFolderQuery, GetFolderVariables>
        query={GetFolderDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetFolderProps<TChildProps = any> = Partial<ReactApollo.DataProps<GetFolderQuery, GetFolderVariables>> &
  TChildProps;
export function GetFolderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, GetFolderQuery, GetFolderVariables, GetFolderProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, GetFolderQuery, GetFolderVariables, GetFolderProps<TChildProps>>(
    GetFolderDocument,
    operationOptions
  );
}
export const GetFolderParentDocument = gql`
  query GetFolderParent($folderId: Int!) {
    folder(id: $folderId) {
      id
      parentFolderId
      parentHierarchy {
        id
        name
      }
    }
  }
`;
export class GetFolderParentComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetFolderParentQuery, GetFolderParentVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetFolderParentQuery, GetFolderParentVariables>
        query={GetFolderParentDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetFolderParentProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetFolderParentQuery, GetFolderParentVariables>
> &
  TChildProps;
export function GetFolderParentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetFolderParentQuery,
        GetFolderParentVariables,
        GetFolderParentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<TProps, GetFolderParentQuery, GetFolderParentVariables, GetFolderParentProps<TChildProps>>(
    GetFolderParentDocument,
    operationOptions
  );
}
export const GetModelFolderDocument = gql`
  query GetModelFolder($modelId: Id!) {
    model(id: $modelId) {
      id
      folderId
    }
  }
`;
export class GetModelFolderComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetModelFolderQuery, GetModelFolderVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetModelFolderQuery, GetModelFolderVariables>
        query={GetModelFolderDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetModelFolderProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetModelFolderQuery, GetModelFolderVariables>
> &
  TChildProps;
export function GetModelFolderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetModelFolderQuery,
        GetModelFolderVariables,
        GetModelFolderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<TProps, GetModelFolderQuery, GetModelFolderVariables, GetModelFolderProps<TChildProps>>(
    GetModelFolderDocument,
    operationOptions
  );
}
export const GetModelFolderContentsDocument = gql`
  query GetModelFolderContents($folderId: Int!) {
    folder(id: $folderId) {
      id
      childFolders {
        id
        name
        createdAt
        createdBy {
          id
          username
        }
        childFolders {
          id
          name
          createdAt
          createdBy {
            id
            username
          }
          models {
            id
            name
            status
            createdAt
            createdBy {
              id
              username
            }
            downloadUrls {
              filename
              url
            }
            convertedUrls {
              filename
              url
            }
          }
        }
        models {
          id
          name
          status
          createdAt
          createdBy {
            id
            username
          }
          downloadUrls {
            filename
            url
          }
          convertedUrls {
            filename
            url
          }
        }
      }
      models {
        id
        name
        status
        createdAt
        createdBy {
          id
          username
        }
        downloadUrls {
          filename
          url
        }
        convertedUrls {
          filename
          url
        }
      }
    }
  }
`;
export class GetModelFolderContentsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetModelFolderContentsQuery, GetModelFolderContentsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetModelFolderContentsQuery, GetModelFolderContentsVariables>
        query={GetModelFolderContentsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetModelFolderContentsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetModelFolderContentsQuery, GetModelFolderContentsVariables>
> &
  TChildProps;
export function GetModelFolderContentsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetModelFolderContentsQuery,
        GetModelFolderContentsVariables,
        GetModelFolderContentsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetModelFolderContentsQuery,
    GetModelFolderContentsVariables,
    GetModelFolderContentsProps<TChildProps>
  >(GetModelFolderContentsDocument, operationOptions);
}
export const GetModelsDocument = gql`
  query GetModels($folderId: Int!) {
    folder(id: $folderId) {
      id
      name
      childFolders {
        id
        name
        createdAt
        createdBy {
          id
          username
        }
      }
      models {
        id
        name
        status
        createdAt
        createdBy {
          id
          username
        }
        downloadUrls {
          filename
          url
        }
        convertedUrls {
          filename
          url
        }
      }
    }
  }
`;
export class GetModelsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetModelsQuery, GetModelsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetModelsQuery, GetModelsVariables>
        query={GetModelsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetModelsProps<TChildProps = any> = Partial<ReactApollo.DataProps<GetModelsQuery, GetModelsVariables>> &
  TChildProps;
export function GetModelsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, GetModelsQuery, GetModelsVariables, GetModelsProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, GetModelsQuery, GetModelsVariables, GetModelsProps<TChildProps>>(
    GetModelsDocument,
    operationOptions
  );
}
export const GetModelUrlsDocument = gql`
  query GetModelUrls($modelId: Id!) {
    model(id: $modelId) {
      id
      modelAdjustmentArr
      downloadUrls {
        filename
        url
      }
      convertedUrls {
        filename
        url
      }
    }
  }
`;
export class GetModelUrlsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetModelUrlsQuery, GetModelUrlsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetModelUrlsQuery, GetModelUrlsVariables>
        query={GetModelUrlsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetModelUrlsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetModelUrlsQuery, GetModelUrlsVariables>
> &
  TChildProps;
export function GetModelUrlsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, GetModelUrlsQuery, GetModelUrlsVariables, GetModelUrlsProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, GetModelUrlsQuery, GetModelUrlsVariables, GetModelUrlsProps<TChildProps>>(
    GetModelUrlsDocument,
    operationOptions
  );
}
export const GetProjectFoldersDocument = gql`
  query GetProjectFolders($projectId: Int!) {
    project(id: $projectId) {
      id
      modelFolder {
        id
      }
      assetFolder {
        id
      }
      wbsAssetFolder {
        id
      }
    }
  }
`;
export class GetProjectFoldersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetProjectFoldersQuery, GetProjectFoldersVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetProjectFoldersQuery, GetProjectFoldersVariables>
        query={GetProjectFoldersDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetProjectFoldersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetProjectFoldersQuery, GetProjectFoldersVariables>
> &
  TChildProps;
export function GetProjectFoldersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetProjectFoldersQuery,
        GetProjectFoldersVariables,
        GetProjectFoldersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetProjectFoldersQuery,
    GetProjectFoldersVariables,
    GetProjectFoldersProps<TChildProps>
  >(GetProjectFoldersDocument, operationOptions);
}
export const GetProjectsDocument = gql`
  query GetProjects {
    user {
      id
      username
      projects {
        id
        name
      }
    }
  }
`;
export class GetProjectsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetProjectsQuery, GetProjectsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetProjectsQuery, GetProjectsVariables>
        query={GetProjectsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetProjectsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetProjectsQuery, GetProjectsVariables>
> &
  TChildProps;
export function GetProjectsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, GetProjectsQuery, GetProjectsVariables, GetProjectsProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, GetProjectsQuery, GetProjectsVariables, GetProjectsProps<TChildProps>>(
    GetProjectsDocument,
    operationOptions
  );
}
export const GetRootLevelAssetsDocument = gql`
  query GetRootLevelAssets {
    user {
      id
      projects {
        id
        assetFolder {
          id
          childFolders {
            id
            name
            createdAt
            createdBy {
              id
              username
            }
          }
          assets {
            id
            name
            createdAt
            createdBy {
              id
              username
            }
            downloadUrls {
              filename
              url
            }
            convertedUrls {
              filename
              url
            }
          }
        }
      }
    }
  }
`;
export class GetRootLevelAssetsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetRootLevelAssetsQuery, GetRootLevelAssetsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetRootLevelAssetsQuery, GetRootLevelAssetsVariables>
        query={GetRootLevelAssetsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetRootLevelAssetsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetRootLevelAssetsQuery, GetRootLevelAssetsVariables>
> &
  TChildProps;
export function GetRootLevelAssetsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetRootLevelAssetsQuery,
        GetRootLevelAssetsVariables,
        GetRootLevelAssetsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetRootLevelAssetsQuery,
    GetRootLevelAssetsVariables,
    GetRootLevelAssetsProps<TChildProps>
  >(GetRootLevelAssetsDocument, operationOptions);
}
export const GetRootLevelModelsDocument = gql`
  query GetRootLevelModels {
    user {
      id
      projects {
        id
        modelFolder {
          id
          childFolders {
            id
            name
            createdAt
            createdBy {
              id
              username
            }
          }
          models {
            id
            name
            createdAt
            createdBy {
              id
              username
            }
            downloadUrls {
              filename
              url
            }
            convertedUrls {
              filename
              url
            }
          }
        }
      }
    }
  }
`;
export class GetRootLevelModelsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetRootLevelModelsQuery, GetRootLevelModelsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetRootLevelModelsQuery, GetRootLevelModelsVariables>
        query={GetRootLevelModelsDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type GetRootLevelModelsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetRootLevelModelsQuery, GetRootLevelModelsVariables>
> &
  TChildProps;
export function GetRootLevelModelsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetRootLevelModelsQuery,
        GetRootLevelModelsVariables,
        GetRootLevelModelsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetRootLevelModelsQuery,
    GetRootLevelModelsVariables,
    GetRootLevelModelsProps<TChildProps>
  >(GetRootLevelModelsDocument, operationOptions);
}
export const UpdateAppTokenDocument = gql`
  mutation UpdateAppToken {
    updatePermanentAccessToken
  }
`;
export class UpdateAppTokenComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateAppTokenMutation, UpdateAppTokenVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateAppTokenMutation, UpdateAppTokenVariables>
        mutation={UpdateAppTokenDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type UpdateAppTokenProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateAppTokenMutation, UpdateAppTokenVariables>
> &
  TChildProps;
export type UpdateAppTokenMutationFn = ReactApollo.MutationFn<UpdateAppTokenMutation, UpdateAppTokenVariables>;
export function UpdateAppTokenHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateAppTokenMutation,
        UpdateAppTokenVariables,
        UpdateAppTokenProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<TProps, UpdateAppTokenMutation, UpdateAppTokenVariables, UpdateAppTokenProps<TChildProps>>(
    UpdateAppTokenDocument,
    operationOptions
  );
}
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($assetId: Int!, $name: String, $folderId: Int) {
    updateAsset(id: $assetId, name: $name, folderId: $folderId) {
      id
    }
  }
`;
export class UpdateAssetComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateAssetMutation, UpdateAssetVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateAssetMutation, UpdateAssetVariables>
        mutation={UpdateAssetDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type UpdateAssetProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateAssetMutation, UpdateAssetVariables>
> &
  TChildProps;
export type UpdateAssetMutationFn = ReactApollo.MutationFn<UpdateAssetMutation, UpdateAssetVariables>;
export function UpdateAssetHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, UpdateAssetMutation, UpdateAssetVariables, UpdateAssetProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, UpdateAssetMutation, UpdateAssetVariables, UpdateAssetProps<TChildProps>>(
    UpdateAssetDocument,
    operationOptions
  );
}
export const UpdateFolderDocument = gql`
  mutation UpdateFolder($folderId: Int!, $name: String, $parentFolderId: Int) {
    updateFolder(id: $folderId, name: $name, parentFolderId: $parentFolderId) {
      id
      name
    }
  }
`;
export class UpdateFolderComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateFolderMutation, UpdateFolderVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateFolderMutation, UpdateFolderVariables>
        mutation={UpdateFolderDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type UpdateFolderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateFolderMutation, UpdateFolderVariables>
> &
  TChildProps;
export type UpdateFolderMutationFn = ReactApollo.MutationFn<UpdateFolderMutation, UpdateFolderVariables>;
export function UpdateFolderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, UpdateFolderMutation, UpdateFolderVariables, UpdateFolderProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, UpdateFolderMutation, UpdateFolderVariables, UpdateFolderProps<TChildProps>>(
    UpdateFolderDocument,
    operationOptions
  );
}
export const UpdateModelDocument = gql`
  mutation UpdateModel($modelId: Id!, $name: String, $folderId: Int) {
    updateModel(id: $modelId, name: $name, folderId: $folderId) {
      id
    }
  }
`;
export class UpdateModelComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateModelMutation, UpdateModelVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateModelMutation, UpdateModelVariables>
        mutation={UpdateModelDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type UpdateModelProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateModelMutation, UpdateModelVariables>
> &
  TChildProps;
export type UpdateModelMutationFn = ReactApollo.MutationFn<UpdateModelMutation, UpdateModelVariables>;
export function UpdateModelHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, UpdateModelMutation, UpdateModelVariables, UpdateModelProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, UpdateModelMutation, UpdateModelVariables, UpdateModelProps<TChildProps>>(
    UpdateModelDocument,
    operationOptions
  );
}
export const UploadAssetDocument = gql`
  mutation UploadAsset($folderId: Int, $assetName: String!, $fileNames: [String]!, $type: AssetType!) {
    uploadAsset(folderId: $folderId, name: $assetName, filenames: $fileNames, type: $type) {
      id
      uploadUrls {
        filename
        url
      }
    }
  }
`;
export class UploadAssetComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UploadAssetMutation, UploadAssetVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UploadAssetMutation, UploadAssetVariables>
        mutation={UploadAssetDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type UploadAssetProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UploadAssetMutation, UploadAssetVariables>
> &
  TChildProps;
export type UploadAssetMutationFn = ReactApollo.MutationFn<UploadAssetMutation, UploadAssetVariables>;
export function UploadAssetHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, UploadAssetMutation, UploadAssetVariables, UploadAssetProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, UploadAssetMutation, UploadAssetVariables, UploadAssetProps<TChildProps>>(
    UploadAssetDocument,
    operationOptions
  );
}
export const UploadAssetSuccessDocument = gql`
  mutation UploadAssetSuccess($assetId: Int!) {
    uploadAssetSuccess(id: $assetId) {
      id
      name
      folderId
      type
      status
      filenames
      createdBy {
        username
      }
      createdAt
      updatedAt
    }
  }
`;
export class UploadAssetSuccessComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UploadAssetSuccessMutation, UploadAssetSuccessVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UploadAssetSuccessMutation, UploadAssetSuccessVariables>
        mutation={UploadAssetSuccessDocument}
        {...(this as any)['props'] as any}
      />
    );
  }
}
export type UploadAssetSuccessProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UploadAssetSuccessMutation, UploadAssetSuccessVariables>
> &
  TChildProps;
export type UploadAssetSuccessMutationFn = ReactApollo.MutationFn<
  UploadAssetSuccessMutation,
  UploadAssetSuccessVariables
>;
export function UploadAssetSuccessHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UploadAssetSuccessMutation,
        UploadAssetSuccessVariables,
        UploadAssetSuccessProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UploadAssetSuccessMutation,
    UploadAssetSuccessVariables,
    UploadAssetSuccessProps<TChildProps>
  >(UploadAssetSuccessDocument, operationOptions);
}
