import { createAction } from '../../utils/actions';
import api from '../../../api';

export const UPLOAD_MODEL = '@Files/UPLOAD_MODEL';
export const UPLOAD_MODEL_PENDING = '@Files/UPLOAD_MODEL_PENDING';
export const UPLOAD_MODEL_FULFILLED = '@Files/UPLOAD_MODEL_FULFILLED';
export const UPLOAD_MODEL_REJECTED = '@Files/UPLOAD_MODEL_REJECTED';
export const UPLOAD_ASSET = '@Files/UPLOAD_ASSET';
export const UPLOAD_ASSET_PENDING = '@Files/UPLOAD_ASSET_PENDING';
export const UPLOAD_ASSET_FULFILLED = '@Files/UPLOAD_ASSET_FULFILLED';
export const UPLOAD_ASSET_REJECTED = '@Files/UPLOAD_ASSET_REJECTED';
export const UPDATE_PROGRESS = '@Files/UPLOAD_PROGRESS';

export const uploadModel = (config: api.UploadModelConfig & { tempFileId: string }) =>
  createAction(UPLOAD_MODEL, api.uploadModel(config), { ...config, tempFileId: config.tempFileId, fileType: 'model' });
export const updateProgress = (fileId: string, progress: number) => createAction(UPDATE_PROGRESS, { fileId, progress });
export const uploadAsset = (config: api.UploadAssetConfig & { tempFileId: string, type?: string }) =>
  createAction(UPLOAD_ASSET, api.uploadAsset(config), { ...config, tempFileId: config.tempFileId, fileType: 'asset' });
