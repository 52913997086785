import _ from 'lodash';
import { CombineModelItem, CombineModelState, CombineModelStatus, combineModelStore, CombineModelType, CombineModelParams } from '../stores/combine-model-store';
import { Effect, EntryObject } from './effect';

export class CombineModelEffect extends Effect<CombineModelState> {
  private _viewer: any;
  private gizmoTool: any;

  constructor(viewer?: any) {
    super(combineModelStore);
    if (!viewer) return;
    this._viewer = viewer;
    this.gizmoTool = new Modelo.View.Tool.TransformGizmo(viewer);
    this._viewer.addTool(this.gizmoTool);
    viewer.getEventEmitter().on("Model-Transform-Update", (data: any) => {
      combineModelStore.update(state => {
        const newParams = {...state.params};
        if (data.type === CombineModelType.Rotate) {
          newParams.rotate = [data.x, data.y, data.z].map(i => Math.floor(i * 100) / 100).map(i => parseFloat((180 * i / Math.PI).toFixed(2)));
        } else {
          newParams.translate = [data.x, data.y, data.z].map(i => Math.floor(i * 100) / 100);
        }
        newParams.fromToolBar = false;
        state.settingType = data.type;
        state.params = newParams;
      })
    });
    viewer.getEventEmitter().on("TransformGizmo-End", () => {
      const {paramsHistory,paramsHistoryIndex} = combineModelStore.state;
      let currentParamsHistory : CombineModelParams[] = [];
      Object.assign(currentParamsHistory,paramsHistory);
      if((paramsHistory.length > 1) && (paramsHistoryIndex < paramsHistory.length-1)){
        currentParamsHistory.length = paramsHistoryIndex + 1;
      }
      combineModelStore.update(state => {
        state.paramsHistory = [...currentParamsHistory, state.params];
        state.paramsHistoryIndex = state.paramsHistory.length - 1;
      })
    })
  }

  handleEffect(patch: EntryObject<CombineModelState>) {
    const state = combineModelStore.state;
    switch (patch.key) {
      case 'optModel':
        if (patch.value === '') return;
        if (!this.gizmoTool._enabled) {
          this.gizmoTool.setModel(patch.value);
          this.gizmoTool.setEnabled(true);
        } else {
          const matrix = this.gizmoTool.getworldMatrix();
          const translation = this.gizmoTool.getinitTranslation();
          this.gizmoTool.setModel(patch.value);

          const newParams = {
            translate: this.gizmoTool.getTranslation().map((value : number) => (parseFloat(value.toFixed(2)) || 0)),
            scale: [0, 0, 0],
            rotate: this.gizmoTool.getRotation().map((i : number) => Math.floor(i * 100) / 100).map((i : number) => parseFloat((180 * i / Math.PI).toFixed(2)))
          };
          combineModelStore.update(s => {
            s.lastTransformMatrix = matrix; // 获得交互空间当前的位置矩阵
            s.lastInitTranslation = translation; // 获得模型初始化时的坐标
            s.params = newParams;
            s.paramsHistory = [s.params];
            s.paramsHistoryIndex = -1;
          });
        }
        break;
      case 'lastInitTranslation': {
        break;
      }
      case 'lastTransformMatrix': {
        break;
      }
      case 'params': {
        if (patch.value.fromToolBar) {
          const values = patch.value.rotate.map(i => parseFloat((i * Math.PI * 100 / 180).toFixed(2)) / 100)
          this.gizmoTool.rotate(...values);
          this.gizmoTool.translate(...patch.value.translate);
        }

        
        break;
      }
      case 'paramsHistory': {
        break;
      }
      case 'paramsHistoryIndex': {
        if (patch.value < 0) return;
        const params = state.paramsHistory[patch.value];
        this.gizmoTool.rotate(...params.rotate.map(i => parseFloat((i * Math.PI * 100 / 180).toFixed(2)) / 100));
        this.gizmoTool.translate(...params.translate);
        combineModelStore.update(s => {
          s.params = params;
        });
        break;
      }
      case 'combine': {
        if(patch.value === CombineModelStatus.Start) {
          const models = this._viewer.getScene().core.models;
          const list: CombineModelItem[] = _.cloneDeep(state.combineModels).map(item => {
            item.transformArray = models[item.id].getTransformArray();
            return item;
          });
          combineModelStore.update(s => {
            s.combineModels = list;
          })
        }else if(patch.value === CombineModelStatus.Combine){
          combineModelStore.update(S => {
            S.params = {
              translate : [0, 0, 0],
              scale : [0, 0, 0],
              rotate : [0, 0, 0],
            };
            S.paramsHistoryIndex = -1;
            S.paramsHistory = [];
            S.optModel = '';
          });
        }
      }
      case 'combineModels': {
        combineModelStore.update(s => {
          s.combine = CombineModelStatus.Combine
        });
        break;
      }
      case 'combineModelName': {
        break;
      }
      case 'modelVisible': {
        if (patch.value[0] === '') return;
        this._viewer.getScene().setModelVisibility(...patch.value);
      }
      default:
    }
  }

  getLogMessage(patch: EntryObject<CombineModelState>) {
    return ['Model - EFFECT', patch, combineModelStore.state];
  }
}
