const isProd = process.env.REACT_APP_SECRET_CODE === 'production';

console.log(process.env.REACT_APP_SECRET_CODE)

export default isProd ? {
  GRAPHQL_URI: 'https://bim-portal-prod.modeloapp.com/graphql',
  REST_URI: 'https://bim-portal-prod.modeloapp.com',
  MODELO_API_URI: 'https://build-portal.modeloapp.com'
} : {
  GRAPHQL_URI: 'http://bim-backend-sit.k8s-qunhe.qunhequnhe.com/graphql',
  REST_URI: 'http://bim-backend-sit.k8s-qunhe.qunhequnhe.com',
  MODELO_API_URI: 'http://bim-backend-sit.k8s-qunhe.qunhequnhe.com'
}
