import React from 'react';
import './ModelViewer.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import api from '../api/index';
import { connect } from 'react-redux';
import { IState } from '../rootReducer';
import config from '../config';

declare const Modelo: any;

class ModelViewer extends React.Component<RouteComponentProps<{ modelId: string }> & { locale: string }> {
  state = {
    error: '',
    folderIds: []
  };

  modelApp: any;

  handleBackBtnClick = async () => {
    const modelId = this.props.match.params.modelId;
    const modelFolderObj = await api.getModelFolder({ modelId });
    const folderId = modelFolderObj.data.model.folderId;
    const ModelPathObj = await api.getModelPath({ folderId });
    const { data } = ModelPathObj;
    const folderIds = data.folder.parentHierarchy
      .map((f: { id: any }) => f.id)
      .reverse()
      .slice(1);
    const path = `/models${folderIds.length > 0 ? '/' : ''}${folderIds.join('/')}`;
    this.props.history.push(path);
  };

  componentWillUnmount() {
    this.modelApp && this.modelApp.destroy();
  }

  componentDidMount() {
    const appToken = localStorage.getItem('token');
    const modelId = this.props.match.params.modelId;
    const { locale } = this.props;

    Modelo.init({ endpoint: config.MODELO_API_URI, appToken });

    this.modelApp = new Modelo.UI.ModelViewer({
      modelId,
      locale,
      containerId: 'modelContainer',
      useDefaultFavicon: true,
      onError: (e: Error) => {
        this.setState({ error: 'Load Model Failed' });
      }
    });

    window.getModelVisibleElements = () => {
      const bimTree = this.modelApp.store.getState().bimPanel.bimTree;
      const allElements = Object.keys(bimTree.elements);
      const hiddenElements = new Set(Object.keys(bimTree.hiddenNodes));
      return JSON.stringify(allElements.filter(e => !hiddenElements.has(e)))
    }

    window.getModelSection = () => {
      return JSON.stringify(this.modelApp.tools.section.getSectionBox());
    }

    window.getModelCamera = () => {
      const modelComment = this.modelApp.viewer.getCamera();
      if (!modelComment) return;
      return JSON.stringify({
        fov: modelComment.core._fov,
        distance: modelComment.core._distance,
        phi: modelComment.core._phi,
        theta: modelComment.core._theta,
        at: modelComment.core._at
      });
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <div className="">{error}</div>;
    }

    return (
      <>
        <div className="ModelViewer" id="modelContainer" />
        <div className="ModeloAPIUI__BackBtn" onClick={this.handleBackBtnClick} />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state: IState) => ({
      locale: state.locale
    }),
    {}
  )(ModelViewer)
);
