import React from 'react';
import {
  GetProjectsHOC,
  GetProjectsProps,
  GetProjectFoldersComponent,
  GetProjectFoldersModelFolder,
  GetProjectFoldersAssetFolder,
  GetProjectFoldersWbsAssetFolder
} from '../generated/graphql';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type ProjectFolders = {
  modelFolder: GetProjectFoldersModelFolder;
  assetFolder: GetProjectFoldersAssetFolder;
  wbsFolder: GetProjectFoldersWbsAssetFolder;
};

export type WithProjectFolders<T = any> = T & {
  projectFolders: ProjectFolders;
  projectId: number;
  currentFolderId: number;
};

type StateProps = {};

export default function withProjectFolders(
  WrappedComponent: React.ComponentType<WithProjectFolders>
): React.ComponentType<WithProjectFolders> {
  class WrapperComponent extends React.Component<RouteComponentProps & GetProjectsProps<StateProps>> {
    static displayName = `withModelFolder(${WrappedComponent.displayName || WrappedComponent.name})`;

    static size = (WrappedComponent as any).size;

    render() {
      const { data } = this.props;

      if (data!.loading) return null;

      const projectId = data!.user!.projects![0].id;

      let path = window.location.pathname;
      if (path.endsWith('/')) {
        path = path.substr(0, path.length - 2);
      }
      let lastUrlParam = path.split('/').pop();
      const isRootModelFolder = lastUrlParam === 'models';
      const isRootAssetFolder = lastUrlParam === 'assets';

      return (
        <GetProjectFoldersComponent variables={{ projectId }}>
          {({ data, loading }) => {
            if (loading) return null;
            const projectFolders = {
              modelFolder: data!.project!.modelFolder,
              assetFolder: data!.project!.assetFolder,
              wbsFolder: data!.project!.wbsAssetFolder
            };
            let folderId = Number(lastUrlParam);

            if (isRootModelFolder) {
              folderId = projectFolders.modelFolder.id;
            }

            if (isRootAssetFolder) {
              folderId = projectFolders.assetFolder.id;
            }

            return (
              <WrappedComponent
                {...this.props}
                projectId={projectId}
                projectFolders={projectFolders}
                currentFolderId={folderId}
              />
            );
          }}
        </GetProjectFoldersComponent>
      );
    }
  }

  return withRouter(GetProjectsHOC<RouteComponentProps>({})(WrapperComponent));
}
