import React from 'react';
import { withApollo } from 'react-apollo';
import { DialogProps } from '../Common/Dialog/DialogTypes';
import { TreeNode } from '../Common/Tree/Tree';
import './UploadModelToDAMDialog.scss';
import ModelTree from './components/ModelTree';
import intl from 'react-intl-universal';
import { lt } from '../i18n';
import withProjectFolders, { WithProjectFolders } from './withProjectFolders';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom'

type Props = { onCancel: () => void };

export type UploadModelToDAMDialogDataProps = { uploadSelectedModelToDAMRequest: (modelId:string,uploadToken:string) => void };

type State = {
  uploadToken : string;
  selectedRows: string[];
};

class UploadModelToDAMDialog extends React.Component<
  WithProjectFolders<Props> & DialogProps<UploadModelToDAMDialogDataProps> & RouteComponentProps, State> {
  static size = {
      width: 746,
      height: 596
  };

  state: State = {
    selectedRows: [],
    uploadToken : ''
  };

  handleSelectNode = (node: TreeNode) => {
    let { selectedRows } = this.state;
    if (selectedRows.includes(node.id)) {
      selectedRows = selectedRows.filter(x => x !== node.id);
    } else {
      selectedRows = [node.id];
    }
    this.setState({ selectedRows });
  };

  handleCancel = () => {
    const { dialog } = this.props;
    if (dialog.onCancel) {
      dialog.onCancel();
    }
  };

  handleUpload = () => { 
    const { uploadSelectedModelToDAMRequest } = this.props.dialog.data!;
    const { dialog } = this.props;
    if (dialog.onCancel) {
      dialog.onCancel();
    }
    const {selectedRows,uploadToken } = this.state;
    const verCode = uploadToken.replace(/^cn-/,'').replace(/^us-/,'').replace(/@\S+/,'');
    uploadSelectedModelToDAMRequest(selectedRows[0],verCode);
  }

  setVerificationCode = (e : any) => {
    this.setState({ uploadToken  : e.target.value })
  }


  render() {
      
    const { selectedRows, uploadToken  } = this.state;

    return (
      <div className="UploadModelToDAMDialog">
        <div className="UploadModelToDAMDialog__title">{intl.get(lt.UPLOAD_MODEL_TODAM)}</div>
        <ModelTree selectedRows={selectedRows} onSelect={this.handleSelectNode} disableFolderSelection />
        <div className="UploadModelToDAMDialog__fileSaveContainer">
          <div className="UploadModelToDAMDialog__saveAs">
            <div className="UploadModelToDAMDialog__saveAsTitle">{intl.get(lt.VERIFY_UPLOAD_CODE)}:</div>
            <div className="UploadModelToDAMDialog__saveAsInput">
              <input
                value={uploadToken }
                onChange={e => this.setVerificationCode(e)}
                className="UploadModelToDAMDialog__saveAsInputStyle"
                placeholder={intl.get(lt.VERIFY_UPLOAD_CODE)}
              />
            </div>
          </div>
          {/* <div className="UploadModelToDAMDialog__savePath">
            <div className="UploadModelToDAMDialog__savePathTitle">{intl.get(lt.SAVE_PATH)}</div>
            <div className="UploadModelToDAMDialog__savePathWrapper" />
          </div> */}
        </div>
        <div className="UploadModelToDAMDialog__manipulateContainer">
          <div className="UploadModelToDAMDialog__cancel" onClick={this.handleCancel}>
            {intl.get(lt.CANCEL)}
          </div>
          <div className="UploadModelToDAMDialog__btns">

            <div
              className={classNames('UploadModelToDAMDialog__combine', {
                'UploadModelToDAMDialog__combine--disabled': !(uploadToken  && selectedRows.length > 0)
              })}
              onClick={this.handleUpload}
            >
              {intl.get(lt.UPLOAD_TO_DAM)}
            </div>
          </div>
        </div>
      </div>
    );
  }

}


export default withApollo(withProjectFolders(UploadModelToDAMDialog));