import React from 'react';
import { VariableSizeList as List } from 'react-window';
import Tree, { TreeNode, TreeNodeType } from '../../Common/Tree/Tree';
import {
  GetProjectsQuery,
  GetProjectFoldersQuery,
  GetProjectFoldersVariables,
  GetModelFolderContentsQuery,
  GetModelFolderContentsVariables,
  ModelStatus
} from '../../generated/graphql';
import { loader } from 'graphql.macro';
import { WithApolloClient, withApollo } from 'react-apollo';
import withProjectFolders, { WithProjectFolders } from '../withProjectFolders';
import intl from 'react-intl-universal';
import { lt } from '../../i18n';

function getNodes(data: GetModelFolderContentsQuery, hideModels = false) {
  const models = data.folder!.models!.map(model => ({
    id: model.id,
    title: model.name,
    type: TreeNodeType.Model,
    isLeaf: true,
    status: model.status
  }));

  const folders = data.folder!.childFolders!.map(folder => ({
    id: folder.id + '',
    title: folder.name,
    type: TreeNodeType.Folder,
    isLeaf: hideModels
      ? folder.childFolders!.length === 0
      : folder.childFolders!.length === 0 && folder.models!.length === 0,
    status: ModelStatus.Converted
  }));

  return [...folders, ...models];
}

type ModelTreeNodeData = {
  status: ModelStatus;
};

type Props = {
  hideModels?: boolean;
  disableFolderSelection?: boolean;
  selectedRows: string[];
  onExpand?: (data: TreeNode) => void;
  onSelect: (data: TreeNode) => void;
  disabledRows?: string[];
};

type State = {
  treeRefs: {
    [id: string]: TreeNode;
  };
  treeData: TreeNode[];
  allowMove: boolean;
};

class ModelTree extends React.Component<WithProjectFolders<WithApolloClient<Props>>, State> {
  state: State = {
    treeRefs: {},
    treeData: [],
    allowMove: false
  };

  async componentDidMount() {
    const { client, projectFolders, hideModels } = this.props;

    const getProjectsData = await client.query<GetProjectsQuery>({
      query: loader('../../graphql/GetProjects.gql'),
      variables: {}
    });
    const projectId = getProjectsData!.data.user!.projects![0].id;
    const getProjectFoldersData = await client.query<GetProjectFoldersQuery, GetProjectFoldersVariables>({
      query: loader('../../graphql/GetProjectFolders.gql'),
      variables: { projectId }
    });
    const rootModelFolderId = getProjectFoldersData!.data.project!.modelFolder.id;
    const getModelFolderContentsData = await client.query<GetModelFolderContentsQuery, GetModelFolderContentsVariables>(
      { query: loader('../../graphql/GetModelFolderContents.gql'), variables: { folderId: rootModelFolderId } }
    );

    const rootNode = {
      id: projectFolders.modelFolder.id + '',
      title: intl.get(lt.MODELS),
      subNodes: [],
      type: TreeNodeType.Folder,
      isLeaf: false,
      status: ModelStatus.Converted
    };

    const treeRefs: { [id: string]: TreeNode } = {
      [rootNode.id]: rootNode
    };
    const treeData: TreeNode[] = [rootNode];
    const nodes = getNodes(getModelFolderContentsData.data, hideModels);

    nodes.forEach(node => {
      treeRefs[node.id] = node;
      treeData[0].subNodes!.push(node);
    });

    this.setState({ treeRefs, treeData });
  }

  handleExpandNode = async (node: TreeNode) => {
    const { client, hideModels } = this.props;
    const { treeData, treeRefs } = this.state;

    const { data } = await client.query<GetModelFolderContentsQuery, GetModelFolderContentsVariables>({
      query: loader('../../graphql/GetModelFolderContents.gql'),
      variables: { folderId: Number(node.id) }
    });

    const nodes = getNodes(data, hideModels);

    if (treeRefs[node.id].subNodes) {
      treeRefs[node.id].subNodes = undefined;
    } else {
      treeRefs[node.id].subNodes = nodes;
    }

    nodes.forEach(node => {
      treeRefs[node.id] = node;
    });

    this.setState({ treeRefs, treeData });
    this.props.onExpand && this.props.onExpand(node);
  };

  handleSelectNode = (node: TreeNode) => {
    this.props.onSelect(node);
  };

  render() {
    const { treeData } = this.state;
    const { selectedRows, hideModels, disableFolderSelection, disabledRows } = this.props;

    return (
      <Tree
        data={treeData}
        selectedRows={selectedRows}
        onExpand={this.handleExpandNode}
        onSelect={this.handleSelectNode}
        hideFiles={hideModels}
        disableFolderSelection={disableFolderSelection}
        disabledRows={disabledRows}
      />
    );
  }
}

export default withApollo<Props>(withProjectFolders(ModelTree));
